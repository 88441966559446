import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { IDiscountCondition } from 'models';
import { SplitedPrice } from 'components/prices';
import { TextBody2, TextCaption, TextSubTitle } from 'components/shared/text';
import { GridDivider } from 'components/shared/table/dividers';
import FlrCardNarrow from 'components/shared/card/FlrCardNarrow';
import FlrCardTitle from 'components/shared/card/FlrCardTitle';
import FlrProgress from 'components/shared/progress-bar/FlrProgressBar';
import styles from '../styles';
import messagesCommon from 'translations/common';
import messages from 'translations/account/loyalty';
import FlrLoader from '../../../../components/loading/LoadingSpinner';

interface IComponentProps {
  discount?: IDiscountCondition;
  progressValue?: number;
  loading: boolean;
  classes?: any;
}

const ProductGroupDiscountProgressCard: React.FC<IComponentProps> = ({ discount, progressValue, loading, classes }) => {
  if (loading) {
    return (
      <FlrCardNarrow>
        <FlrCardTitle isUnderline={true}>
          <TextSubTitle>{messages.discountProgress.defaultMessage}</TextSubTitle>
        </FlrCardTitle>
        <Grid container alignItems={'center'} justifyContent={'center'}>
          <Grid item>
            <FlrLoader size={32} />
          </Grid>
        </Grid>
      </FlrCardNarrow>
    );
  }

  const conditions = discount ? discount.conditions : {};
  const conditionsArray = Object.keys(conditions);
  const steps = conditionsArray.map(key => ({
    value: `${key}%`,
    label:
      Number(conditions[key]) > 0 ? (
        <SplitedPrice fontSize={'h6'} value={Number(conditions[key])} decimalSameSize postfix="шт" hideDecimal />
      ) : (
        messagesCommon.start.defaultMessage
      )
  }));
  steps.unshift({
    value: '0%',
    label: `0 шт`
  });

  const activeIndex = conditionsArray.reduce(
    (acc, k, idx) => (Number(conditions[k]) > (progressValue || 0) ? acc : idx + 1),
    0
  );
  let progress = 0;
  if (conditionsArray.length === activeIndex) {
    progress = 100;
  } else if (conditions[activeIndex]) {
    progress =
      (((progressValue || 0) - Number(conditions[activeIndex])) * 100) /
        (Number(conditions[activeIndex + 1]) - Number(conditions[activeIndex])) /
        conditionsArray.length +
      (100 / conditionsArray.length) * activeIndex;
  } else {
    progress =
      ((progressValue || 0) * 100) / Number(conditions[activeIndex + 1]) / conditionsArray.length +
      (100 / conditionsArray.length) * activeIndex + 1;
  }

  const needToBuy: number | undefined = conditions[activeIndex + 1]
    ? Number(conditions[activeIndex + 1]) - (progressValue || 0)
    : undefined;

  const lastDayOfMonth = moment()
    .endOf('month')
    .format('D MMMM');

  const lastDayOfNextMonth = moment()
    .add(1, 'month')
    .endOf('month')
    .format('D MMMM');

  return (
    <FlrCardNarrow className={classes.paperCard} hoverShadow>
      <FlrCardTitle isUnderline={true}>
        <Grid item container justifyContent="space-between">
          <TextSubTitle>{messages.discountProgressNext.defaultMessage}</TextSubTitle>
          <TextCaption className={classes.descriptionText}>
            <FormattedMessage {...messages.currentDiscountDate} values={{ lastDay: lastDayOfNextMonth }} />
          </TextCaption>
        </Grid>
      </FlrCardTitle>
      <Grid container spacing={1} direction={'column'}>
        <Grid item sm container spacing={1}>
          <Grid item sm style={{ width: '100%' }}>
            <FlrProgress steps={steps} progress={progress} activeIndex={activeIndex} />
          </Grid>
        </Grid>
        <GridDivider />
        <Grid item container spacing={3}>
          <Grid item container>
            {activeIndex === conditionsArray.length ? (
              <Grid item>
                <TextBody2 color={'textSecondary'}>{messages.discountMaxLevel.defaultMessage}</TextBody2>
              </Grid>
            ) : (
              <Grid item container justifyContent="space-between">
                <TextCaption className={classes.descriptionText}>
                  <FormattedMessage {...messages.discountProgressAmount} values={{ lastDay: lastDayOfMonth }} />
                </TextCaption>
                <TextBody2>&nbsp;{progressValue || 0} шт</TextBody2>
              </Grid>
            )}
          </Grid>
          <Grid item container justifyContent="space-between">
            {needToBuy ? (
              <>
                <TextCaption className={classes.descriptionText}>
                  <FormattedMessage
                    {...messages.discountNextLevelProgress}
                    values={{ lastDay: lastDayOfMonth, percent: activeIndex + 1 }}
                  />
                </TextCaption>
                <TextBody2>&nbsp;{Number(conditions[activeIndex + 1]) - (progressValue || 0)} шт</TextBody2>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </FlrCardNarrow>
  );
};

export default withStyles<any>(styles)(ProductGroupDiscountProgressCard);
