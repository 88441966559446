import React from 'react';
import { Column } from 'material-table';
import { FormattedMessage } from 'react-intl';

import { IDiscountOrder } from 'store/discount-by-group/actions';

import { SplitedPrice } from 'components/prices';
import { Link2 } from 'components/shared/text';
import messages from 'translations/account/loyalty';
import { baseUrl, UAH } from 'shared/constants';

const columns: Array<Column<IDiscountOrder>> = [
  {
    title: messages.type.defaultMessage,
    field: 'orderId',
    render: rowData => (
      <Link2 to={`${baseUrl}/account/orders/completed/${rowData.orderId}`}>
        {rowData.orderType === 'order' ? (
          <>
            {messages.order.defaultMessage}&nbsp;
            <span style={{ display: 'inline-block', textDecoration: 'inherit' }}>№{rowData.orderId}</span>
          </>
        ) : (
          <FormattedMessage {...messages.orderReturn} values={rowData} />
        )}
      </Link2>
    ),
    sorting: false,
    cellStyle: {
      width: '100%'
    }
  },
  {
    title: (
      <span style={{ whiteSpace: 'nowrap' }}>
        <FormattedMessage {...messages.sum} values={{ currency: UAH }} />
      </span>
    ),
    field: 'total',
    type: 'numeric',
    render: rowData => (
      <SplitedPrice
        value={rowData.total}
        fontSize={'body1'}
        fontSizeDecimal={'body2'}
        positive={rowData.total > 0}
        negative={rowData.total < 0}
      />
    ),
    sorting: false
  },
  {
    title: (
      <span style={{ whiteSpace: 'nowrap' }}>
        <FormattedMessage {...messages.spent} values={{ currency: UAH }} />
      </span>
    ),
    field: 'groupBalance',
    type: 'numeric',
    render: rowData => <SplitedPrice value={rowData.groupBalance || 0} fontSize={'body1'} fontSizeDecimal={'body2'} />,
    sorting: false
  }
];

export default columns;
