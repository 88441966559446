import { ChangeEvent, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { useParams } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { isMobileDevice } from 'environment';
import { Hidden, Tab, Tabs } from '@mui/material';

import FlrCard from 'components/shared/card/FlrCard';
import DropMenu, { IDropMenuItem } from 'components/shared/DropMenu';
import Icon from 'components/shared/Icon';
import { FlrTableV2 } from 'components/shared/table';
import { TitleH1 } from 'components/shared/text';
import Tooltip from 'components/shared/tooltip';
import {
  groupOrdersByStatuses,
  Order,
  ORDER_STATUS_DELIVERED,
  ORDER_STATUS_DONE,
  ORDER_STATUS_PACKED,
  ORDER_STATUS_PENDING_APPROVAL,
  ORDER_STATUS_PREORDER,
  ORDER_STATUS_PREORDER_PENDING_APPROVAL,
  ORDER_STATUS_RESERVED,
  ORDER_STATUS_RETURN,
  ORDER_STATUS_SHIPPED,
  OrderStatus,
  User
} from 'models';
import { baseUrl } from 'shared/constants';
import { getAccountLoadingState, getUserAccount } from 'store/account/selectors';
import { getOrdersList, getOrdersLoadingState } from 'store/order/selectors';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/account/order';

import { breadCrumbsContext } from '../breadCrumbsState';
import TableColumns, { columnsMobile } from './AccountOrdersTableColumns';
import AccountOrdersTableMobileRowExpanded from './AccountOrdersTableMobileRowExpanded';

import classes from './AccountOrdersTable.module.scss';

export interface IOrderTab {
  key: string;
  path: string;
  label: string;
  icon: string;
  statuses: OrderStatus[];
}

interface IProps {
  setRightPanel?: (arg: () => ReactNode) => void;
}

interface IStateProps {
  account: User | null;
  accountLoadingState?: boolean;
  orders: Order[];
  ordersLoadingState: boolean;
}

type IComponentProps = IProps & IStateProps;

const rootPath = `/account/orders`;

const preOrdersPath = 'pre-orders';
export const pathsOrders: IOrderTab[] = [
  {
    key: 'preOrder',
    path: `${rootPath}/${preOrdersPath}`,
    icon: 'box',
    label: messages.preOrder.defaultMessage,
    statuses: [ORDER_STATUS_PREORDER_PENDING_APPROVAL, ORDER_STATUS_PREORDER]
  },
  {
    key: 'deliveries',
    path: `${rootPath}/deliveries`,
    icon: 'delivery',
    label: messages.deliveries.defaultMessage,
    statuses: [ORDER_STATUS_PENDING_APPROVAL, ORDER_STATUS_RESERVED, ORDER_STATUS_PACKED, ORDER_STATUS_SHIPPED]
  },
  {
    key: 'reviewPending',
    path: `${rootPath}/review-pending`,
    icon: 'comment',
    label: messages.reviewPending.defaultMessage,
    statuses: [ORDER_STATUS_DELIVERED]
  },
  {
    key: 'completed',
    path: `${rootPath}/completed`,
    icon: 'complete',
    label: messages.completed.defaultMessage,
    statuses: [ORDER_STATUS_DONE]
  },
  {
    key: 'reclamation',
    path: `${rootPath}/reclamation`,
    icon: 'return',
    label: messages.returns.defaultMessage,
    statuses: [ORDER_STATUS_RETURN]
  }
];

const isMobile = Boolean(isMobileDevice(window.navigator));

const AccountOrdersTable: FC<IComponentProps> = ({ account, ordersLoadingState, orders }) => {
  const { filter } = useParams<any>();
  const ordersSummary = account && account.profile ? account.profile.ordersSummary : null;

  const initialTab = filter
    ? pathsOrders.findIndex((item) => item.path.indexOf(filter) >= 0)
    : ordersSummary
    ? pathsOrders.findIndex((item) => !!groupOrdersByStatuses(ordersSummary, item.statuses)) || 0
    : 0;

  const [tab, setTab] = useState(initialTab > 0 ? initialTab : 0);
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  /** set account breadcrumbs */
  const { setCurrentUrl } = useContext(breadCrumbsContext);
  const updateBreadCrumbs = useCallback(() => {
    const { label } = pathsOrders[tab];

    setCurrentUrl([{ label: messages.title.defaultMessage }, { label }]);
  }, [setCurrentUrl, tab]);

  useEffect(() => {
    updateBreadCrumbs();

    return () => {
      setCurrentUrl([]);
    };
  }, [updateBreadCrumbs, setCurrentUrl]);

  useEffect(() => {
    updateBreadCrumbs();

    return () => {
      setCurrentUrl([]);
    };
  }, [setCurrentUrl, tab, updateBreadCrumbs]);

  /** redirect from root path */
  const pathObject: IOrderTab | null = filter ? pathsOrders[tab] : null;

  const filterFn =
    filter === preOrdersPath
      ? (order: Order) =>
          order.orderStatus === ORDER_STATUS_PREORDER || order.orderStatus === ORDER_STATUS_PREORDER_PENDING_APPROVAL
      : (order: Order) => pathObject && pathObject.statuses.includes(order.orderStatus);
  const filteredOrders = pathObject && filter && orders ? orders.filter(filterFn) : orders;

  if (!filter) {
    return <Redirect to={`${baseUrl}${pathsOrders[tab].path}`} />;
  }

  const toggledTableActions = [
    (order: Order) => ({
      icon: () => {
        const options: IDropMenuItem[] = [];
        if (order.orderStatus === ORDER_STATUS_SHIPPED) {
          options.push({
            text: messages.reviewAlertLink.defaultMessage,
            icon: <Icon type={'feedback'} size={24} />
          });
        }
        options.push({
          text: messages.editOrder.defaultMessage,
          icon: <Icon type={'edit'} size={24} />
        });
        options.push({
          text: messages.preOrderRemove.defaultMessage,
          icon: <Icon type={'trash'} size={24} />
        });

        if (options.length > 2) {
          return <DropMenu options={options} />;
        }
        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Hidden smUp>
              <DropMenu options={options} />
            </Hidden>
            <Hidden smDown>
              {options.map((option, index) => {
                if (!option.onClick) {
                  return (
                    <div key={index} style={{ marginLeft: 10, opacity: 0.5 }}>
                      {option.icon}
                    </div>
                  );
                }
                return (
                  <div style={{ marginLeft: 10 }} key={index}>
                    <Tooltip title={option.text || ''}>
                      <div>{option.icon}</div>
                    </Tooltip>
                  </div>
                );
              })}
            </Hidden>
          </div>
        );
      },
      onClick: () => null
    })
  ];
  const tablesCommonProps = {
    data: filteredOrders,
    isLoadingExternal: ordersLoadingState,
    actions: toggledTableActions,
    options: {
      actionsColumnIndex: -1,
      headerStyle: {
        maxWidth: 'fill-available'
      }
    }
  };
  return (
    <div className={classes.container}>
      <TitleH1>{messages.title.defaultMessage}</TitleH1>

      {/*/!* TODO if some auto pay*!/*/}
      {/*<Alert closable title={"Автоматична оплата"}>*/}
      {/*  <span>*/}
      {/*    Оплату буде автоматично знято після закінчення відліку таймера. Поповніть баланс, щоб уникнути санкцій.*/}
      {/*  </span>*/}
      {/*</Alert>*/}

      {/*/!* TODO if auto clear to catalog*!/*/}
      {/*<Alert type={"warning"} closable title={"Неоформлені замовлення"}>*/}
      {/*  <span>По завершенню відліку часу всі неоформлені товари повернуться у Каталог. Перейти до оформлення</span>*/}
      {/*</Alert>*/}

      <div className={classes.tableToolbarContainer}>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor={'primary'}
          variant="scrollable"
          scrollButtons={false}
        >
          {pathsOrders.map((tabItem) => {
            const tabOrdersCount = groupOrdersByStatuses(ordersSummary, tabItem.statuses);
            return (
              <Tab
                key={tabItem.path}
                disabled={!Boolean(ordersSummary && tabOrdersCount)}
                label={
                  <>
                    <Icon type={tabItem.icon} size={24} offset={8} />
                    {tabItem.label} ({ordersSummary ? tabOrdersCount : 0})
                  </>
                }
                component={Link}
                to={`${baseUrl}${tabItem.path}`}
              />
            );
          })}
        </Tabs>
      </div>

      <FlrCard>
        <FlrTableV2
          columns={isMobile ? columnsMobile : TableColumns}
          detailPanel={isMobile ? AccountOrdersTableMobileRowExpanded : null}
          {...tablesCommonProps}
        />
      </FlrCard>
    </div>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  account: getUserAccount(state),
  accountLoadingState: getAccountLoadingState(state),
  orders: getOrdersList(state),
  ordersLoadingState: getOrdersLoadingState(state)
});

export default connect(mapStateToProps)(AccountOrdersTable);
