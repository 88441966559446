import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { connect, MapStateToProps } from 'react-redux';

import { IApplicationState } from 'store/reducers';
import { getDiscountConditionsLoadingState } from 'store/discount-conditions/selectors';
import { TextBody1, TextBody2, TextSubTitle } from 'components/shared/text';

import styles from '../styles';
import messages from 'translations/account/loyalty';
import { IConditions } from 'models';

interface IProps {
  conditions: IConditions | undefined;
  classes: any;
}

interface IStateProps {
  discountConditionsLoadingState?: boolean;
}

type IComponentProps = IProps & IStateProps;

const DiscountLevels: React.FC<IComponentProps> = ({ discountConditionsLoadingState, conditions, classes }) => {
  const percentsList = Object.keys(conditions || {});
  return (
    <>
      <TextSubTitle className={classes.rulesSubtitle}>{messages.discountLevels.defaultMessage}</TextSubTitle>
      <TextBody2 className={classes.rulesSubSubtitle}>{messages.discountLevelsDesc.defaultMessage}</TextBody2>
      {discountConditionsLoadingState ? (
        <span>LOADING......</span>
      ) : conditions ? (
        <Grid container spacing={1} direction={'column'}>
          {percentsList.map((percent, idx) => {
            const discountData = conditions[percent];
            if (discountData === undefined || percent === '0') {
              return null;
            }
            const min = Number(discountData);
            const max = Number(conditions[percentsList[idx + 1]]) - 1;
            return (
              <Grid item container sm spacing={2} key={`discount-condition-${percent}`}>
                <Grid item sm={1}>
                  <span className={classes.mediumBold}>{percent}%</span>
                </Grid>
                <Grid item sm>
                  <FormattedMessage
                    {...messages.productsWereBuy}
                    values={{
                      b: (...chunks: any[]) => <span className={classes.mediumBold}>{chunks}</span>,
                      if: (chunk: string) => <span key={chunk}>{chunk.includes('NaN') ? '' : chunk}</span>,
                      min,
                      max
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <TextBody1 color={'textSecondary'}>На даний момент дані програми лояльності недоступні</TextBody1>
      )}
    </>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  discountConditionsLoadingState: getDiscountConditionsLoadingState(state)
});

export default connect(mapStateToProps, null)(withStyles<any>(styles)(DiscountLevels as any)) as any;
