import { FC, useCallback, useContext, useEffect } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, useMediaQuery, useTheme } from '@mui/material';

import { TextSubTitle, TitleH1, TitleH2 } from 'components/shared/text';
import { PRODUCT_GROUP_DISCOUNT } from 'models';
import { baseUrl, defaultCatalogProductType } from 'shared/constants';
import { catalogSetFilter } from 'store/catalog/actions';
import { fetchDiscountByGroupAsync, IDiscountByGroup } from 'store/discount-by-group/actions';
import { getDiscountByGroup, getDiscountByGroupLoadingState } from 'store/discount-by-group/selectors';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/account/loyalty';

import { breadCrumbsContext } from '../breadCrumbsState';
import {
  ProductGroupDiscountCard,
  ProductGroupDiscountProductsHistoryCard,
  ProductGroupDiscountProgressCard,
  ProductGroupOrdersHistoryCard
} from './cards';
import CategoriesListInGroupCard from './cards/CategoriesListInGroupCard';
import { loyaltyBreadcrumb, loyaltyBreadcrumbMobile } from './index';
import DiscountLevels from './rules/DiscountLevels';
import DiscountRules from './rules/DiscountRules';

import classes from './ProductGroupDiscount.module.scss';

interface IStateProps {
  discountByGroup: IDiscountByGroup | null;
  discountByGroupLoadingState: boolean;
}

interface IDispatchProps {
  fetchDiscountByGroup: typeof fetchDiscountByGroupAsync.request;
  setCatalogFilter: any;
}

type IComponentProps = IStateProps & IDispatchProps;

const ProductGroupDiscount: FC<IComponentProps> = ({
  discountByGroup,
  discountByGroupLoadingState,
  fetchDiscountByGroup,
  setCatalogFilter
}) => {
  const { discountGroupId } = useParams<{ discountGroupId: string }>();
  const theme = useTheme();
  const screenMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const breadcrumb = screenMobile ? loyaltyBreadcrumbMobile : loyaltyBreadcrumb;

  const { setCurrentUrl } = useContext(breadCrumbsContext);
  const updateBreadCrumbs = useCallback(
    (bc, label) => {
      setCurrentUrl([{ label: bc.label, path: bc.path }, { label }]);
    },
    [setCurrentUrl]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (discountByGroup) {
      updateBreadCrumbs(breadcrumb, discountByGroup?.discountRule.name);
    }
    return () => {
      setCurrentUrl([]);
    };
    // eslint-disable-next-line
  }, [breadcrumb, updateBreadCrumbs, setCurrentUrl, discountByGroup]);

  useEffect(() => {
    if (discountGroupId) {
      fetchDiscountByGroup(discountGroupId);
    }
  }, [fetchDiscountByGroup, discountGroupId]);

  const handleClick = () => {
    setCatalogFilter({ discount: true });
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleWrapper}>
        <div className={classes.title}>
          <TitleH1 className={classes.header}>{discountByGroup?.discountRule.name}</TitleH1>
          <TextSubTitle className={classes.subTitle}>{messages.productGroupTitle.defaultMessage}</TextSubTitle>
        </div>
        <Link className={classes.link} to={`${baseUrl}/catalog/${defaultCatalogProductType}`}>
          <Button color="primary" variant={'contained'} onClick={handleClick}>
            {messages.buyWithDiscount.defaultMessage}
          </Button>
        </Link>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.productGroup}>
          <ProductGroupDiscountCard discount={discountByGroup} />
          {discountByGroup?.discountRule.discountType !== 'static' &&
          discountByGroup?.discountRule.discountType !== 'website' ? (
            <ProductGroupDiscountProgressCard
              discount={discountByGroup?.discountRule}
              loading={discountByGroupLoadingState}
              progressValue={discountByGroup?.progressValue}
            />
          ) : null}
        </div>
        <div className={classes.categoriesList}>
          <CategoriesListInGroupCard
            categories={discountByGroup?.discountRule.categories}
            group={discountByGroup?.discountRule.name}
          />
          <ProductGroupDiscountProductsHistoryCard
            group={discountByGroup?.discountRule.name}
            mobile={screenMobile}
            loading={discountByGroupLoadingState}
            data={(discountByGroup && Object.values(discountByGroup.transactions)) || []}
          />
        </div>
      </div>
      <ProductGroupOrdersHistoryCard
        loading={discountByGroupLoadingState}
        data={(discountByGroup && Object.values(discountByGroup.orders)) || []}
      />
      {discountByGroup?.discountRule?.discountType !== 'static' &&
      discountByGroup?.discountRule.discountType !== 'website' ? (
        <div className={classes.discountRules}>
          <TitleH2>{messages.rules.defaultMessage}</TitleH2>
          <>
            <DiscountRules type={PRODUCT_GROUP_DISCOUNT} classNameWrapper={classes.discountRule} />
            <DiscountLevels conditions={discountByGroup?.discountRule?.conditions} />
          </>
        </div>
      ) : null}
      <Link className={classes.mobileLink} to={`${baseUrl}/catalog/${defaultCatalogProductType}`}>
        <Button color="primary" variant={'contained'} onClick={handleClick}>
          {messages.buyWithDiscount.defaultMessage}
        </Button>
      </Link>
    </div>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  discountByGroupLoadingState: getDiscountByGroupLoadingState(state),
  discountByGroup: getDiscountByGroup(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      fetchDiscountByGroup: fetchDiscountByGroupAsync.request,
      setCatalogFilter: catalogSetFilter
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductGroupDiscount);
