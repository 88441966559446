import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CardCreditLine from 'components/cards/account/CardCreditLine';
import BalanceCard from 'components/cards/shared/BalanceCard';
import { useTrackInitialPageLoad } from 'controllers';
import { getUserAccount } from 'store/account/selectors';
import { fetchDiscountConditionsAsync } from 'store/discount-conditions/actions';
import { getDiscountConditions, getDiscountConditionsLoadingState } from 'store/discount-conditions/selectors';

import TransactionsTable from '../finance/Table';
import DashboardDelivery from './DashboardDelivery';
import DashboardLoyalty from './DashboardLoyalty';

import classes from './Dashboard.module.scss';

const AccountDashboard = () => {
  const dispatch = useDispatch();
  const account = useSelector(getUserAccount);
  const discountConditions = useSelector(getDiscountConditions);
  const discountConditionsLoading = useSelector(getDiscountConditionsLoadingState);

  if (!discountConditions && !discountConditionsLoading) {
    dispatch(fetchDiscountConditionsAsync.request());
  }

  useTrackInitialPageLoad();

  const combinedConditions = discountConditions
    ? [...discountConditions.permanentDiscounts, ...discountConditions.conditionDiscounts]
    : undefined;

  return (
    <div className={classes.container}>
      <div className={classes.leftSide}>
        <DashboardDelivery account={account} />
        <DashboardLoyalty discountConditions={combinedConditions} classNameWrapper={classes.desktopLoyalty} />
      </div>
      <div className={classes.rightSide}>
        <section className={classes.financesSection}>
          <BalanceCard classNameWrapper={classes.financesCard} />
          <CardCreditLine classNameWrapper={classes.creditLineCard} withCircle />
          <TransactionsTable isDashboard />
        </section>
      </div>
      <DashboardLoyalty discountConditions={combinedConditions} smallCards classNameWrapper={classes.mobileLoyalty} />
    </div>
  );
};

export default AccountDashboard;
