import { createReducer, PayloadAction, createAsyncAction } from "typesafe-actions";
import {
  loadedDataWrapper,
  REQUEST_ACTIONS,
  IAsyncDataWrapper,
  loadingDataWrapper,
  errorDataWrapper
} from "store/actions";
import { call, put, takeEvery } from "redux-saga/effects";
import { DiscountConditions } from "models/discount-conditions";
import { DiscountConditionsRepository } from "./request";

const httpClient = new DiscountConditionsRepository();

export const prefix = "@@discountConditions/";

export const DISCOUNT_CONDITION_REQUEST = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const DISCOUNT_CONDITION_REQUEST_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const DISCOUNT_CONDITION_REQUEST_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

interface IDiscountConditionsStateSync {
  discountConditions: DiscountConditions | null;
}

export type IDiscountConditionsState = IAsyncDataWrapper<IDiscountConditionsStateSync>;

export const discountConditionsInitialState: IDiscountConditionsState = {
  loading: false,
  loaded: false,
  data: {
    discountConditions: null
  },
  error: null
};

type DiscountConditionsActionTypes =
  | typeof DISCOUNT_CONDITION_REQUEST
  | typeof DISCOUNT_CONDITION_REQUEST_SUCCESS
  | typeof DISCOUNT_CONDITION_REQUEST_FAILURE;

export const fetchDiscountConditionsAsync = createAsyncAction(
  DISCOUNT_CONDITION_REQUEST,
  DISCOUNT_CONDITION_REQUEST_SUCCESS,
  DISCOUNT_CONDITION_REQUEST_FAILURE
)<void, DiscountConditions, Error>();

function* discountConditionsSaga(): Generator {
  try {
    const response: any = yield call(() => httpClient.fetch());
    const discountConditions = new DiscountConditions(response.data);
    yield put(fetchDiscountConditionsAsync.success(discountConditions));
  } catch (err) {
    yield put(fetchDiscountConditionsAsync.failure(err as Error));
  }
}

export function* discountConditionsRequestSaga() {
  yield takeEvery(fetchDiscountConditionsAsync.request, discountConditionsSaga);
}

export default createReducer(discountConditionsInitialState)
  .handleAction(fetchDiscountConditionsAsync.request, (state: IDiscountConditionsState) => {
    return loadingDataWrapper(state.data);
  })
  .handleAction(
    fetchDiscountConditionsAsync.success,
    (state: IDiscountConditionsState, action: PayloadAction<DiscountConditionsActionTypes, DiscountConditions>) =>
      loadedDataWrapper({
        ...state.data,
        discountConditions: action.payload
      })
  )
  .handleAction(fetchDiscountConditionsAsync.failure, (state: IDiscountConditionsState) =>
    errorDataWrapper({ ...state.data, discountConditions: null }, new Error("Failed to load discountConditions"))
  );
