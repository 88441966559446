import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@mui/styles';
import { TextSubTitle } from 'components/shared/text';
import FlrCardNarrow from 'components/shared/card/FlrCardNarrow';
import FlrCardTitle from 'components/shared/card/FlrCardTitle';
import messages from 'translations/account/loyalty';
import styles from '../styles';

interface IProps {
  classes: any;
  group: string | undefined;
  categories?: string[];
}

const CategoriesListInGroupCard: React.FC<IProps> = ({ classes, group, categories }) => {
  return categories && categories.length ? (
    <FlrCardNarrow className={classes.categoriesCardRoot} style={{ width: '100%' }}>
      <FlrCardTitle isUnderline>
        <TextSubTitle>
          <FormattedMessage {...messages.categoriesList} values={{ group }} />
        </TextSubTitle>
      </FlrCardTitle>
      <TextSubTitle>{categories.join(', ')}</TextSubTitle>
    </FlrCardNarrow>
  ) : null;
};

export default withStyles<any>(styles)(CategoriesListInGroupCard);
