import React from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { getDiscountConditions, getDiscountConditionsLoadingState } from 'store/discount-conditions/selectors';
import { TextSubTitle } from 'components/shared/text';
import FlrCardNarrow from 'components/shared/card/FlrCardNarrow';
import FlrCardTitle from 'components/shared/card/FlrCardTitle';
import FlrProgress from 'components/shared/progress-bar/FlrProgressBar';

import styles from '../styles';
import { UAH } from 'shared/constants';
import messages from 'translations/account/loyalty';

interface IComponentProps {
  progressValue: number;
  classes?: any;
}

const PersonalDiscountProgressCard: React.FC<IComponentProps> = ({ progressValue, classes }) => {
  const discountConditions = useSelector(getDiscountConditions);
  const discountConditionsLoadingState = useSelector(getDiscountConditionsLoadingState);

  if (discountConditionsLoadingState) {
    return (
      <FlrCardNarrow>
        <br />
        <br />
        <br />
        loading....
        <br />
        <br />
        <br />
        <br />
      </FlrCardNarrow>
    );
  }

  if (!discountConditions) {
    return null;
  }

  const balanceConditions = discountConditions.discounts.balance.find(
    d => discountConditions.accountBalanceDiscount?.discountRule === d.id
  );

  const conditions =
    balanceConditions && balanceConditions.conditions
      ? balanceConditions.conditions
      : discountConditions.accountBalanceDiscount?.conditions || {};
  const conditionsArray = Object.keys(conditions);

  const steps = conditionsArray.map(key => ({
    value: `${key}%`,
    label: `${conditions[key]} ${UAH}`
  }));
  steps.unshift({
    value: '0%',
    label: `0 ${UAH}`
  });
  const activeIndex = conditionsArray.reduce((acc, k, idx) => (Number(conditions[k]) > progressValue ? acc : idx), -1);

  // const progressValue = (account && account?.profile.balance) || 1;
  let progress = 0;
  if (conditionsArray.length === activeIndex) {
    progress = 100;
  } else if (conditions[activeIndex]) {
    progress =
      (((progressValue || 0) - Number(conditions[activeIndex])) * 100) /
        (Number(conditions[activeIndex + 1]) - Number(conditions[activeIndex])) /
        conditionsArray.length +
      (100 / conditionsArray.length) * activeIndex;
  } else {
    progress =
      ((progressValue || 0) * 100) / Number(conditions[activeIndex + 1]) / conditionsArray.length +
      (100 / conditionsArray.length) * activeIndex +
      1;
  }

  return (
    <FlrCardNarrow className={classes.paperCard} hoverShadow>
      <FlrCardTitle isUnderline={true}>
        <TextSubTitle>{messages.discountProgress.defaultMessage}</TextSubTitle>
      </FlrCardTitle>
      <Grid container spacing={2} direction={'column'}>
        <Grid item sm container justifyContent={'space-between'} direction={'row'}>
          <Grid item container spacing={1} direction={'column'}>
            <Grid item sm>
              <FlrProgress steps={steps} progress={progress} activeIndex={activeIndex + 1} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FlrCardNarrow>
  );
};

export default withStyles<any>(styles)(PersonalDiscountProgressCard);
