import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import makeStyles from '@mui/styles/makeStyles';

import { ReactComponent as TableSortDefault } from 'components/shared/icons/table-sort-default.svg';

import styles from './styles';

interface IEnhancedTableProps {
  classes?: any;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: 'asc' | 'desc';
  orderBy: string;
  headCells: any;
}

const useStyles = makeStyles(styles);

export function EnhancedTableHead(props: IEnhancedTableProps) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.field}
            align={headCell.type === 'numeric' ? 'right' : 'left'}
            sortDirection={orderBy === headCell.field ? order : false}
            className={classes.tableHeadCell}
          >
            <TableSortLabel
              IconComponent={TableSortDefault as any}
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : 'asc'}
              onClick={createSortHandler(headCell.field)}
              className={classes.tableSortLabel}
            >
              {headCell.title}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
