import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';

import FlrCardNarrow from 'components/shared/card/FlrCardNarrow';
import FlrCardTitle from 'components/shared/card/FlrCardTitle';
import { FlrTable } from 'components/shared/table';
import { TextSubTitle } from 'components/shared/text';
import { IDiscountOrder } from 'store/discount-by-group/actions';
import messages from 'translations/account/loyalty';

import styles from '../styles';
import columns from './table-columns/OrdersTransactions';

interface IProps {
  // own props
  loading: boolean;
  data: IDiscountOrder[];
  classes: any;
}

const ProductGroupOrdersHistoryCard: React.FC<IProps> = ({ data, classes, loading }) => {
  return (
    <>
      <FlrCardNarrow className={clsx(classes.historyChart, classes.paperCard, classes.paperCardFullHeight)}>
        <FlrCardTitle isUnderline>
          <TextSubTitle align={'left'}>{messages.productGroupTransactions.defaultMessage}</TextSubTitle>
        </FlrCardTitle>
        <FlrTable columns={columns} data={data} isLoading={loading} />
      </FlrCardNarrow>
    </>
  );
};

export default withStyles<any>(styles)(ProductGroupOrdersHistoryCard);
