import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import clsx from 'clsx';
import env, { isMobileDevice } from 'environment';
import moment from 'moment';
import { IconButton, Tab, Tabs } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { FlrButtonKhaki, FlrButtonOutlinedBrown } from 'components/shared/buttons';
import FlrCard from 'components/shared/card/FlrCard';
import FlrDatePicker from 'components/shared/form-elements/FlrDatePicker';
import Icon from 'components/shared/Icon';
import { FlrTableV2 } from 'components/shared/table';
import { Link2 } from 'components/shared/text';
import {
  isReturnOrRefundTransaction,
  Transaction,
  TransactionOperation,
  TransactionOperationType
} from 'models/transaction';
import { baseUrl } from 'shared/constants';
import { IApplicationState } from 'store/reducers';
import { fetchTransactionsAsync } from 'store/transactions/actions';
import { getTransactionsDataList, getTransactionsLoadingState } from 'store/transactions/selectors';
import messages from 'translations/account/finance';
import { HttpClient } from 'utils/http';

import { breadCrumbsContext } from '../breadCrumbsState';
import DetailsExpandPanel from './DetailsExpandPanel';
import TableColumns, { columnsMobile, dashboardColumns } from './TableColumns';

import classes from './Table.module.scss';

interface IProps {
  isDashboard?: boolean;
}

interface IStateProps {
  transactions: Transaction[];
  transactionsLoadingState: boolean;
}

interface IDispatchProps {
  loadTransactions: typeof fetchTransactionsAsync.request;
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

interface ITab {
  key: string;
  path: string;
  label: string;
  transactionType: any;
}

const rootPath = `/account/finance`;
const paths: ITab[] = [
  {
    key: 'all',
    path: `${rootPath}`,
    label: messages.filterAll.defaultMessage,
    transactionType: ''
  },
  {
    key: 'orders',
    path: `${rootPath}/orders`,
    label: messages.filterOrder.defaultMessage,
    transactionType: TransactionOperation.Order
  },
  {
    key: 'payment',
    path: `${rootPath}/payment`,
    label: messages.filterPayment.defaultMessage,
    transactionType: TransactionOperation.Payment
  },
  {
    key: 'return',
    path: `${rootPath}/return`,
    label: messages.filterReturn.defaultMessage,
    transactionType: TransactionOperation.Refund
  },
  {
    key: 'reclamation',
    path: `${rootPath}/reclamation`,
    label: messages.filterReclamation.defaultMessage,
    transactionType: TransactionOperation.Reclamation
  }
];

const isMobile = Boolean(isMobileDevice(window.navigator));

const Table: FC<IComponentProps> = ({ transactions, transactionsLoadingState, loadTransactions, isDashboard }) => {
  const { status } = useParams<{ status: string }>();

  useEffect(() => {
    loadTransactions();
  }, [loadTransactions]);

  /*start from week ago*/
  const [dateXlsFrom, setDateXlsFrom] = useState<Date | null>(new Date(Date.now() - 60 * 60 * 24 * 7 * 1000));
  const [dateXlsTo, setDateXlsTo] = useState<Date | null>(new Date());
  const handleDateChange =
    (isTo = false) =>
    (date: any) => {
      if (isTo) {
        return setDateXlsTo(date);
      }
      setDateXlsFrom(date);
    };

  const initialTab = status ? paths.findIndex((item) => item.path.indexOf(status) >= 0) : 0;
  const [tab, setTab] = useState(initialTab > 0 ? initialTab : 0);
  const [transactionsFilter, setTransactionsFilter] = useState(initialTab > 0 ? paths[initialTab].transactionType : '');

  const handleUpdateFilter = (state: string) => {
    setTransactionsFilter(state);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCancel = () => {
    setModalOpen(false);
  };
  const handleModalConfirm = () => {
    handleDownloadPDF();
    setModalOpen(false);
  };

  const handleDownloadPDF = () => {
    const url = `${env.apiUrl}/account/transactions/exportToCsv`;
    const httpClient = new HttpClient(url);
    const token = window.localStorage.getItem('token') || '';
    const headers = { Authorization: token };

    httpClient
      .get('', {
        params: {
          startDate: dateXlsFrom,
          endDate: dateXlsTo
        },
        responseType: 'blob',
        headers
      })
      .then((response) => {
        downloadFile(response.data, 'transactions.xlsx');
      })
      .catch((err) => {
        // todo show toast
      });
  };

  transactions.forEach((txnItem) => {
    let transactionOperation = txnItem?.operation?.onModel;
    if (isReturnOrRefundTransaction(txnItem)) {
      switch (txnItem?.operationType) {
        case TransactionOperationType.debit:
          transactionOperation = TransactionOperation.Reclamation;
          break;
        case TransactionOperationType.credit:
          transactionOperation = TransactionOperation.Refund;
          break;
      }
    }
    txnItem.transactionOperation = transactionOperation;
  });

  const filteredTransactions = transactionsFilter
    ? transactions.filter((item) => item.transactionOperation === transactionsFilter)
    : transactions;
  const transactionFilterTypesAvailable = transactions
    .map((transaction) => transaction.transactionOperation || '')
    .filter((transactionOperation, i, arr) => arr.findIndex((t) => t === transactionOperation) === i);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    handleUpdateFilter(paths[newValue].transactionType);
    setTab(newValue);
  };

  const { setCurrentUrl } = useContext(breadCrumbsContext);
  const updateBreadCrumbs = useCallback(() => {
    const { label } = paths[tab];

    setCurrentUrl([{ label: messages.title.defaultMessage }, { label }]);
  }, [setCurrentUrl, tab]);

  useEffect(() => {
    updateBreadCrumbs();
  }, [updateBreadCrumbs]);

  useEffect(() => {
    updateBreadCrumbs();

    return () => {
      setCurrentUrl([]);
    };
  }, [setCurrentUrl, tab, updateBreadCrumbs]);

  const tableCommonProps = {
    data: filteredTransactions,
    isLoadingExternal: transactionsLoadingState,
    hidePagination: isDashboard
  };

  const columns = isMobile ? columnsMobile : !isDashboard ? TableColumns : dashboardColumns;

  return (
    <FlrCard className={clsx(classes.tableContainer, { [classes.tableContainerDashboard]: isDashboard })} zeroPadding>
      <div className={clsx(classes.titleContainer, {[classes.tableContainerDashboard]: isDashboard})}>
        <span className={classes.label}>
          {isDashboard ? messages.lastTransactions.defaultMessage : messages.transaction.defaultMessage}
        </span>
        {isDashboard ? <Link2 to={`${baseUrl}/account/finance`}>Всі транзакції</Link2> : null}
        {!isDashboard && isMobile ? (
          <IconButton onClick={handleOpenModal} size="large" className={classes.btn}>
            <Icon type={'download'} size={24} opacity={1} />
          </IconButton>
        ) : null}
      </div>
      {!isDashboard ? (
        <div className={classes.tableToolbarContainer}>
          <Tabs
            className={classes.TabsRoot}
            value={tab}
            onChange={handleChange}
            indicatorColor={'primary'}
            variant="scrollable"
            scrollButtons={false}
          >
            {paths.map((tabItem) => {
              return (
                <Tab
                  disabled={Boolean(
                    tabItem.transactionType && transactionFilterTypesAvailable.indexOf(tabItem.transactionType) < 0
                  )}
                  key={tabItem.path}
                  label={tabItem.label}
                  component={Link}
                  to={`${baseUrl}${tabItem.path}`}
                />
              );
            })}
          </Tabs>

          <div className={classes.filterContainer}>
            <Link2 className={classes.downloadButton} onClick={handleOpenModal}>
              <Icon type={'attach'} offset={8} size={16} />
              <span>{messages.transactionDownloadXLS.defaultMessage}</span>
            </Link2>
          </div>
        </div>
      ) : null}
      <FlrTableV2
        {...tableCommonProps}
        detailPanel={isMobile ? DetailsExpandPanel : null}
        columns={columns}
        classNameWrapper={classes.tableContentWrapper}
      />
      <Dialog
        open={modalOpen}
        onClose={handleCancel}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{messages.downloadModalTitle.defaultMessage}</DialogTitle>
        <DialogContent dividers={true}>
          <p>{messages.downloadModalCaption.defaultMessage}</p>
          <div className={classes.downloadForm}>
            <FlrDatePicker
              id="date-from"
              maxDate={moment(dateXlsTo)}
              label={messages.downloadDateFrom.defaultMessage}
              value={moment(dateXlsFrom)}
              onChange={handleDateChange()}
            />
            <FlrDatePicker
              id="date-to"
              disableFuture
              label={messages.downloadDateTo.defaultMessage}
              value={moment(dateXlsTo)}
              minDate={moment(dateXlsFrom)}
              onChange={handleDateChange(true)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <FlrButtonOutlinedBrown onClick={handleCancel} color="primary">
            {messages.cancelBtnLabel.defaultMessage}
          </FlrButtonOutlinedBrown>
          <FlrButtonKhaki onClick={handleModalConfirm} color="primary">
            {messages.downloadModalButton.defaultMessage}
          </FlrButtonKhaki>
        </DialogActions>
      </Dialog>
    </FlrCard>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  transactions: getTransactionsDataList(state),
  transactionsLoadingState: getTransactionsLoadingState(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, {}> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      loadTransactions: fetchTransactionsAsync.request
    },
    dispatch
  )
});

function downloadFile(data: any, fileName: string): void {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName || 'file.pdf');
  document.body.appendChild(link);
  link.click();
}

export default connect(mapStateToProps, mapDispatchToProps)(Table);
