import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDiscountConditionsAsync } from 'store/discount-conditions/actions';
import { getDiscountConditionsLoadingState } from 'store/discount-conditions/selectors';
import { useTrackInitialPageLoad } from 'controllers';
import { baseUrl } from 'shared/constants';
import messages from 'translations/account/loyalty';
import FlrLoader from 'components/loading/LoadingSpinner';
import BalanceDiscount from './BalanceDiscount';
import ProductGroupDiscount from './ProductGroupDiscount';

interface IProps {}

export const loyaltyBreadcrumb = {
  label: messages.title.defaultMessage,
  path: `/account/loyalty`
};

export const loyaltyBreadcrumbMobile = {
  label: messages.titleMobile.defaultMessage,
  path: `/account/loyalty`
};

export const AccountLoyalty: React.FC<IProps> = () => {
  useTrackInitialPageLoad();

  const dispatch = useDispatch();
  const discountConditionsLoading = useSelector(getDiscountConditionsLoadingState);

  useEffect(() => {
    if (!discountConditionsLoading) {
      dispatch(fetchDiscountConditionsAsync.request());
    }
    // eslint-disable-next-line
  }, []);

  if (discountConditionsLoading) {
    return (
      <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
        <Grid item>
          <FlrLoader size={100} />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Route exact path={`${baseUrl}/account/loyalty`} component={BalanceDiscount} />
      <Route exact path={`${baseUrl}/account/loyalty/:discountGroupId`} component={ProductGroupDiscount} />
    </>
  );
};
