import React from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import { useLocation } from 'react-router';
import get from 'lodash/get';

import { Order, orderStatusOptions } from 'models';

import { Link3, TextBody2 } from 'components/shared/text';
import { GridDivider } from 'components/shared/table/dividers';
import { defaultLocale, UAH } from 'shared/constants';
import { SplitedPrice } from 'components/prices';

import messages from 'translations/account/order';
import styles from './styles';

interface IProps {
  // component own props
  data: Order;
  classes: any;
}

const prettyDate = (date: Date, withDayOfWeek?: boolean) => {
  const mdate = moment(date);
  return mdate.locale(defaultLocale).format(withDayOfWeek ? 'dd DD.MM.YYYY' : 'DD.MM.YYYY');
};

const AccountOrdersTableMobileRowExpanded: React.FC<IProps> = ({ classes, data }) => {
  const { pathname } = useLocation();

  return (
    <Grid item container direction="column" className={classes.expandedMobileContainer}>
      <Grid item container alignItems="flex-start" classes={{ root: classes.expandedMobileRow }}>
        <Grid item xs>
          <TextBody2 color="textSecondary">{messages.title.defaultMessage}</TextBody2>
        </Grid>
        <Grid item container xs spacing={1} direction="column">
          <Grid item>
            <TextBody2 align="right">
              <Link3 to={`${pathname}/${data.orderId}`}>№ {data.orderId}</Link3>
            </TextBody2>
          </Grid>
          <Grid item>
            <TextBody2 color="textSecondary" align="right">
              {messages.tableStatusFrom.defaultMessage} {prettyDate(data.createdAt)}
            </TextBody2>
          </Grid>
        </Grid>
      </Grid>
      <GridDivider />
      <Grid item container alignItems="flex-start" classes={{ root: classes.expandedMobileRow }}>
        <Grid item xs>
          <TextBody2 color="textSecondary">{messages.tableStatus.defaultMessage}</TextBody2>
        </Grid>
        <Grid item xs>
          <TextBody2 color="textSecondary" align="right">
            {orderStatusOptions[data.orderStatus]}
          </TextBody2>
        </Grid>
      </Grid>
      <GridDivider />
      <Grid item container alignItems="flex-start" classes={{ root: classes.expandedMobileRow }}>
        <Grid item xs>
          <TextBody2 color="textSecondary">{messages.tableDateShipment.defaultMessage}</TextBody2>
        </Grid>
        <Grid item container xs spacing={1} direction="column" justifyContent="flex-end">
          <Grid item>
            <TextBody2 align="right">{prettyDate(data.deliveryDate || new Date(), true)}</TextBody2>
          </Grid>
          <Grid item>
            <TextBody2 align="right" color="textSecondary">
              {data.deliveryTime ? data.deliveryTime.name : ''}
            </TextBody2>
          </Grid>
        </Grid>
      </Grid>
      <GridDivider />
      <Grid item container alignItems="flex-start" classes={{ root: classes.expandedMobileRow }}>
        <Grid item xs>
          <TextBody2 color="textSecondary">{messages.tableDeliveryType.defaultMessage}</TextBody2>
        </Grid>
        <Grid item xs>
          <TextBody2 align="right">{get(data, 'orderDetails.deliveryMethod.name', '-')}</TextBody2>
        </Grid>
      </Grid>
      <GridDivider />
      <Grid item container alignItems="flex-start" classes={{ root: classes.expandedMobileRow }}>
        <Grid item xs>
          <TextBody2 color="textSecondary">{messages.tableDeliveryAddress.defaultMessage}</TextBody2>
        </Grid>
        <Grid item container xs spacing={1} direction="column">
          <Grid item>
            <TextBody2 align="right">{data.outlet!.name}</TextBody2>
          </Grid>
          <Grid item>
            <TextBody2 color="textSecondary" align="right">
              {data.outlet!.address}
            </TextBody2>
          </Grid>
        </Grid>
      </Grid>
      <GridDivider />
      <Grid item container alignItems="flex-start" classes={{ root: classes.expandedMobileRow }}>
        <Grid item xs>
          <TextBody2 color="textSecondary">{messages.tableDeliveryCount.defaultMessage}</TextBody2>
        </Grid>
        <Grid item container xs spacing={1} direction="column">
          <Grid item>
            <TextBody2 align="right">
              <SplitedPrice
                fontColor="inherit"
                fontSize="caption"
                bold={false}
                hideDecimal
                value={data.totalQty}
                postfix="шт"
              />
            </TextBody2>
          </Grid>
          <Grid item>
            <TextBody2 color="textSecondary" align="right">
              <SplitedPrice
                fontColor="inherit"
                fontSize="caption"
                bold={false}
                hideDecimal
                value={data.totalPackings}
                postfix="уп."
              />
            </TextBody2>
          </Grid>
        </Grid>
      </Grid>
      <GridDivider />
      <Grid item container alignItems="flex-start" classes={{ root: classes.expandedMobileRow }}>
        <Grid item xs>
          <TextBody2 color="textSecondary">{messages.tableDeliveryAmount.defaultMessage}</TextBody2>
        </Grid>
        <Grid item xs>
          <TextBody2 align="right">
            <SplitedPrice
              fontColor="black"
              fontSize="caption"
              decimalSameSize
              value={data.totalSum}
              postfix={UAH}
            />
          </TextBody2>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles<any>(styles)(AccountOrdersTableMobileRowExpanded);
