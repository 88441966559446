import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import IconButton from '@mui/material/IconButton';
import messages from 'translations/layout/table';
import { Icon } from 'components/shared';

const useStyles1 = makeStyles(() =>
  createStyles({
    root: {
      flexShrink: 0
    }
  })
);

interface ITablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

export function TablePaginationActions(props: ITablePaginationActionsProps) {
  const classes = useStyles1();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const isLastPage = page >= Math.ceil(count / rowsPerPage) - 1;

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page" size="large">
        <Icon type="firstPage" size={24} opacity={page === 0 ? 0.2 : 0.65} />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page" size="large">
        <Icon type="keyboardArrowLeft" size={24} opacity={page === 0 ? 0.2 : 0.65} />
      </IconButton>
      {page * rowsPerPage + 1}-{isLastPage ? count : page * rowsPerPage + rowsPerPage}&nbsp;
      {messages.rowsOf.defaultMessage}&nbsp;{count}
      <IconButton onClick={handleNextButtonClick} disabled={isLastPage} aria-label="next page" size="large">
        <Icon type="keyboardArrowRight" size={24} opacity={isLastPage ? 0.2 : 0.65} />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={isLastPage} aria-label="last page" size="large">
        <Icon type="lastPage" size={24} opacity={isLastPage ? 0.2 : 0.65} />
      </IconButton>
    </div>
  );
}
