export const baseUrl = '';

export const defaultLocation = 'Киев';
export const cookiesAllowedName = 'flrAcceptCookies';
export const eternal = 24 * 365 * 100;
export const defaultLocale = 'uk';
export const defaultCurrency = 'UAH';
export const UAH = '₴';

export interface IPhoneContact {
  phone: string;
  icon?: string;
  additionalText?: string;
}

export interface ISocialIcon {
  icon: 'viber' | 'telegram';
  title: string;
  link: string;
  description: string;
}

export const phoneDefault = '0 800 202 862';
export const phoneContacts: IPhoneContact[] = [
  { phone: '0 800 202 862', additionalText: '(безкоштовно з усіх операторів)' }
  // { phone: "050 804 50 04", icon: "mts" }
  // { phone: "063 804 50 04", icon: "lifecell" }
];

// const chatId = 'u816689430-1596692233vid50ce5bac-86f3-4129-a85f-f7cf6c85edbb';
export const socialIconsDefault: ISocialIcon[] = [
  {
    icon: 'viber',
    title: 'Floroteka',
    link: `viber://pa?chatURI=floroteka&context=u816689430-1596692233vid50ce5bac-86f3-4129-a85f-f7cf6c85edbb`,
    description: 'Viber'
  },
  { icon: 'telegram', title: 'FlorotekaBot', link: `https://t.me/FlorotekaBot`, description: 'Telegram' }
];

export const homeMaxWidth = 1720;
export const useSimpleScrollUnderRowsNumber = 500;
export const productListPadding = 32;
export const productCardHeight = 236 + 9;
export const tableViewRowHeight = 36;
export const tableViewRowMobileHeight = 78;
export const productCardHeightMobile = 254 - 12;
export const productCardHeightMobileLogged = 28;
export const productCardsMargin = 16;
export const productCardMinWidth = 256;
export const leftDrawerWidth = 280;
export const rightDrawerWidth = 424;
export const drawerWidthDevice = 464;
export const filtersDrawerWidth = leftDrawerWidth;
export const cartDrawerWidth = rightDrawerWidth;
export const headerHeight = 128;
export const headerHeightTablet = 124;
export const mobileHeaderHeight = 54;
export const mobileSearchHeaderHeight = 54;
export const breadcrumbsHeight = 51;
export const devicePanelHeight = 54;
export const flrQuantityMobileHeight = 67 + 23;
export const fadeSize = 8;
export const hideRightPanelWidth = 1858;
export const hideLeftPanelWidth = 1550;

export const catalogProductTypeShear = 'shear-flowers';
export const catalogProductTypeAccessories = 'accessories';
export const catalogProductTypePotted = 'potted-flowers';
export const catalogProductTypeStable = 'preserved-flowers';
export const catalogProductTypeCeramic = 'ceramics';
export const categories: Record<string, string> = {
  [catalogProductTypeShear]: 'Зрізані квіти',
  [catalogProductTypePotted]: 'Рослини у вазонах',
  [catalogProductTypeStable]: 'Стабілізовані квіти',
  [catalogProductTypeAccessories]: 'Фурнітура',
  [catalogProductTypeCeramic]: 'Кераміка'
};

export type ProductTypeAlias =
  | typeof catalogProductTypeShear
  | typeof catalogProductTypePotted
  | typeof catalogProductTypeStable
  | typeof catalogProductTypeAccessories;

export const catalogProductTypeCodes: Record<string, string> = {
  [catalogProductTypeShear]: '000000002',
  [catalogProductTypePotted]: '000000003',
  [catalogProductTypeStable]: '000000005',
  [catalogProductTypeAccessories]: '000000001',
  [catalogProductTypeCeramic]: '000000007'
};

export const catalogTypeSlugByCode: Record<string, string> = {
  [catalogProductTypeCodes[catalogProductTypeShear]]: catalogProductTypeShear,
  [catalogProductTypeCodes[catalogProductTypePotted]]: catalogProductTypePotted,
  [catalogProductTypeCodes[catalogProductTypeStable]]: catalogProductTypeStable,
  [catalogProductTypeCodes[catalogProductTypeAccessories]]: catalogProductTypeAccessories,
  [catalogProductTypeCodes[catalogProductTypeCeramic]]: catalogProductTypeCeramic
};

export const defaultCatalogProductType = Object.keys(categories)[0];
export const defaultCatalogInStock = 'inStock=1;';

export const cartGroupCheckoutStorageKey = 'flrCheckoutCartGroup';
export const registrationFormStepStorageKey = 'flrRegistrationForm';
export const registrationShouldClearLocalstorageKeys = 'flrRegistrationShouldClearLocalstorageKeys';
export const registrationTokenStorageKey = 'regToken';

export const CART_WARNING_NOTIFICATION_MINUTES_LEFT = 15;

export const CATALOG_V2 = false;

export const INFINITE = '∞';
