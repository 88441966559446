import { defaultMaterialTheme, ITheme } from 'utils/styled';
import brandColors from '../colors/brandColors';

const fontFamilyDefault =
  "'Cera Pro', 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Arial, sans-serif";

export const palette = {
  common: {
    black: '#000000',
    white: '#ffffff',
    primaryHoverPress: '#2a0c5b',
    primaryClickablePressBackground: '#f1e8f7',
    primaryAccentText: '#8f9e1c',
    errorWarningBackground: '#fdd4d1',

    /** gray */
    helperBlack: 'rgba(0, 0, 0, 0.44)',
    buttonOutlined: 'rgba(0, 0, 0, 0.23)',
    disabledBlack: 'rgba(0, 0, 0, 0.2)',
    disabledGrayBackground: 'rgba(0, 0, 0, 0.04)',
    disabledBlackBackground: 'rgba(0, 0, 0, 0.08)',
    cardBlackBackground: 'rgba(0, 0, 0, 0.02)',
    cardBlackBackgroundHex: '#F9F9F9',
    lines: 'rgba(0, 0, 0, 0.12)',
    fireBordered: '#D770AD'
  },
  primary: {
    main: '#8e3fbe',
    light: '#f1e8f7'
  },
  secondary: {
    main: '#cddc39'
  },
  error: { main: '#e91e63' },
  info: { main: '#009bdd' },
  success: { main: '#cddc39' },
  warning: { main: '#ffb200' },
  text: {
    primary: 'rgba(0, 0, 0, 0.8)',
    secondary: 'rgba(0, 0, 0, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.2)',
    hint: 'rgba(0, 0, 0, 0.38)'
  },
  gray: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161'
  },
  divider: 'rgba(0, 0, 0, 0.2)'
};

export const confirmDefaultProps = {
  cancellationButtonProps: {
    variant: 'outlined' as 'outlined',
    color: 'primary' as 'primary'
  },
  confirmationButtonProps: {
    variant: 'contained' as 'contained',
    color: 'primary' as 'primary'
  }
};

export const typography = {
  fontFamily: fontFamilyDefault,
  htmlFontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    marginTop: defaultMaterialTheme.spacing(3),
    marginBottom: defaultMaterialTheme.spacing(3),
    fontFamily: fontFamilyDefault,
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 1.2,
    color: palette.text.primary,

    '&:first-child': {
      marginTop: 0
    }
  },
  h2: {
    marginTop: defaultMaterialTheme.spacing(2),
    marginBottom: defaultMaterialTheme.spacing(2),
    fontFamily: fontFamilyDefault,
    fontWeight: 700,
    fontSize: 20,
    lineHeight: 1.2,
    color: palette.text.primary
  },
  // Founds
  h3: {
    marginTop: defaultMaterialTheme.spacing(2),
    marginBottom: defaultMaterialTheme.spacing(2),
    fontFamily: fontFamilyDefault,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: 1.2,
    color: palette.text.primary
  },
  // h4 subtitle1
  h4: {
    fontSize: '1.25rem'
  },
  // Coins
  h5: {
    marginTop: defaultMaterialTheme.spacing(1.5),
    marginBottom: defaultMaterialTheme.spacing(1.5),
    fontFamily: fontFamilyDefault,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: 1.2,
    color: palette.text.primary
  },
  // Helper Text
  h6: {
    fontFamily: fontFamilyDefault,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.2,
    color: palette.text.primary
  },
  subtitle1: {
    fontFamily: fontFamilyDefault,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.2,
    color: palette.text.primary
  },
  body1: {
    fontFamily: fontFamilyDefault,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.4,
    color: palette.text.primary
  },
  body2: {
    fontFamily: fontFamilyDefault,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1,
    color: palette.text.primary
  },
  button: {
    fontFamily: fontFamilyDefault,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.2,
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    color: palette.text.primary
  },
  // 2 BUTTON
  button2: {
    fontFamily: fontFamilyDefault,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1,
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    color: palette.text.primary
  },

  // Caption
  caption: {
    fontFamily: fontFamilyDefault,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1,
    color: palette.text.primary
  },

  // 1 Link1
  link: {
    paddingBottom: 0,
    borderBottom: `1px solid ${palette.text.primary}`,
    fontFamily: fontFamilyDefault,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.2,
    color: palette.text.primary,
    textDecoration: 'none !important'
  },
  // 2 Link
  link2: {
    paddingBottom: 0,
    borderBottom: `1px solid ${palette.common.black}`,
    fontFamily: fontFamilyDefault,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1,
    letterSpacing: '0.03em',
    textDecoration: 'none !important'
  },
  // 3 Link
  link3: {
    paddingBottom: 0,
    borderBottom: `1px solid ${palette.common.black}`,
    fontFamily: fontFamilyDefault,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.2,
    color: palette.common.black,
    textDecoration: 'none !important'
  }
};

export const shadow = {
  /* Shadow-Droplist & Popover */
  popoverDroplist: {
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.16)'
  },
  /* Shadow-Card-Active */
  cardActive: {
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.16)'
  },
  /* Shadow-Card-Hover&FAB */
  cardHover: {
    boxShadow: '2px 4px 20px rgba(0, 0, 0, 0.16)'
  },
  /* Shadow-Card-Selected */
  cardSelected: {
    boxShadow: ' 4px 4px 10px rgba(115, 52, 133, 0.16)'
  },
  /* Shadow-Catalogue Item-Selected */
  catalogItemSelected: {
    boxShadow: '0px 0px 24px rgba(142, 63, 190, 0.4)'
  },
  /* Shadow-Toggle Button */
  buttonToggle: {
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)'
  }
};

const scrollBar = {
  overflowY: 'auto',

  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0,0,0,0.25) transparent',

  '&::-webkit-scrollbar': {
    width: 4,
    height: 4
  },

  '&::-webkit-scrollbar-track': {
    borderRadius: 10,
    backgroundColor: 'transparent'
  },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: 2,
    backgroundColor: 'rgba(0,0,0,0.15)'
  }
};

const defaultTheme: ITheme = {
  palette,
  typography,
  shadow,
  scrollBar,

  colors: brandColors,
  opacities: {
    iconDefault: '0.65',
    iconHover: '0.85'
  },
  fonts: {
    headings:
      "'Cera Pro', 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Arial, sans-serif",
    body:
      "'Cera Pro', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Arial, sans-serif",
    monospace:
      "'Cera Pro', 'IBM Plex Mono', Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace, monospace"
  },
  line: {
    height: '28px'
  },
  fontSizes: {
    tiny: '12px',
    small: '14px',
    default: '14px',
    medium: '16px',
    large: '18px',
    h1: '32px',
    h2: '26px',
    h3: '1.563rem',
    h4: '1.25rem',
    h5: '14px',
    h6: '12px',
    caption: '14px',
    body1: '16px',
    body2: '14px',
    subtitle1: '16px',
    subtitle2: '14px'
  },
  letterSpacing: '0.03em',
  padding: {
    container: '1.5rem',
    card: '0.5rem',
    cardInner: '0.75rem',
    cards: '3rem'
  },
  containerPadding: '1.5rem',
  cardContainerPadding: '1rem',
  cardBorderRadius: '8px',
  buttonPadding: {
    tiny: '6px 16px',
    small: '9px 24px',
    default: '12px 32px',
    medium: '12px 32px',
    large: '15px 40px'
  },
  breakpoints: {
    xs: '0px',
    sm: '740px',
    md: '960px',
    lg: '1366px',
    xl: '1920px',
    values: { xs: 0, sm: 740, md: 960, lg: 1366, xl: 1920 }
  },
  widths: {
    md: '820px',
    lg: '1124px',
    xl: '1440px'
  },
  heights: {
    header: '60px',
    catalog: {
      header: '120px',
      sortBar: '48px'
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        colorError: {
          '&&': {
            color: palette.error.main
          }
        }
      }
    },
    MuiPickersModal: {
      styleOverrides: {
        dialogRoot: {
          padding: 0,

          '& .MuiDialogActions-root': {
            padding: defaultMaterialTheme.spacing(1)
          }
        }
      }
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        switchHeader: {
          marginTop: defaultMaterialTheme.spacing(1.5),
          marginBottom: defaultMaterialTheme.spacing(2)
        },
        transitionContainer: {
          height: 14,

          '& .MuiTypography-root': {
            ...typography.caption,
            textTransform: 'capitalize'
          }
        },
        daysHeader: {
          paddingBottom: defaultMaterialTheme.spacing(3),
          borderBottom: `1px solid ${palette.common.disabledBlackBackground}`
        },
        dayLabel: {
          ...typography.caption,
          textTransform: 'capitalize',
          color: palette.text.secondary
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          ...typography.body1,
          color: palette.text.secondary,
          textTransform: 'none',
          textDecoration: 'none',
          opacity: 1,
          padding: '6px 12px',
          overflow: 'hidden',
          position: 'relative',
          maxWidth: '264px',
          boxSizing: 'border-box',
          minHeight: 48,
          textAlign: 'center',
          flexShrink: 0,
          whiteSpace: 'normal',
          letterSpacing: 0.64,
          flexDirection: 'row',
          justifyContent: 'flex-start',

          '&&': {
            minWidth: 30
          },
          '&.Mui-selected': {
            color: palette.primary.main,
            opacity: 1
          },
          '&.Mui-disabled': {
            color: palette.common.disabledBlack,
            opacity: 0.5
          },

          '& .MuiTab-textColorInherit': {
            opacity: 1,

            '&:active': {
              textDecoration: 'none',
              color: palette.common.primaryHoverPress
            }
          }
        },
        wrapper: {
          flexDirection: 'row',
          alignItems: 'center'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          ...typography.link,
          transition: defaultMaterialTheme.transitions.create(['color', 'border-color'], {
            easing: defaultMaterialTheme.transitions.easing.sharp,
            duration: defaultMaterialTheme.transitions.duration.leavingScreen
          }),

          '& .MuiTypography-colorError &': {
            color: palette.text.primary,
            borderColor: palette.text.primary,

            '&:hover, &:active': {
              borderColor: palette.error.main,
              color: palette.error.main,
              textDecoration: 'none !important'
            }
          },

          '& .MuiTypography-colorSecondary &': {
            color: palette.secondary.main,
            borderColor: palette.secondary.main,

            '&:hover': {
              opacity: 0.8
            },
            '&:active': {
              opacity: 0.6
            }
          },

          '& .MuiTypography-colorSecondary:active &': {
            color: palette.secondary.main,
            borderColor: palette.secondary.main,
            textDecoration: 'none !important'
          },

          '&:hover': {
            color: palette.primary.main,
            borderColor: palette.primary.main,
            textDecoration: 'none !important',

            '& .MuiTypography-colorSecondary &': {
              color: palette.secondary.main,
              borderColor: palette.secondary.main,
              opacity: 0.8
            }
          },

          '&:active': {
            color: palette.common.primaryHoverPress,
            borderColor: palette.common.primaryHoverPress,
            textDecoration: 'none !important'
          },

          color: 'inherit',
          borderColor: 'inherit'
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          border: '1px solid #d3d4d5'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
          marginRight: defaultMaterialTheme.spacing(1)
        }
      }
    },
    MuiDialog: {
      defaultProps: {
        scroll: 'body'
      },
      styleOverrides: {
        root: {},
        paperWidthSm: {
          maxWidth: 750,
          width: 750,
          '&.MuiDialog-paperScrollBody': {
            [defaultMaterialTheme.breakpoints.down('sm')]: {
              maxWidth: `calc(100% - ${defaultMaterialTheme.spacing(3)})`,
              margin: `${defaultMaterialTheme.spacing(1.5)}`
            }
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          [defaultMaterialTheme.breakpoints.down('md')]: {
            padding: defaultMaterialTheme.spacing(1)
          }
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          marginBottom: defaultMaterialTheme.spacing(5),
          [(defaultMaterialTheme as any).breakpoints.down('md')]: {
            padding: defaultMaterialTheme.spacing(1)
          }
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingTop: defaultMaterialTheme.spacing(3),
          justifyContent: 'space-between',
          borderTop: `1px solid ${palette.text.disabled}`
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...typography.h6,
          color: palette.text.secondary,

          '&.MuiFormHelperText-contained': {
            margin: '4px 10px 0'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled[data-shrink=true]': {
            display: 'none'
          },
          '&.MuiInputLabel-outlined': {
            margin: 0,
            color: palette.common.helperBlack,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '95%',
            textOverflow: 'ellipsis',

            '&.Mui-focused': {
              color: palette.primary.main
            }
          },
          '&.MuiInputLabel-outlined.Mui-error': {
            color: palette.error.main,

            '&.Mui-focused': {
              color: palette.error.main
            }
          }
        },
        outlined: {
          '&.MuiInputLabel-shrink': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '100%',
            textOverflow: 'ellipsis'
          }
        }
      }
    },
    MuiInputBase: {
      defaultProps: {
        variant: 'outlined'
      },
      styleOverrides: {
        root: {
          '& .MuiInputBase-input[type=password]': {
            letterSpacing: '0.2em'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: palette.common.helperBlack,
          borderRadius: 8,
          color: palette.common.black,

          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            backgroundColor: palette.common.disabledBlackBackground,
            borderColor: 'transparent',
            color: palette.common.disabledBlack
          },

          '& .MuiOutlinedInput-root': {},

          '& .MuiOutlinedInput-input': {
            padding: '10px 12px'
          },
          '& .MuiOutlinedInput-input[type=password]': {
            letterSpacing: '0.2em'
          },

          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.common.helperBlack,
            color: palette.common.black
          },

          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.common.helperBlack
          },

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.primary.main,
            // borderWidth: 1.5
            borderWidth: 2
          }
        },
        adornedEnd: {
          paddingRight: 14
        },
        multiline: {
          padding: 0
        }
      }
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 8,
          borderRadius: 8,
          color: palette.text.secondary,

          '&:hover': {
            color: palette.common.primaryHoverPress,
            backgroundColor: 'transparent'
          },
          '&:active': {
            backgroundColor: palette.common.primaryClickablePressBackground,
            color: palette.common.primaryHoverPress
          },
          '&:disabled': {
            color: palette.common.disabledBlack
          },

          '&.MuiIconButton-colorSecondary': {
            color: palette.text.secondary,

            '&:hover': {
              color: palette.error.main,
              backgroundColor: 'transparent'
            },
            '&:active': {
              backgroundColor: palette.common.errorWarningBackground,
              color: palette.error.main
            }
          },

          '&.MuiIconButton-sizeSmall': {
            backgroundColor: 'transparent',
            padding: 3,
            fontSize: 18,
            '&:hover': {
              backgroundColor: 'transparent'
            },
            '&:active': {
              backgroundColor: 'transparent'
            },
            '&.MuiIconButton-colorSecondary': {
              '&:active': {
                backgroundColor: 'transparent'
              }
            }
          }
        }
      }
    },
    MuiButtonGroup: {
      styleOverrides: {
        '&.MuiButtonGroup-groupedHorizontal:not(:last-child)': {
          borderTopRightRadius: '0 !important',
          borderBottomRightRadius: '0 !important'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 24,
          borderRadius: 4,

          '&.inactive': {
            opacity: 0.7
          }
        },
        label: {
          padding: '0 8px',
          fontSize: 12,
          lineHeight: 14,
          fontWeight: 400,
        },
        colorPrimary: {
          color: palette.primary.main,
          backgroundColor: palette.common.primaryClickablePressBackground
        },
        deletableColorPrimary: {
          position: 'relative',
          paddingRight: defaultMaterialTheme.spacing(1),

          '&:focus': {
            backgroundColor: palette.common.primaryClickablePressBackground
          },
          '&&:active': {
            backgroundColor: palette.common.errorWarningBackground,
            color: palette.error.main
          },
          '&:hover': {
            backgroundColor: palette.common.primaryClickablePressBackground,

            '& .MuiChip-deleteIconColorPrimary': {
              color: palette.error.main
            }
          }
        },
        deleteIconColorPrimary: {
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',

          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',

          color: palette.primary.main,

          '&:hover': {
            color: palette.error.main
          },
          '&:active': {
            color: palette.error.main
          },

          '& svg': {
            width: 8,
            height: 8
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '10px 24px',
          borderRadius: 8,
          letterSpacing: 'normal',
          transition: defaultMaterialTheme.transitions.create(['color', 'backgroundColor'], {
            easing: defaultMaterialTheme.transitions.easing.sharp,
            duration: defaultMaterialTheme.transitions.duration.leavingScreen
          }),

          '&:focus': {
            textDecoration: 'none'
          },

          '&.MuiButton-containedPrimary': {
            '&.MuiButton-sizeSmall': {
              padding: '10px 16px',
              borderRadius: 6,
              ...typography.button2,
              color: palette.common.white
            },

            '&:hover': {
              background: palette.common.primaryHoverPress
            },
            '&:active': {
              backgroundColor: palette.common.primaryClickablePressBackground,
              color: palette.common.primaryHoverPress
            },
            '&:disabled': {
              backgroundColor: palette.common.disabledBlackBackground,
              color: palette.common.disabledBlack
            }
          },

          '&.MuiButton-outlinedPrimary': {
            padding: '8px 24px',
            borderWidth: 2,
            borderColor: palette.primary.main,
            color: palette.primary.main,

            '&.MuiButton-sizeSmall': {
              padding: '8px 16px',
              borderRadius: 6,
              ...typography.button2,
              color: palette.primary.main
            },

            '&:hover': {
              borderWidth: 2,
              borderColor: palette.common.primaryHoverPress,
              color: palette.common.primaryHoverPress,
              background: palette.common.white
            },
            '&:active': {
              borderWidth: 2,
              borderColor: palette.common.primaryHoverPress,
              color: palette.common.primaryHoverPress,
              background: palette.common.primaryClickablePressBackground
            },
            '&:disabled': {
              borderWidth: 2,
              borderColor: palette.common.disabledBlack,
              backgroundColor: palette.common.white,
              color: palette.common.disabledBlack
            }
          },

          '&.MuiButton-textPrimary': {
            padding: '6px 8px',
            // color: palette.primary.main,
            '&.MuiButton-sizeSmall': { borderRadius: 6 },

            '&:hover': {
              color: palette.common.primaryHoverPress
              // background: 'transparent'
            },
            '&:active': {
              color: palette.common.primaryHoverPress,
              background: palette.common.primaryClickablePressBackground
            },
            '&:disabled': {
              color: palette.common.disabledBlack
            }
          },
          '&.MuiButton-textSecondary': {
            color: palette.primary.main,
            '&.MuiButton-sizeSmall': { borderRadius: 6 },

            '&:hover': {
              color: palette.error.main,
              background: 'transparent'
            },
            '&:active': {
              color: palette.error.main,
              background: palette.common.errorWarningBackground
            },
            '&:disabled': {
              color: palette.common.disabledBlack
            }
          },

          '&.MuiButton-containedSecondary': {
            background: palette.common.primaryHoverPress
          }
        }
      }
    },

    MuiCheckbox: {
      defaultProps: {
        color: 'primary'
      },
      styleOverrides: {
        root: {
          padding: 5,
          opacity: '1 !important',
          color: palette.common.helperBlack,
          backgroundColor: 'transparent',
          borderWidth: 1,
          borderRadius: '50%',

          '&.MuiCheckbox-colorPrimary': {
            backgroundColor: 'transparent',

            '&:hover': {
              color: palette.common.primaryHoverPress,
              backgroundColor: 'transparent'
            },
            '&.MuiCheckbox-indeterminate:hover': {
              color: palette.common.primaryHoverPress,
              backgroundColor: 'transparent'
            },
            '&.Mui-checked:hover': {
              color: palette.common.primaryHoverPress,
              backgroundColor: 'transparent'
            },
            '&.MuiCheckbox-indeterminate:active': {
              color: palette.common.primaryHoverPress,
              backgroundColor: palette.common.primaryClickablePressBackground
            },
            '&.Mui-checked:active': {
              color: palette.common.primaryHoverPress,
              backgroundColor: palette.common.primaryClickablePressBackground
            },
            '&:active': {
              color: palette.common.primaryHoverPress,
              backgroundColor: palette.common.primaryClickablePressBackground
            }
          }
        }
      }
    },

    MuiRadio: {
      defaultProps: {
        color: 'primary'
      },
      styleOverrides: {
        root: {
          opacity: '1 !important',
          color: palette.common.helperBlack,
          backgroundColor: 'transparent',
          borderWidth: 1,
          borderRadius: '50%',

          '&.MuiRadio-colorPrimary': {
            backgroundColor: 'transparent',

            '&hover': {
              color: palette.common.primaryHoverPress,
              backgroundColor: 'transparent'
            },
            '&.Mui-checked:hover': {
              color: palette.common.primaryHoverPress,
              backgroundColor: 'transparent'
            },
            '&.Mui-checked:active': {
              color: palette.common.primaryHoverPress,
              backgroundColor: palette.common.primaryClickablePressBackground
            },
            '&:active': {
              color: palette.common.primaryHoverPress,
              backgroundColor: palette.common.primaryClickablePressBackground
            }
          }
        }
      }
    },

    MuiSwitch: {
      defaultProps: {
        color: 'primary'
      },
      styleOverrides: {
        track: {
          backgroundColor: palette.common.disabledBlack
        },
        root: {
          '& .MuiSwitch-colorPrimary': {
            borderRadius: '50%',
            '&.Mui-checked': {
              '&.MuiSwitch-switchBase': {
                color: palette.primary.main,

                '&:hover': {
                  color: palette.common.primaryHoverPress
                }
              }
            },
            '&.MuiSwitch-switchBase': {
              borderRadius: '50%',
              color: '#8F8F8F'
            }
          }
        }
      }
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true
      },
      styleOverrides: {
        arrow: {
          color: palette.common.black
        },
        popper: {
          pointerEvents: 'default'
        },
        tooltip: {
          backgroundColor: palette.common.black,
          color: palette.common.white,
          borderRadius: 8,
          maxWidth: 'max(20vw, 300px)'
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: '1300 !important'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          ...shadow.cardActive
        },
        rounded: {
          borderRadius: 8
        }
      }
    },
    MuiTreeItem: {
      styleOverrides: {
        root: {
          '&:hover > .MuiTreeItem-content': {
            backgroundColor: 'transparent'
          },
          '&:focus > .MuiTreeItem-content': {
            backgroundColor: 'transparent'
          },

          '& .Mui-selected.MuiTreeItem-content': {
            backgroundColor: 'transparent'
          },
          '& .Mui-selected:focus.MuiTreeItem-content': {
            backgroundColor: 'transparent'
          },
          '& .Mui-selected:hover.MuiTreeItem-content': {
            backgroundColor: 'transparent'
          },
          '& .Mui-focused.MuiTreeItem-content': {
            backgroundColor: 'transparent'
          },
          '& .Mui-focused.Mui-selected.MuiTreeItem-content': {
            backgroundColor: 'transparent'
          },

          '& .Mui-checked + MuiFormControl-label': {
            color: palette.common.black
          },

          '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'transparent'
          },
          '&.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'transparent'
          },
          '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover': {
            backgroundColor: 'transparent'
          },
          '& > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'transparent'
          },
          '&:focus > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'transparent'
          },
          '& > .MuiTreeItem-content .MuiTreeItem-label:hover': {
            backgroundColor: 'transparent'
          }
        },
        content: {
          padding: 0
        },
        iconContainer: {
          width: 24,
          marginRight: 0
        }
      }
    },
    MuiTable: {},
    MuiTablePagination: {
      // select count of rows
      styleOverrides: {
        selectRoot: {
          display: 'none'
        },
        toolbar: {
          '& .MuiButtonBase-root': {
            '&.Mui-disabled:not(.MuiIconButton-root)': {
              color: palette.common.primaryHoverPress,
              backgroundColor: palette.common.primaryClickablePressBackground
            }
          }
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          flexDirection: 'row',

          '&& svg ': {
            fill: 'inherit'
          },

          '&&.MuiTableSortLabel-active ': {
            color: palette.primary.main,

            '& .MuiTableSortLabel-iconDirectionDesc': {
              '& .down': {
                fill: palette.primary.main
              },
              '& .up': {
                fill: palette.common.disabledBlack
              }
            },
            '& .MuiTableSortLabel-iconDirectionAsc': {
              '& .down': {
                fill: palette.common.disabledBlack
              },
              '& .up': {
                fill: palette.primary.main
              }
            }
          },

          '&&:hover .MuiTableSortLabel-icon': {
            opacity: 1,
            '& .down, & .up': {
              fill: palette.primary.main
            }
          },

          '&&:active .MuiTableSortLabel-icon': {
            '& .down, & .up': {
              fill: palette.common.primaryHoverPress
            }
          }
        },
        iconDirectionAsc: {
          transform: 'rotate(0deg)'
        },
        icon: {
          opacity: 1
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone >div': {
            justifyContent: 'flex-end'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          padding: 9,
          borderBottom: `1px solid ${palette.common.disabledBlackBackground}`,
          borderLeft: 'none',
          borderRight: 'none',
          width: 'auto !important',
          ...typography.body2,
          color: palette.text.secondary,

          '&.MuiTableCell-root': {
            backgroundColor: 'transparent'
          }
        },
        paddingCheckbox: {
          fontSize: 0,
          '& .MuiTableSortLabel-icon': {
            display: 'none'
          }
        },

        body: {
          ...typography.body1,
          color: palette.text.primary,
          width: 'auto !important',

          [defaultMaterialTheme.breakpoints.down('sm')]: {
            fontSize: 14
          },

          [`${defaultMaterialTheme.breakpoints.up('xs')}`]: {
            paddingRight: 0,
            paddingLeft: 0
          }
        },
        alignLeft: {
          paddingLeft: 0
        },
        alignRight: {
          paddingRight: 0
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          width: '100%',
          paddingLeft: defaultMaterialTheme.spacing(3),
          paddingRight: defaultMaterialTheme.spacing(3),
          paddingTop: defaultMaterialTheme.spacing(2),
          paddingBottom: defaultMaterialTheme.spacing(2),
          [defaultMaterialTheme.breakpoints.down('md')]: {
            paddingLeft: defaultMaterialTheme.spacing(2),
            paddingRight: defaultMaterialTheme.spacing(2)
          },
          [defaultMaterialTheme.breakpoints.down('sm')]: {
            paddingBottom: 0
          }
        },
        ol: {
          fontSize: 12,
          lineHeight: 1
        },
        li: {
          marginBottom: defaultMaterialTheme.spacing(0.5)
        },
        separator: {
          color: palette.common.helperBlack,
          fontSize: 12,
          lineHeight: 1.2
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '& .MuiAutocomplete-option': {
            display: 'flex',
            width: '100%'
          }
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        variant: 'outlined'
      }
    },
    MuiPopover: {
      defaultProps: {
        disableScrollLock: true,
        disableAutoFocus: true,
        disableRestoreFocus: true
      }
    },
    MuiInput: {
      defaultProps: {
        variant: 'outlined'
      }
    },
    TextField: {
      defaultProps: {
        variant: 'outlined'
      }
    }
    // MuiInputBase: {
    //   defaultProps: {
    //     variant: 'outlined'
    //   }
    // },
    // MuiTooltip: {
    //   defaultProps: {
    //     arrow: true
    //   }
    // },
    // MuiCheckbox: {
    //   defaultProps: {
    //     color: 'primary'
    //   }
    // },
    // MuiDialog: {
    //   defaultProps: {
    //     scroll: 'body'
    //   }
    // },
    // MuiRadio: {
    //   defaultProps: {
    //     color: 'primary'
    //   }
    // },
    // MuiSwitch: {
    //   defaultProps: {
    //     color: 'primary'
    //   }
    // }
  }
};

export const breakpoints = defaultTheme.breakpoints.values;

export default defaultTheme;
