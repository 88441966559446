import moment from 'moment';

import { IRequestOrderItem, OrderItem } from './order-item';
import { IMethod, Outlet } from './outlet';
import { Product } from './product';
import { User } from './user';
import { Warehouse } from './warehouse';

export const ORDER_TYPE_ORDER = 'order';
export const ORDER_TYPE_PREORDER = 'preorder';
export type OrderType = typeof ORDER_TYPE_ORDER | typeof ORDER_TYPE_PREORDER;

export const ORDER_STATUS_NEW = 'CHECKOUT';
export const ORDER_STATUS_RETURN = 'RETURN'; // "Возрат"

export const ORDER_STATUS_PREORDER = '000000002'; // "Предзаказ"
export const ORDER_STATUS_PREORDER_PENDING_APPROVAL = '000000027'; // "Предзаказ очікує підтвердження"
export const ORDER_STATUS_PENDING_APPROVAL = '000000023'; // "Очікує підтвердження"
// export const ORDER_STATUS_APPROVED = "000000002"; // "Пiдтверджено"
export const ORDER_STATUS_RESERVED = '000000024'; // "Зарезервовано"
export const ORDER_STATUS_PACKED = '000000025'; // "Скомплектовано"
export const ORDER_STATUS_SHIPPED = '000000003'; // "Доставка"
export const ORDER_STATUS_DELIVERED = '000000026'; // "Доставлено"
export const ORDER_STATUS_DONE = '000000022'; // "Выполнен"
export const ORDER_STATUS_CANCELED = '000000004'; // "Закрыт"

export const orderStatusOptions = {
  [ORDER_STATUS_NEW]: 'Формується користовачем',
  [ORDER_STATUS_PREORDER_PENDING_APPROVAL]: 'Передзамовлення очікує підтвердження',
  [ORDER_STATUS_PREORDER]: 'Передзамовлення пiдтверджено',
  [ORDER_STATUS_PENDING_APPROVAL]: 'Очікує підтвердження',
  [ORDER_STATUS_RESERVED]: 'Зарезервовано',
  [ORDER_STATUS_PACKED]: 'Скомплектовано',
  [ORDER_STATUS_SHIPPED]: 'Відправлено',
  [ORDER_STATUS_DELIVERED]: 'Доставлено',
  [ORDER_STATUS_DONE]: 'Виконано',
  [ORDER_STATUS_CANCELED]: 'Скасовано',
  [ORDER_STATUS_RETURN]: 'Повернення'
};

/*
*
* Заказ:
1/ Очікує підтвердження (000000023) Доставки
3/ Зарезервовано (000000024) Доставки
4/ Скомплектовано (000000025) Доставки
5/ Доставка (000000003) Доставки
6/ Доставлено (000000026) Очікують відгук
7/ Виконано (000000022) Завершені
8/ Скасовано (000000004) Завершені

Предзаказ:
1/ Очікує підтвердження (000000023) Передзамовлення
2/ Пiдтверджено (000000002) Передзамовлення
3/ Зарезервовано (000000024) Доставки
4/ Скомплектовано (000000025) Доставки
5/ Доставка (000000003) Доставки
6/ Доставлено (000000022) Очікують відгук
7/ Виконано (000000022) Завершені
8/ Скасовано (000000004) Завершені

* */

export type OrderStatus =
  | typeof ORDER_STATUS_PREORDER
  | typeof ORDER_STATUS_PREORDER_PENDING_APPROVAL
  | typeof ORDER_STATUS_NEW
  | typeof ORDER_STATUS_PENDING_APPROVAL
  // | typeof ORDER_STATUS_APPROVED
  | typeof ORDER_STATUS_RESERVED
  | typeof ORDER_STATUS_PACKED
  | typeof ORDER_STATUS_SHIPPED
  | typeof ORDER_STATUS_DELIVERED
  | typeof ORDER_STATUS_DONE
  | typeof ORDER_STATUS_RETURN
  | typeof ORDER_STATUS_CANCELED;

// export const ORDER_DELIVERY_FLOROTEKA = "floroteka";
// export const ORDER_DELIVERY_NOVAPOSHTA = "novaposhta";
// export const ORDER_DELIVERY_SELFPICKUP = "selfpickup";

// export type OrderDelivery =
//   | typeof ORDER_DELIVERY_FLOROTEKA
//   | typeof ORDER_DELIVERY_NOVAPOSHTA
//   | typeof ORDER_DELIVERY_SELFPICKUP;

// export const ORDER_DELIVERY_OPTIONS: OrderDelivery[] = [
//   ORDER_DELIVERY_FLOROTEKA,
//   ORDER_DELIVERY_NOVAPOSHTA,
//   ORDER_DELIVERY_SELFPICKUP
// ];

export const ORDER_PACKING_ORIGINAL = 'original';
export const ORDER_PACKING_COLD = 'cold';

export type OrderPacking = typeof ORDER_PACKING_ORIGINAL | typeof ORDER_PACKING_COLD;

export const ORDER_PACKING_OPTIONS: OrderPacking[] = [ORDER_PACKING_ORIGINAL, ORDER_PACKING_COLD];

export interface IDeliveryLocation {
  // address: string;
  // longitude: string;
  // latitude: string;
}

export interface IDeliveryMethod {
  code: string;
  name: string;
  description: string;
}

export interface ISelfPickUpMethod {
  name: string;
}

export interface IRequestOrder {
  id: string;
  deliveryMethod: string;
  outlet: string;
  deliveryDate: Date | null | string;
  deliveryTime?: IDeliveryTime | null;
  selfPickUpTime?: ISelfPickUpMethod | null;
  packageId: OrderPacking;
  packageReturn: boolean;
  warehouse: string;
  items: IRequestOrderItem[];
  notes: string;
}

export interface ITimeData {
  alias: string;
  description: string;
  id: string;
  name: string;
}

export interface IAvailableSelfPickUpTimeData {
  date: { [key: string]: string[] };
}

export interface IDeliveryTime {
  name: string;
  id: string;
  code?: string;
  description: string;
}

export interface IOrderDetails {
  cartGroupId: string;
  supplier: string;
  client: User;
  currency: {
    code: string;
    name: string;
  };
  deliveryMethod: IDeliveryMethod;
  deliveryTime: IDeliveryTime;
  outlet: Partial<Outlet>;
  warehouse: Warehouse;
}

export const getOrderItemsNameSort = (locale: any) => (a: OrderItem, b: OrderItem) => {
  return a.product.name.localeCompare(b.product.name, locale, {
    ignorePunctuation: true,
    usage: 'sort',
    numeric: true
  });
};

export const groupOrdersByStatuses = (summary: Record<OrderStatus, number> | null, statuses: OrderStatus[]) => {
  if (!summary) {
    return 0;
  }

  return statuses.reduce((results, current) => results + +summary[current], 0);
};

export class Order {
  public id: string;
  public orderId: string;
  public orderType: OrderType;
  public orderStatus: OrderStatus; // alias/code for actual orders orderStatus: "new" | "pending" | "......."
  public orderDetails: any;
  public createdAt: Date;
  public totalSum: number;
  public totalQty: number;
  public totalPackQty: number;
  public totalPackings: number;
  public totalDiscount: number;
  public shippingAmount: number;
  public shippingData: {
    outlets: Outlet[];
    methods: IMethod[];
    times: ITimeData[];
    timeSlots: ITimeData[];
    availableSelfPickDates: IAvailableSelfPickUpTimeData[];
  };

  public deliveryMethod: string;
  public outlet: Outlet | null;
  public deliveryDate: Date | null;
  public deliveryTime: IDeliveryTime | any; // alias/code for orderTime: "all" | "morning" | "noon" | "evening",
  public packageId: OrderPacking; // alias/code for BoxType: "own" | "cold" | "solid" | "brand" | "" ... etc
  public packageReturn: boolean;
  public warehouse: Warehouse;
  public items: OrderItem[];
  public token: string;
  public notes: string;
  public selfPickUpTime?: ISelfPickUpMethod | any;

  public feedbackReclamation: string;

  public processing: boolean;

  constructor(order: any) {
    this.id = order.id;
    this.orderId = order.orderId;
    this.orderType = order.orderType;
    this.orderStatus = order.orderStatus;
    this.createdAt = order.createdAt;
    this.totalSum = order.totalSum || 0;
    this.totalQty = order.totalQty || 0;
    this.totalPackQty = order.totalPackQty || 0;
    this.shippingAmount = order.shippingAmount || 0;
    this.totalPackings = order.totalPackings || 0;
    this.totalDiscount = order.totalDiscount || 0;
    this.notes = order.notes;
    this.shippingData = order.shippingData
      ? {
          methods:
            order.shippingData.methods && typeof order.shippingData.methods === 'object'
              ? Object.values(order.shippingData.methods)
                  // @ts-ignore
                  // make self pickup first
                  .sort((a: IMethod) => (a.alias === 'selfpickup' ? -1 : 0))
              : ([] as any),
          outlets:
            order.shippingData.outlets && Array.isArray(order.shippingData.outlets)
              ? order.shippingData.outlets.map((outletRaw: any) => new Outlet(outletRaw))
              : ([] as any),
          times: order.shippingData.times,
          timeSlots: order.shippingData.timeSlots,
          availableSelfPickDates: order.shippingData.availableSelfPickDates
        }
      : {
          methods: [],
          outlets: [],
          times: [],
          timeSlots: [],
          availableSelfPickDates: []
        };

    this.deliveryMethod = order.deliveryMethod;
    this.outlet = new Outlet({
      ...((order.orderDetails && order.orderDetails.outlet) || {}),
      ...(order.outlet || {})
    });
    this.orderDetails = order.orderDetails;
    this.deliveryDate = order.deliveryDate ? new Date(order.deliveryDate) : null;
    this.deliveryTime = order.deliveryTime;
    this.selfPickUpTime = order.selfPickUpTime;
    this.packageId = order.packageId;
    this.packageReturn = !!order.packageReturn;
    this.items =
      order.items &&
      order.items.length &&
      order.items.map((item: any) => {
        if (typeof item === 'object') {
          return new OrderItem(item);
        }
        return null;
      });
    if (this.items) {
      this.items = this.items.sort();
    }
    this.warehouse = new Warehouse(order.warehouse || {});
    this.token = order.token;

    this.feedbackReclamation = order.feedbackReclamation;

    this.processing = false;
  }

  public deliveryPrice() {
    return this.shippingAmount;
    /*if (this.deliveryMethod === ORDER_DELIVERY_FLOROTEKA) {
      return 100;
    }
    if (this.deliveryMethod === ORDER_DELIVERY_NOVAPOSHTA) {
      return 300;
    }
    return 0;*/
  }

  public packingPrice() {
    return 0;
    /*if (this.packageId === ORDER_PACKING_COLD) {
      return 500;
    }
    return 300;*/
  }

  public packingReturnPrice() {
    return 0;
    /*if (!this.packageReturn) {
      return 0;
    }
    if (this.packageId === ORDER_PACKING_ORIGINAL) {
      return 300;
    }
    return 0;*/
  }

  public totalSumAll() {
    return this.totalSum;
  }

  public totalItemsSumWithDiscount() {
    return this.items && this.items.length ? this.items.reduce((acc, i) => acc + i.total, 0) : 0;
  }

  public toRequest(): IRequestOrder {
    return {
      id: this.id,
      warehouse: this.warehouse.id,
      deliveryMethod: this.deliveryMethod,
      outlet: (this.outlet && this.outlet.id) || '',
      // deliveryDate: this.deliveryDate,
      deliveryDate: this.deliveryDate ? moment(this.deliveryDate).format('YYYY-MM-DD') : this.deliveryDate,
      deliveryTime: this.deliveryTime || null,
      selfPickUpTime: this.selfPickUpTime || null,
      packageId: this.packageId,
      packageReturn: this.packageReturn,
      items: this.items.map((item) => item.toRequestItem()),
      notes: this.notes
    };
  }

  public isValid(): boolean {
    return Boolean(
      this.deliveryMethod && (this.deliveryTime || this.selfPickUpTime) && this.deliveryDate && this.packageId
    );
  }
}

export interface IFlatOffer {
  id: string;
  productId: string;
  product: Product;
}

export interface ITransitOrderItem {
  discount: number;
  discountAmount: number;
  discountAuto: number;
  discountPrice: number;
  flatOffer: IFlatOffer;
  price: number;
  qty: number;
  subTotal: number;
  total: number;
}

export interface ITransitOrder {
  deliveryDate: string;
  deliveryTime: string;
  id: string;
  orderDate: string;
  orderId: string;
  orderDetails: IOrderDetails;
  totalSum: number;
  items?: ITransitOrderItem[];
}
