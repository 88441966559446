import { all } from 'redux-saga/effects';
import { accountRequestSaga } from './account/actions';
import { authRequestSaga } from './auth/actions';
import { filterOptionsRequestSaga } from './filter-options/actions';
import { catalogRequestSaga } from './catalog/actions';
import { cartRequestSaga } from './cart/actions';
import { orderRequestSaga } from './order/actions';
import { outletsRequestSaga } from './outlets/actions';
import { expiredCartRequestSaga } from './expired-cart/actions';
import { transactionsRequestSaga } from './transactions/actions';
import { checkoutRequestSaga } from './checkout/actions';
import { discountConditionsRequestSaga } from './discount-conditions/actions';
import { seoSettingsRequestSaga } from './seo/actions';
import { balanceHistoryRequestSaga } from './balance-history/actions';
import { discountByGroupRequestSaga } from './discount-by-group/actions';
import { mainRequestSaga } from './main/actions';
import { staticPagesRequestSaga } from './static-page/actions';

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
  yield all([
    accountRequestSaga(),
    authRequestSaga(),
    catalogRequestSaga(),
    cartRequestSaga(),
    checkoutRequestSaga(),
    orderRequestSaga(),
    filterOptionsRequestSaga(),
    outletsRequestSaga(),
    expiredCartRequestSaga(),
    transactionsRequestSaga(),
    discountConditionsRequestSaga(),
    balanceHistoryRequestSaga(),
    discountByGroupRequestSaga(),
    mainRequestSaga(),
    seoSettingsRequestSaga(),
    staticPagesRequestSaga()
  ]);
}
