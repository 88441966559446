import React, { ReactNode } from 'react';
import { Route } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, MapStateToProps } from 'react-redux';
import { useLocation } from 'react-router';

import { useTrackInitialPageLoad } from 'controllers';
import { GTM } from 'controllers';
import {
  Order,
  // ORDER_STATUS_APPROVED,
  ORDER_STATUS_PACKED,
  ORDER_STATUS_PENDING_APPROVAL,
  ORDER_STATUS_PREORDER_PENDING_APPROVAL,
  ORDER_STATUS_RESERVED,
  ORDER_TYPE_PREORDER
} from 'models';

import AccountOrdersTable from './AccountOrdersTable';
import AccountOrder from './AccountOrder';
import { baseUrl } from 'shared/constants';
import { IApplicationState } from 'store/reducers';
import { fetchOrderListAsync } from 'store/order/actions';
import { getOrdersLoaded, getOrdersLoadingState, getOrdersList } from 'store/order/selectors';
import { OrderRepository } from 'store/order/request';

const httpClient = new OrderRepository();

interface IProps {
  // component own props
  setRightPanel?: (arg: () => ReactNode) => void;
}

interface IStateProps {
  // Props passed to the component by `connect`
  ordersLoadingState: boolean;
  ordersLoaded: boolean;
  orders: Order[];
}

interface IDispatchProps {
  // Dispatch props passed to the component by `connect`
  loadOrders: typeof fetchOrderListAsync.request;
}

type IComponentProps = IProps & IStateProps & IDispatchProps;

const AccountOrders: React.FC<IComponentProps> = ({
  setRightPanel,
  ordersLoadingState,
  ordersLoaded,
  loadOrders,
  orders
}) => {
  useTrackInitialPageLoad();

  const location = useLocation<any>();

  React.useEffect(() => {
    if (!ordersLoaded && !ordersLoadingState) {
      loadOrders();
    }
  }, [ordersLoadingState, ordersLoaded, loadOrders]);

  React.useEffect(() => {
    const isPreOrderTab = location.pathname.includes('pre-orders');
    const isDeliveries = location.pathname.includes('deliveries');
    if (ordersLoaded && (isPreOrderTab || isDeliveries)) {
      const deliveriesOrderStatuses = [ORDER_STATUS_PENDING_APPROVAL, ORDER_STATUS_RESERVED, ORDER_STATUS_PACKED];
      const filterFn = isPreOrderTab
        ? (order: Order) =>
            order.orderType === ORDER_TYPE_PREORDER && order.orderStatus === ORDER_STATUS_PREORDER_PENDING_APPROVAL
        : (order: Order) => deliveriesOrderStatuses.includes(order.orderStatus);
      const filteredOrders = orders ? orders.filter(filterFn) : orders;

      try {
        if (filteredOrders && filteredOrders.length) {
          httpClient.get(filteredOrders[filteredOrders.length - 1].orderId).then(({ data }: any) => {
            if (data) {
              GTM.trackPurchasePage(data);
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
    // eslint-disable-next-line
  }, [ordersLoaded]);

  return (
    <>
      <Route
        exact
        path={`${baseUrl}/account/orders/:filter?`}
        render={props => <AccountOrdersTable {...props} setRightPanel={setRightPanel} />}
      />

      <Route
        exact
        path={`${baseUrl}/account/orders/:filter/:uuid`}
        render={props => <AccountOrder {...props} setRightPanel={setRightPanel} />}
      />
    </>
  );
};

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  ordersLoadingState: getOrdersLoadingState(state),
  ordersLoaded: getOrdersLoaded(state),
  orders: getOrdersList(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      loadOrders: fetchOrderListAsync.request
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountOrders);
