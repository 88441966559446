import React from "react";
import { FormattedMessage } from "react-intl";
import { withStyles } from "@mui/styles";
import { TextBody1, TextSubTitle } from "components/shared/text";

import styles from "../styles";
import messages from "translations/account/loyalty";

interface IProps {
  messageKey: string;
  classes: any;
}

const RuleText: React.FC<IProps> = ({ messageKey, classes }) => {
  return (
    <>
      <TextSubTitle className={classes.rulesSubtitle}>
        {messages[messageKey] && messages[messageKey].defaultMessage}
      </TextSubTitle>
      <TextBody1 color={"textSecondary"}>
        <FormattedMessage
          tagName={React.Fragment}
          values={{
            b: (...chunks: any[]) => <span className={classes.mediumBold}>{chunks}</span>
          }}
          {...messages[`${messageKey}Text`]}
        />
      </TextBody1>
    </>
  );
};

export default withStyles<any>(styles)(RuleText);
