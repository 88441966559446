import { Product } from "./product";

export class DiscountProduct {
  public product: Product;
  public qty: number;
  public total: number;

  constructor(discountProduct: any) {
    this.product = new Product(discountProduct.product)
    this.qty = discountProduct.qty;
    this.total = discountProduct.total;
  }
}
