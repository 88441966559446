import { ITheme } from 'utils/styled';

const styles = (theme: ITheme) => ({
  root: {
    userSelect: 'none',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    '& .RSPBprogressBar': {
      height: theme.spacing(0.5),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      background: theme.palette.primary.light,

      [(theme as any).breakpoints.up('xs')]: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
      }
    },
    '& .RSPBprogression': {
      height: theme.spacing(0.5),
      background: theme.palette.primary.main
    },
    [(theme as any).breakpoints.up('xs')]: {
      overflowX: 'scroll',
      margin: 0,
      padding: '0 20px'
    },

    scrollbarWidth: 'thin',
    scrollbarColor: 'rgba(0,0,0,0.25) transparent',

    '&::-webkit-scrollbar': {
      width: 4,
      height: 4
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: 10,
      backgroundColor: 'transparent'
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: 2,
      backgroundColor: 'rgba(0,0,0,0.15)'
    }
  },
  step: {
    position: 'absolute',
    '& .text': {
      position: 'absolute',
      padding: theme.spacing(1),
      left: '50%',
      whiteSpace: 'nowrap'
    },
    '& .label': {
      bottom: 0,
      opacity: 0.8,
      transform: 'translateY(100%) translateX(-50%)'
    },
    '& .value': {
      top: 0,
      opacity: 0.8,
      transform: 'translateY(-100%) translateX(-50%)'
    },
    '& .point': {
      width: 16,
      height: 16,
      borderRadius: 8,
      background: theme.palette.primary.light
    }
  },
  active: {
    '& .label': {
      color: theme.palette.text.primary,
      opacity: 1
    },
    '& .value': {
      ...theme.typography.h1,
      paddingBottom: theme.spacing(0.75),
      marginTop: 0,
      marginBottom: 0,
      color: theme.palette.text.primary,
      opacity: 1
    },
    '& .point': {
      width: 20,
      height: 20,
      borderRadius: 10,
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)'
    }
  },
  accomplished: {
    '& .point': {
      background: theme.palette.primary.main
    }
  }
});

export default styles;
