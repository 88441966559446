import { defineMessages } from "react-intl";
import { PERSONAL_DISCOUNT, PRODUCT_GROUP_DISCOUNT } from "models/user-profile";

export default defineMessages({
  title: {
    id: "account.loyalty.title",
    description: "account.loyalty.title",
    defaultMessage: "Програма лояльності"
  },
  titlePromotions: {
    id: "account.loyalty.titlePromotions",
    description: "account.loyalty.titlePromotions",
    defaultMessage: "Акції"
  },
  titleGroups: {
    id: "account.loyalty.titleGroups",
    description: "account.loyalty.titleGroups",
    defaultMessage: "Групи товарів зі знижкою"
  },
  subTitleGroups: {
    id: "account.loyalty.subTitleGroups",
    description: "account.loyalty.subTitleGroups",
    defaultMessage: "Ваші знижки на групи товарів з 1 по {lastDayOfMonth}"
  },
  groupsDescriptionP1: {
    id: "account.loyalty.groupsDescriptionP1",
    description: "account.loyalty.groupsDescriptionP1",
    defaultMessage: "Кількість куплених товарів з групи накопичується з 1 по останнє число поточного місяця"
  },
  groupsDescriptionP2: {
    id: "account.loyalty.groupsDescriptionP2",
    description: "account.loyalty.groupsDescriptionP2",
    defaultMessage: "Від кількості куплених товарів залежатиме відсоток знижки у наступному місяці"
  },
  groupsDescriptionP3: {
    id: "account.loyalty.groupsDescriptionP3",
    description: "account.loyalty.groupsDescriptionP3",
    defaultMessage: "Чим більше товарів з групи куплено, тим більша знижка! Знижки на один товар сумуються"
  },
  titleMobile: {
    id: "account.loyalty.titleMobile",
    description: "account.loyalty.titleMobile",
    defaultMessage: "Програми лояльності"
  },
  titleBalance: {
    id: "account.loyalty.titleBalance",
    description: "account.loyalty.titleBalance",
    defaultMessage: "Депозит на балансі"
  },
  nextDiscountUpdate: {
    id: "account.loyalty.nextDiscountUpdate",
    description: "account.loyalty.nextDiscountUpdate",
    defaultMessage: "{date} о {time} оновлення знижки"
  },
  rules: {
    id: "account.loyalty.rules",
    description: "account.loyalty.rules",
    defaultMessage: "Правила"
  },
  readRules: {
    id: "account.loyalty.readRules",
    description: "account.loyalty.readRules",
    defaultMessage: "Читати правила"
  },
  readRulesMobile: {
    id: "account.loyalty.readRulesMobile",
    description: "account.loyalty.readRulesMobile",
    defaultMessage: "Детальніше"
  },
  currentDiscountLevel: {
    id: "account.loyalty.currentDiscountLevel",
    description: "account.loyalty.currentDiscountLevel",
    defaultMessage: "Поточний рівень знижки"
  },
  currentDiscountDate: {
    id: "account.loyalty.currentDiscountDate",
    description: "account.loyalty.currentDiscountDate",
    defaultMessage: "з 1 по {lastDay}"
  },
  currentDiscountDateRange: {
    id: "account.loyalty.currentDiscountDateRange",
    description: "account.loyalty.currentDiscountDateRange",
    defaultMessage: "з {startDate} по {endDate}"
  },
  discountByBalanceMobile: {
    id: "account.loyalty.discountByBalanceMobile",
    description: "account.loyalty.discountByBalanceMobile",
    defaultMessage: "Знижки на всі товари"
  },
  discountByGroupsMobile: {
    id: "account.loyalty.discountByGroupsMobile",
    description: "account.loyalty.discountByGroupsMobile",
    defaultMessage: "Знижки на групи товарів"
  },
  discountProgress: {
    id: "account.loyalty.discountProgress",
    description: "account.loyalty.discountProgress",
    defaultMessage: "Прогрес знижки"
  },
  discountProgressNext: {
    id: "account.loyalty.discountProgressNext",
    description: "account.loyalty.discountProgressNext",
    defaultMessage: "Знижка наступного місяця"
  },
  discountProgressWeeks: {
    id: "account.loyalty.discountProgressWeeks",
    description: "account.loyalty.discountProgressWeeks",
    defaultMessage: "тиж."
  },
  discountNextLevel: {
    id: "account.loyalty.discountNextLevel",
    description: "account.loyalty.discountNextLevel",
    defaultMessage: "Наступний рівень"
  },
  discountMaxLevel: {
    id: "account.loyalty.discountMaxLevel",
    description: "account.loyalty.discountMaxLevel",
    defaultMessage: "Максимальний рівень"
  },
  discountTillNextLevel: {
    id: "account.loyalty.discountTillNextLevel",
    description: "account.loyalty.discountTillNextLevel",
    defaultMessage: "До наступного рівня"
  },
  discountProgressAmount: {
    id: "account.loyalty.discountProgressAmount",
    description: "account.loyalty.discountProgressAmount",
    defaultMessage: "Куплено товарів з групи з 1 по {lastDay}"
  },
  discountNextLevelProgress: {
    id: "account.loyalty.discountNextLevelProgress",
    description: "account.loyalty.discountNextLevelProgress",
    defaultMessage: "Для знижки у {percent}% до {lastDay} викупіть ще"
  },
  discountLevels: {
    id: "account.loyalty.personalConditionDiscount.levels",
    description: "account.loyalty.personalConditionDiscount.levels",
    defaultMessage: "Рівні знижки на наступний місяць"
  },
  discountLevelsDesc: {
    id: "account.loyalty.discountLevelsDesc.levels",
    description: "account.loyalty.discountLevelsDesc.levels",
    defaultMessage: "В залежності від кількості куплених товарів у поточному"
  },
  gettingDiscount: {
    id: "account.loyalty.gettingDiscount",
    description: "account.loyalty.gettingDiscount",
    defaultMessage: "Отримання знижки"
  },
  gettingDiscountText: {
    id: "account.loyalty.gettingDiscountText",
    description: "account.loyalty.gettingDiscountText",
    defaultMessage:
      "Якщо <b>на початок тижня (00:01 понеділка)</b> депозит на балансі відповідає приведеним вижче умовам, ви отримаєте відповідну знижку на всі товари Флоротеки впродовж поточного тижня."
  },
  losingDiscount: {
    id: "account.loyalty.losingDiscount",
    description: "account.loyalty.losingDiscount",
    defaultMessage: "Втрата знижки"
  },
  losingDiscountText: {
    id: "account.loyalty.losingDiscountText",
    description: "account.loyalty.losingDiscountText",
    defaultMessage:
      "Якщо на початок тижня (00:01 понеділка) баланс <b>НЕ відповідає</b> приведеним вище умовам, ваша знижка переходить на попередній рівень, а для її підвищення пітрібно повторно виконати відповідні умови."
  },
  increasingDiscount: {
    id: "account.loyalty.increasingDiscount",
    description: "account.loyalty.increasingDiscount",
    defaultMessage: "Збільшення знижки"
  },
  increasingDiscountText: {
    id: "account.loyalty.increasingDiscountText",
    description: "account.loyalty.increasingDiscountText",
    defaultMessage:
      "Кількість куплених товарів з групи накопичується з 1 по останнє число поточного місяця. Від кількості куплених товарів залежатиме відсоток знижки у наступному місяці. Чим більше товарів з групи куплено, тим більша знижка!"
  },
  decreasingDiscount: {
    id: "account.loyalty.decreasingDiscount",
    description: "account.loyalty.decreasingDiscount",
    defaultMessage: "Зменшення знижки"
  },
  decreasingDiscountText: {
    id: "account.loyalty.decreasingDiscountText",
    description: "account.loyalty.decreasingDiscountText",
    defaultMessage:
      "Кількість повернутих товарів віднімається від накопиченої кількості та може призвести до зниження знижки."
  },
  [`${PERSONAL_DISCOUNT}ConditionDiscount1`]: {
    id: "account.loyalty.personalConditionDiscount.1",
    description: "account.loyalty.personalConditionDiscount.1",
    defaultMessage: "від <boldprice>{min}</boldprice> до <price>{max}</price>"
  },
  [`${PERSONAL_DISCOUNT}ConditionDiscount2`]: {
    id: "account.loyalty.personalConditionDiscount.2",
    description: "account.loyalty.personalConditionDiscount.2",
    defaultMessage: `від <boldprice>{max}</boldprice><d>\n</d>або впродовж <b>півроку</b> (26 тиж.) від <boldprice>{min}</boldprice> до <price>{max}</price>`
  },
  [`${PERSONAL_DISCOUNT}ConditionDiscount3`]: {
    id: "account.loyalty.personalConditionDiscount.3",
    description: "account.loyalty.personalConditionDiscount.3",
    defaultMessage: "впродовж <b>півроку</b> (26 тиж.) від <boldprice>{min}</boldprice>"
  },
  [`${PERSONAL_DISCOUNT}ConditionDiscount4`]: {
    id: "account.loyalty.personalConditionDiscount.4",
    description: "account.loyalty.personalConditionDiscount.4",
    defaultMessage: "впродовж <b>року</b> (52 тиж.) від <boldprice>{min}</boldprice>"
  },
  productsWereBuy: {
    id: "account.loyalty.productsWereBuy",
    description: "account.loyalty.productsWereBuy",
    defaultMessage: "<b>від {min}<if> до {max} шт</if></b>"
  },
  balanceHistory1: {
    id: "account.loyalty.balanceHistory.1",
    description: "account.loyalty.balanceHistory.1",
    defaultMessage: "Менше {max}"
  },
  balanceHistory2: {
    id: "account.loyalty.balanceHistory.2",
    description: "account.loyalty.balanceHistory.2",
    defaultMessage: "до {max} тис"
  },
  balanceHistory3: {
    id: "account.loyalty.balanceHistory.3",
    description: "account.loyalty.balanceHistory.3",
    defaultMessage: "{min}-{max} тис"
  },
  balanceHistory4: {
    id: "account.loyalty.balanceHistory.4",
    description: "account.loyalty.balanceHistory.4",
    defaultMessage: "Більше {min}"
  },
  [`${PRODUCT_GROUP_DISCOUNT}ConditionDiscountBasic`]: {
    id: "account.loyalty.productGroupConditionDiscountBasic",
    description: "account.loyalty.productGroupConditionDiscountBasic",
    defaultMessage: "базова знижка"
  },
  [`${PRODUCT_GROUP_DISCOUNT}ConditionDiscountAfter`]: {
    id: "account.loyalty.productGroupConditionDiscountAfter",
    description: "account.loyalty.productGroupConditionDiscountAfter",
    defaultMessage: `після витрачених <boldprice>{min}</boldprice>`
  },
  buyWithDiscount: {
    id: "account.loyalty.buyWithDiscount",
    description: "account.loyalty.buyWithDiscount",
    defaultMessage: "Купити товари зі знижкою"
  },
  discountForWeeks: {
    id: "account.loyalty.discountForWeeks",
    description: "account.loyalty.discountForWeeks",
    defaultMessage: `Отримано за {weeks} {weeksKey, plural,
        one {тиждень}
        =2 {тижні}
        =3 {тижні}
        =4 {тижні}
        other {тижнів}
    }`
  },
  discountForWeeksPast: {
    id: "account.loyalty.discountForWeeksPast",
    description: "account.loyalty.discountForWeeksPast",
    defaultMessage: `{weeks} {weeksKey, plural,
        one {тиждень}
        =2 {тижні}
        =3 {тижні}
        =4 {тижні}
        other {тижнів}
    } пройшло`
  },
  discountForWeeksRequired: {
    id: "account.loyalty.discountForWeeksRequired",
    description: "account.loyalty.discountForWeeksRequired",
    defaultMessage: `{weeks} {weeksKey, plural,
        one {тиждень}
        =2 {тижні}
        =3 {тижні}
        =4 {тижні}
        other {тижнів}
    } до наступної знижки`
  },
  discountLabel: {
    id: "account.loyalty.discountLabel",
    description: "account.loyalty.discountLabel",
    defaultMessage: "Знижка"
  },
  productGroupCurrentDiscountTitle: {
    id: "account.loyalty.productGroupCurrentDiscountTitle",
    description: "account.loyalty.productGroupCurrentDiscountTitle",
    defaultMessage: "Витрачено на товари групи"
  },
  productGroupCurrentDiscountText: {
    id: "account.loyalty.productGroupCurrentDiscountText",
    description: "account.loyalty.productGroupCurrentDiscountText",
    defaultMessage: "Витрачена сума накопичується за весь період"
  },
  productGroupCurrentDiscountSpent: {
    id: "account.loyalty.productGroupCurrentDiscountSpent",
    description: "account.loyalty.productGroupCurrentDiscountSpent",
    defaultMessage: "Витрачено {amount} {currency}"
  },
  productGroupCurrentDiscountRequired: {
    id: "account.loyalty.productGroupCurrentDiscountRequired",
    description: "account.loyalty.productGroupCurrentDiscountRequired",
    defaultMessage: "До наступного рівня купіть ще на {amount} {currency}"
  },
  productGroupTitle: {
    id: "account.loyalty.productGroupTitle",
    description: "account.loyalty.productGroupTitle",
    defaultMessage: "Група товарів зі знижкою"
  },
  categoriesList: {
    id: "account.loyalty.categoriesList",
    description: "account.loyalty.categoriesList",
    defaultMessage: "Категорії товарів в групі “{group}”"
  },
  productGroupTransactions: {
    id: "account.loyalty.productGroupTransactions",
    description: "account.loyalty.productGroupTransactions",
    defaultMessage: "Транзакції по групі товарів"
  },
  productsWithDiscountTitle: {
    id: "account.loyalty.productsWithDiscountTitle",
    description: "account.loyalty.productsWithDiscountTitle",
    defaultMessage: "Товари в групі “{group}” ({amount} шт)"
  },
  productsWithDiscountText: {
    id: "account.loyalty.productsWithDiscountText",
    description: "account.loyalty.productsWithDiscountText",
    defaultMessage: "Чим більше ви придбаєте цих товарів, тим більшою буде знижка на всю групу в наступному календарному місяці"
  },
  spent: {
    id: "account.loyalty.spent",
    description: "account.loyalty.spent",
    defaultMessage: "Витрачено, {currency}"
  },
  order: {
    id: "account.loyalty.order",
    description: "account.loyalty.order",
    defaultMessage: "Замовлення"
  },
  sum: {
    id: "account.loyalty.sum",
    description: "account.loyalty.sum",
    defaultMessage: "Сума, {currency}"
  },
  type: {
    id: "account.loyalty.type",
    description: "account.loyalty.type",
    defaultMessage: "Тип"
  },
  qty: {
    id: "account.loyalty.qty",
    description: "account.loyalty.qty",
    defaultMessage: "Куплено в поточному місяці, шт"
  },
  orderReturn: {
    id: "account.loyalty.orderReturn",
    description: "account.loyalty.orderReturn",
    defaultMessage: "Повернення товару"
  },
  tooltipTitle: {
    id: "account.loyalty.tooltipTitle",
    description: "account.loyalty.tooltipTitle",
    defaultMessage: "історія знижок"
  },
  tooltipTitlePeriod: {
    id: "account.loyalty.tooltipTitlePeriod",
    description: "account.loyalty.tooltipTitlePeriod",
    defaultMessage: "За останні 6 місяців"
  },
});
