import React, { FC } from 'react';

import { TextBody2, TextSubTitle } from 'components/shared/text';
import { OrderItem } from 'models';
import { getProductImage } from 'utils/helpers';

import ProductDetails from '../../shared/ProductDetails';

import classes from './OrderCardItemLabel.module.scss';

interface IProps {
  selected?: boolean;
  item: OrderItem;
  onSelect: () => void;
  onUnselect: () => void;
}

const OrderCardItemLabelDevice: FC<IProps> = ({ selected, item, onSelect, onUnselect }) => {
  const handleSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (selected && onUnselect) {
      return onUnselect();
    }
    if (onSelect) {
      onSelect();
    }
  };

  return (
    <div onClick={handleSelect} className={classes.container}>
      <img src={getProductImage(item.product)} alt={item.product.displayName} className={classes.productImage} />
      <div className={classes.content}>
        <TextSubTitle className={classes.displayName}>{item.product.displayName}</TextSubTitle>
        <div className={classes.productDetailsWrapper}>
          <TextBody2 className={classes.qtyLabel}>{item.qty}&nbsp;шт</TextBody2>
          <ProductDetails product={item.product} attrsOnly />
        </div>
      </div>
    </div>
  );
};

export default OrderCardItemLabelDevice;
