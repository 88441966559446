import React from 'react';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import clsx from 'clsx';
import styled from '@emotion/styled/macro';
import { Button, IconButton } from '@mui/material';

import { AsideDeviceFooter, AsideDeviceHeader } from 'components/layout/aside-device';
import Icon from 'components/shared/Icon';
import { Cart, CartItem, IMiniCart, IRequestCartItem, Product, User } from 'models';
import MiniDetails from 'pages/catalog/layout/right-bar/MiniDetails';
import { baseUrl } from 'shared/constants';
import { getAccountLoadingState, getUserAccount } from 'store/account/selectors';
import { addCartItemAsync, addManyCartItemsAsync, openMiniCart } from 'store/cart/actions';
import { getCart, getCartLoadingState, getMiniCart, getMiniCartMultiUpdateLoading } from 'store/cart/selectors';
import { ICatalogData, selectProductAsync, unselectProduct, watchProductAsync } from 'store/catalog/actions';
import {
  getCatalogData,
  getCatalogSortedState,
  getSelectedProductData,
  getSelectedProductLoadingState
} from 'store/catalog/selectors';
import { IApplicationState } from 'store/reducers';
import cartMessages from 'translations/cart/common';
import miniCartMessages from 'translations/cart/mini-cart';
import { IThemed } from 'utils/styled';

import MiniCart from './MiniCart';

import classes from './RightBar.module.scss';

interface IOwnProps {
  // put own props here
  onClose: () => void;
}

interface IState {
  purchasing: boolean;
}

interface IStateProps {
  cart: Cart;
  miniCart: IMiniCart;
  selectedProduct: Product | null;
  user: User | null;
  userLoading: boolean;
  catalog: ICatalogData;
  catalogSorted: boolean;
  isLoading: boolean;
  isLoadingSaving: boolean;
  selectedProductLoading: boolean;
}

interface IDispatchProps {
  addToCart: typeof addCartItemAsync.request;
  addManyToCart: typeof addManyCartItemsAsync.request;
  selectProduct: typeof selectProductAsync.request;
  deselectProduct: typeof unselectProduct;
  openMiniCart: typeof openMiniCart;
  watchProduct: typeof watchProductAsync.request;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const StyledIcon = styled<any>(Icon)(({ theme }: IThemed) => ({
  '&.selected': {
    color: theme.palette.error.main
  }
}));

class UnconnecterRightBar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handlePurchase = this.handlePurchase.bind(this);
    this.handlePurchaseAll = this.handlePurchaseAll.bind(this);
    this.handleSelectFirstProduct = this.handleSelectFirstProduct.bind(this);
  }

  public componentDidUpdate(): void {
    this.handleSelectFirstProduct();
  }

  public handlePurchase(qty: number, offer: string) {
    const { selectedProduct, addToCart, openMiniCart: openMini, deselectProduct } = this.props;
    if (selectedProduct) {
      const item = {
        qty,
        offer
      };
      addToCart(item);
      deselectProduct();
    }
    openMini();
  }

  public handleUpdate(items: CartItem[]) {
    const { selectedProduct, addManyToCart, deselectProduct } = this.props;
    addManyToCart(items.map((item) => ({ offer: item.offer.id, qty: item.qty }) as IRequestCartItem));
    if (selectedProduct) {
      deselectProduct();
    }
  }

  public handlePurchaseAll() {
    window.location.assign(`${baseUrl}/cart`);
  }

  /** Select first product for unauthorized user */
  public handleSelectFirstProduct() {
    const { selectedProduct, userLoading, user, catalog, catalogSorted, selectProduct } = this.props;

    const isNotAuthorizedUser = !userLoading && !user;

    if (!selectedProduct && catalog.rows && catalog.rows.length && isNotAuthorizedUser && catalogSorted) {
      selectProduct(catalog.rows[0][0]);
    }
  }

  public render() {
    const { cart, selectedProduct, selectedProductLoading, user, userLoading, onClose, watchProduct } = this.props;
    const selectedProductId = (selectedProduct && selectedProduct.id) || '';
    const isShowDetails = selectedProduct || selectedProductLoading || (!user && !userLoading);
    const isWatch = user && user.profile && selectedProductId && user.profile.watchList.includes(selectedProductId);
    const hasCartItems = cart && cart.items && cart.items.length > 0;

    return isShowDetails ? (
      <React.Fragment>
        <AsideDeviceHeader
          onClose={onClose}
          title={selectedProduct?.fullName || ''}
          fullWidth
          style={{ marginTop: 0 }}
          action={
            <IconButton onClick={() => watchProduct({ productId: selectedProductId, watch: !isWatch })} size="large">
              <StyledIcon
                type={isWatch ? 'heartFull' : 'heart'}
                size={24}
                className={clsx('favourite', { selected: isWatch })}
              />
            </IconButton>
          }
        />
        <MiniDetails onPurchase={this.handlePurchase} />
      </React.Fragment>
    ) : (
      user && (
        <React.Fragment>
          <AsideDeviceHeader
            onClose={onClose}
            title={miniCartMessages.productsInCart.defaultMessage}
            crossmarkClose
            fullWidth
            style={{ marginTop: 0 }}
          />
          <MiniCart
            onPurchaseClick={this.handlePurchaseAll}
            onEdit={this.handleUpdate}
            cart={cart}
            loading={this.props.isLoading || this.props.isLoadingSaving}
          />
          <AsideDeviceFooter
            fullWidth
            action={
              <Button
                className={classes.continueShoppingBtn}
                fullWidth={!hasCartItems}
                variant={'contained'}
                color={'primary'}
                onClick={onClose}
              >
                {cartMessages.continueShopping.defaultMessage}
              </Button>
            }
            info={
              hasCartItems && (
                <Button
                  variant={'text'}
                  color={'secondary'}
                  onClick={this.handlePurchaseAll}
                  className={classes.buyBtn}
                >
                  {cartMessages.buy.defaultMessage}
                </Button>
              )
            }
          />
        </React.Fragment>
      )
    );
  }
}

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IApplicationState> = (state: IApplicationState) => ({
  cart: getCart(state),
  selectedProduct: getSelectedProductData(state),
  miniCart: getMiniCart(state),
  user: getUserAccount(state),
  userLoading: getAccountLoadingState(state),
  catalog: getCatalogData(state),
  catalogSorted: getCatalogSortedState(state),
  isLoading: getCartLoadingState(state),
  isLoadingSaving: getMiniCartMultiUpdateLoading(state),
  selectedProductLoading: getSelectedProductLoadingState(state)
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      watchProduct: watchProductAsync.request,
      addToCart: addCartItemAsync.request,
      addManyToCart: addManyCartItemsAsync.request,
      selectProduct: (product: Product) => selectProductAsync.request(product),
      deselectProduct: unselectProduct,
      openMiniCart
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(UnconnecterRightBar);
