import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "account.order.title",
    description: "",
    defaultMessage: "Замовлення"
  },
  preOrderRemove: {
    id: "account.order.preOrderRemove",
    description: "",
    defaultMessage: "Скасувати"
  },
  preOrder: {
    id: "account.order.preOrder",
    description: "",
    defaultMessage: "Передзамовлення"
  },
  deliveries: {
    id: "account.order.deliveries",
    description: "",
    defaultMessage: "Доставки"
  },
  reviewPending: {
    id: "account.order.reviewPending",
    description: "",
    defaultMessage: "Очікують на відгук"
  },
  completed: {
    id: "account.order.completed",
    description: "",
    defaultMessage: "Завершені"
  },
  returns: {
    id: "account.order.return",
    description: "",
    defaultMessage: "Повернення"
  },
  tableOrder: {
    id: "account.order.table.order",
    description: "",
    defaultMessage: "Замовлення"
  },
  tableStatus: {
    id: "account.order.table.tableStatus",
    description: "",
    defaultMessage: "Статус"
  },
  tableStatusFrom: {
    id: "account.order.table.tableStatus",
    description: "",
    defaultMessage: "від"
  },
  tableDateShipment: {
    id: "account.order.table.tableDateShipment",
    description: "",
    defaultMessage: "Дата доставки"
  },
  tableDeliveryAddress: {
    id: "account.order.table.tableDeliveryAddress",
    description: "",
    defaultMessage: "Адреса доставки"
  },
  tableDeliveryType: {
    id: "account.order.table.tableDeliveryType",
    description: "",
    defaultMessage: "Спосіб доставки"
  },
  tableDeliveryCount: {
    id: "account.order.table.tableDeliveryCount",
    description: "",
    defaultMessage: "Кількість"
  },
  tableDeliveryAmount: {
    id: "account.order.table.tableDeliveryAmount",
    description: "",
    defaultMessage: "Сума"
  },
  reviewTitle: {
    id: "account.order.review.title",
    description: "",
    defaultMessage: "Відгук"
  },
  reviewAlertTitle: {
    id: "account.order.review.reviewAlertTitle",
    description: "",
    defaultMessage: "Очікує на відгук"
  },
  reviewAlertBody: {
    id: "account.order.review.reviewAlertTitle",
    description: "",
    defaultMessage: "Нам важлива Ваша думка"
  },
  reviewAlertLink: {
    id: "account.order.review.reviewAlertLink",
    description: "",
    defaultMessage: "Залишити відгук"
  },
  ratingQuality: {
    id: "account.order.review.ratingQuality",
    description: "",
    defaultMessage: "Якість товару"
  },
  ratingManager: {
    id: "account.order.review.ratingQuality",
    description: "",
    defaultMessage: "Робота менеджера"
  },
  ratingDelivery: {
    id: "account.order.review.ratingQuality",
    description: "",
    defaultMessage: "Доставка"
  },
  reviewCommentLabel: {
    id: "account.order.review.reviewCommentLabel",
    description: "",
    defaultMessage: "Коментар*"
  },
  reviewCommentPlaceholder: {
    id: "account.order.review.reviewCommentPlaceholder",
    description: "",
    defaultMessage: "Напишіть, ваші враження від доставки"
  },
  reviewSend: {
    id: "account.order.review.reviewSend",
    description: "",
    defaultMessage: "Надіслати"
  },
  reviewAddReclamation: {
    id: "account.order.review.reviewAddReclamation",
    description: "",
    defaultMessage: "Додати рекламацію"
  },
  reviewReclamationTitle: {
    id: "account.order.review.reviewReclamationTitle",
    description: "",
    defaultMessage: "Рекламація"
  },
  reviewReclamationLabel: {
    id: "account.order.review.reviewReclamationLabel",
    description: "",
    defaultMessage: "Опис проблеми"
  },
  reviewReclamationPlaceholder: {
    id: "account.order.review.reviewReclamationPlaceholder",
    description: "",
    defaultMessage: "Опишіть проблему. Наприклад: 15шт Бакарді привезли зламаних"
  },
  reviewReclamationAddPhoto: {
    id: "account.order.review.reviewReclamationCancel",
    description: "",
    defaultMessage: "Додати фото"
  },
  reviewReclamationPhotoLabel: {
    id: "account.order.review.reviewReclamationCancel",
    description: "",
    defaultMessage: "Фотографії, які підтверджують наявність проблеми*"
  },
  reviewReclamationPhotoHelper: {
    id: "account.order.review.reviewReclamationCancel",
    description: "",
    defaultMessage: "Формат файлів JPG, JPEG, PNG. Максимальний розмір файлу 10МВ."
  },
  reviewReclamationCancel: {
    id: "account.order.review.reviewReclamationAddPhoto",
    description: "",
    defaultMessage: "скасувати рекламацію"
  },
  reviewReclamationFilesRequired: {
    id: "account.order.review.reviewReclamationFilesRequired",
    description: "",
    defaultMessage: "Обов'язково додайте фотографії до рекалмації"
  },
  reviewReclamationFileWrongType: {
    id: "account.order.review.reviewReclamationFilesRequired",
    description: "",
    defaultMessage: "Невірний формат файлу"
  },
  reviewReclamationFileWrongSize: {
    id: "account.order.review.reviewReclamationFilesRequired",
    description: "",
    defaultMessage: "розмір файлу > 10МВ"
  },
  orderLabel: {
    id: "account.order.orderLabel",
    description: "",
    defaultMessage: "Параметри доставки"
  },
  orderCreated: {
    id: "account.order.orderCreated",
    description: "",
    defaultMessage: "Оформлено"
  },
  orderProduct: {
    id: "account.order.orderProduct",
    description: "",
    defaultMessage: "Товар"
  },
  orderAllToCart: {
    id: "account.order.orderAllToCart",
    description: "",
    defaultMessage: "Передзамовити всі "
  },
  orderRemoveConfirmation: {
    id: "account.order.orderAllToCart",
    description: "",
    defaultMessage: "Ви впевнені що хочете відмінити передазамовлення "
  },

  notificationReviewAdded: {
    id: "account.order.notificationReviewAdded",
    description: "",
    defaultMessage: "Відгук додано"
  },
  editOrder: {
    id: "account.order.editOrder",
    description: "",
    defaultMessage: "Редагувати"
  },
});
