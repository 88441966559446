import { Currency } from "./currency";

export enum TransactionOperation {
  Order = "Order",
  Payment = "Payment",
  Refund = "Refund",
  Reclamation = "Reclamation"
}

export enum TransactionOperationType {
  debit = "debit",
  credit = "credit"
}

export interface IPaymentData {
  name: string;
  amount: string;
  status: string;
  code: string;
  description: string;
  createdAt: string;
  currencyCode: string;
  alias: string;
  balance_1c: number;
  id: string;
  type: string;
  paymentId?: string;
  orderId?: string;
}

export interface ITransactionOperation {
  on: IPaymentData;
  onModel: TransactionOperation;
}

export function isReturnOrRefundTransaction(record: Transaction): boolean {
  const { operationType } = record;
  const onModel = record?.operation?.onModel;
  return (onModel === TransactionOperation.Order && operationType === "debit") || (onModel === TransactionOperation.Payment && operationType === "credit");
}

/*
*
* export const ORDER_STATUS_PREORDER = "000000000"; // "Предзаказ"
export const ORDER_STATUS_NEW = "CHECKOUT";
export const ORDER_STATUS_PENDING = "000000002"; // "Принят"
export const ORDER_STATUS_SHIPPED = "000000003"; // "Отгружен"
export const ORDER_STATUS_DONE = "000000004"; // "Выполнен"
export const ORDER_STATUS_CANCELED = "000000005"; // "Закрыт"

export type OrderStatus =
  | typeof ORDER_STATUS_PREORDER
  | typeof ORDER_STATUS_NEW
  | typeof ORDER_STATUS_PENDING
  | typeof ORDER_STATUS_SHIPPED
  | typeof ORDER_STATUS_DONE
  | typeof ORDER_STATUS_CANCELED;
*
* */

export interface ITransaction {
  id: string;
  createdAt: Date;
  operationType: TransactionOperationType; // operation_type // debit credit
  operation: ITransactionOperation;
  details: string;
  amount: number;
  amountBase: number; // amount_base
  currency: Currency;
  currencyBase: Currency; // currency_base
  currencyRate: number; // currency_rate
  balance: number;
  isReturn?: boolean;
  transactionOperation?: TransactionOperation;
}

export class Transaction implements ITransaction {
  public id: string;
  public createdAt: Date;
  public operationType: TransactionOperationType; // operation_type // debit credit
  public operation: ITransactionOperation; // ['Order'], // 'Payment', 'Return'
  public details: string;
  public amount: number;
  public amountBase: number; // amount_base
  public currency: Currency;
  public currencyBase: Currency; // currency_base
  public currencyRate: number; // currency_rate
  public balance: number;
  public isReturn = false;
  public transactionOperation: TransactionOperation | undefined;

  constructor(transaction: any) {
    this.id = transaction.id;
    this.createdAt = this._getCreatedDate(transaction);
    this.operationType = transaction.operationType;
    this.operation = transaction.operation;
    this.details = transaction.details;
    this.amount = transaction.amount;
    this.amountBase = transaction.amountBase;
    this.currency = transaction.currency;
    this.currencyBase = transaction.currencyBase;
    this.currencyRate = transaction.currencyRate;
    this.balance = transaction.balance;
    this.isReturn = Boolean(transaction.isReturn);
    this.transactionOperation = transaction?.operationType;
  }

  private _getCreatedDate(data: any): Date {
    if (data.operation && data.operation.date) {
      return data.operation.date;
    }
    if (data.operation && data.operation.date && data.operation.on.createdAt) {
      return data.operation.on.createdAt;
    }
    return data.createdAt;
  }

  get dateString() {
    return new Date(this.createdAt).toLocaleDateString('ru');
  }
}
