import { createReducer, PayloadAction, createAsyncAction } from "typesafe-actions";
import {
  loadedDataWrapper,
  REQUEST_ACTIONS,
  IAsyncDataWrapper,
  loadingDataWrapper,
  errorDataWrapper
} from "store/actions";
import { call, put, takeEvery } from "redux-saga/effects";
import { DiscountByGroupRepository } from "./request";
import { DiscountProduct, IDiscountCondition, IDiscountHistory, IDiscountData } from "models";

const httpClient = new DiscountByGroupRepository();

export const prefix = "@@discountByGroup/";

export interface IDiscountOrder {
  orderId: string;
  orderType: "order" | "refund";
  total: number;
  groupBalance: number;
}

export interface IDiscountByGroup {
  discountRule: IDiscountCondition;
  history: IDiscountHistory[];
  orders: Record<string, IDiscountOrder>;
  transactions: Record<string, DiscountProduct>;
  currentDiscount: IDiscountData;
  nextDiscountProgress: IDiscountData;
  progressValue?: number;
}

export const DISCOUNT_BY_GROUP_REQUEST = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const DISCOUNT_BY_GROUP_REQUEST_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const DISCOUNT_BY_GROUP_REQUEST_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

interface IDiscountByGroupStateSync {
  discountByGroup: IDiscountByGroup | null;
}

export type IDiscountByGroupState = IAsyncDataWrapper<IDiscountByGroupStateSync>;

export const discountByGroupInitialState: IDiscountByGroupState = {
  loading: false,
  loaded: false,
  data: {
    discountByGroup: null
  },
  error: null
};

type DiscountByGroupActionTypes =
  | typeof DISCOUNT_BY_GROUP_REQUEST
  | typeof DISCOUNT_BY_GROUP_REQUEST_SUCCESS
  | typeof DISCOUNT_BY_GROUP_REQUEST_FAILURE;

export const fetchDiscountByGroupAsync = createAsyncAction(
  DISCOUNT_BY_GROUP_REQUEST,
  DISCOUNT_BY_GROUP_REQUEST_SUCCESS,
  DISCOUNT_BY_GROUP_REQUEST_FAILURE
)<string, IDiscountByGroup, Error>();

function* discountByGroupSaga(action: ReturnType<typeof fetchDiscountByGroupAsync.request>): Generator {
  try {
    const response: any = yield call(() => httpClient.fetch(action.payload));
    const discountByGroup: IDiscountByGroup = response.data;
    const groupTransactionKeys = Object.keys(discountByGroup.transactions);
    groupTransactionKeys.forEach(key => {
      discountByGroup.transactions[key] = new DiscountProduct(discountByGroup.transactions[key]);
    });
    yield put(fetchDiscountByGroupAsync.success(discountByGroup));
  } catch (err) {
    yield put(fetchDiscountByGroupAsync.failure(err as Error));
  }
}

export function* discountByGroupRequestSaga() {
  yield takeEvery(fetchDiscountByGroupAsync.request, discountByGroupSaga);
}

export default createReducer(discountByGroupInitialState)
  .handleAction(fetchDiscountByGroupAsync.request, (state: IDiscountByGroupState) => {
    return loadingDataWrapper(state.data);
  })
  .handleAction(
    fetchDiscountByGroupAsync.success,
    (state: IDiscountByGroupState, action: PayloadAction<DiscountByGroupActionTypes, IDiscountByGroup>) =>
      loadedDataWrapper({
        ...state.data,
        discountByGroup: action.payload
      })
  )
  .handleAction(fetchDiscountByGroupAsync.failure, (state: IDiscountByGroupState) =>
    errorDataWrapper({ ...state.data, discountByGroup: null }, new Error("Failed to load discountByGroup"))
  );
