import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { connect, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import clsx from 'clsx';
import env from 'environment';
import { useSnackbar } from 'notistack';
import { Button, Grid, IconButton, TextField } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { withStyles } from '@mui/styles';

import { AsideDeviceHeader } from 'components/layout/aside-device';
import SnackbarContent from 'components/layout/notistack-provider/SnackbarWithTitle';
import Alert from 'components/shared/Alert/Alert';
import FlrRating from 'components/shared/form-elements/FlrRating';
import Icon from 'components/shared/Icon';
import { TextBody1, TextBody2, TextHelper, TextSubTitle, TitleH1 } from 'components/shared/text';
import { GTM } from 'controllers';
import { OrderReaction } from 'models/orderReaction';
import { closeAccountDetails } from 'store/account/actions';
import { getOrderAsync, getOrderReactionAsync } from 'store/order/actions';
import { getOrderReaction, getOrdersLoadingState } from 'store/order/selectors';
import { IApplicationState } from 'store/reducers';
import messages from 'translations/account/order';
import messagesControls from 'translations/layout/controls';
import notificationsMessages from 'translations/layout/notifications';
import { HttpClient } from 'utils/http';

import AccountOrderReviewPanelSkeleton from './AccountOrderReviewPanelSkeleton';
import styles from './styles';

interface IFormData {
  comment: string;
  reclamation: string;
  ratingQuality: number;
  ratingManager: number;
  ratingDelivery: number;
}

interface IProps {
  // component own props
  uuid: string | undefined;
  id: string | null;
  feedbackReclamation: string | null;
  classes: any;
  getOrder: typeof getOrderAsync.request;
  accountCloseDetails: typeof closeAccountDetails;
}

interface IStateProps {
  // Props passed to the component by `connect`
  isLoading: boolean;
  orderReaction: OrderReaction | null;
}

interface IDispatchProps {
  // Dispatch props passed to the component by `connect`
  getReaction: typeof getOrderReactionAsync.request;
  setReaction: typeof getOrderReactionAsync.success;
}
type IComponentProps = IProps & IStateProps & IDispatchProps;

const MAX_FILE_SIZE = 10485760;
const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

const AccountOrderReviewPanel: React.FC<IComponentProps> = ({
  classes,
  id,
  uuid,
  feedbackReclamation,
  orderReaction,
  getReaction,
  setReaction,
  isLoading,
  getOrder,
  accountCloseDetails
}) => {
  // todo dev
  React.useEffect(() => {
    if (feedbackReclamation) {
      getReaction(feedbackReclamation);
    }
    return () => {
      setReaction(null);
    };
  }, [feedbackReclamation, getReaction, setReaction]);

  const [isSent, setIsSent] = React.useState<boolean>(false);
  const [isLoadingDev, setIsLoadingDev] = React.useState<boolean>(false);


  const [files, setFiles] = React.useState<any[]>([]);
  const { getRootProps, getInputProps, isDragActive, rejectedFiles } = useDropzone({
    accept: ACCEPTED_FILE_TYPES.join(', '),
    maxSize: MAX_FILE_SIZE,
    onDrop: (acceptedFiles) => {
      const filesPrepared: any[] =
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        ) || [];

      setFiles((prevState) => [...prevState, ...filesPrepared]);
    }
  });

  const handleRemovePhoto = (index: number) => () => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  const [reclamationShow, setReclamationShow] = React.useState(false);

  const handleToggleReclamation = () => {
    setReclamationShow(!reclamationShow);
    setValue('reclamation', '');
    if (reclamationShow) {
      setFiles([]);
    }
  };

  const { setValue, handleSubmit, errors, control, formState, reset } = useForm<IFormData>();

  React.useEffect(() => {
    if (orderReaction && orderReaction.id === feedbackReclamation) {
      reset({ ...orderReaction });
      setIsSent(true);
    }
  }, [orderReaction, feedbackReclamation, reset]);
  React.useEffect(() => {
    if (!feedbackReclamation && isSent) {
      reset();
      setIsSent(false);
    }
  }, [feedbackReclamation, isSent, reset]);

  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = handleSubmit((fields): any => {
    const { ratingQuality, ratingManager, ratingDelivery, reclamation, comment } = fields;
    if (reclamationShow && !files.length) {
      return;
    }

    if (ratingQuality && ratingManager && ratingDelivery && reclamation && comment && files.length) {
      GTM.trackOrderFeedback();
    }

    const data = new FormData();
    Object.keys(fields).forEach((key) => {
      data.append(key, fields[key]);
    });
    files.forEach((file) => {
      if (file) {
        data.append('images', file, file.name);
      }
    });
    const httpClient = new HttpClient(`${env.apiUrl}`);
    const token = window.localStorage.getItem('token') || '';
    const headers = { Authorization: token };
    const onUploadProgress = (progressEvent: any) => {
      // todo progress upload
      // const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
    };
    httpClient
      .post(`/account/order-reviews/${id}`, data, { headers, onUploadProgress })
      .then((res: any) => {
        const { data: dataRes } = res;
        const { images } = dataRes;

        enqueueSnackbar(<SnackbarContent>{messages.notificationReviewAdded.defaultMessage}</SnackbarContent>, {
          variant: 'success'
        });

        setReaction(
          new OrderReaction({
            ratingQuality,
            ratingManager,
            ratingDelivery,
            comment,
            reclamation: reclamation || '',
            images
          })
        );
        if (uuid) {
          getOrder(uuid);
        }
      })
      .catch((res) => {
        if (!res.ok) {
          if (res.parsedErrors) {
            // todo handle error
          }
        }
        enqueueSnackbar(
          <SnackbarContent title={notificationsMessages.error.defaultMessage}>
            {notificationsMessages.errorOccurred.defaultMessage}
          </SnackbarContent>,
          {
            variant: 'error'
          }
        );
      })
      .finally(() => {
        setIsLoadingDev(false);
      });
  });

  const thumbs = files.map((file, index) =>
    file.preview ? (
      <div className={classes.previewPhoto} key={file.name}>
        {!isSent && (
          <IconButton
            color={'secondary'}
            className={classes.previewPhotoImageRemove}
            onClick={handleRemovePhoto(index)}
            size="large"
          >
            <Icon type="trash" opacity="1" size={24} />
          </IconButton>
        )}

        <img className={classes.previewPhotoImage} src={file.preview} alt={''} />
      </div>
    ) : null
  );

  React.useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  if (
    isLoading ||
    !id ||
    (!orderReaction && feedbackReclamation) ||
    (orderReaction && orderReaction.id !== feedbackReclamation)
  ) {
    return <AccountOrderReviewPanelSkeleton />;
  }

  return (
    <>
      <AsideDeviceHeader onClose={() => accountCloseDetails()} title={messages.reviewTitle.defaultMessage} accountHeaderHide/>
      <div className={classes.reviewContainer}>
        {!isSent && (
          <Alert className={classes.reviewAlert} leftShift title={messages.reviewAlertTitle.defaultMessage}>
            <TextBody2>{messages.reviewAlertBody.defaultMessage}</TextBody2>
          </Alert>
        )}

        <TitleH1 className={classes.reviewTitle} align={'center'}>
          {messages.reviewTitle.defaultMessage}
        </TitleH1>

        <div className={classes.ratingsContainer}>
          <Grid className={classes.ratingsItem} container alignItems={'center'} justifyContent={'space-between'}>
            <Grid>
              <TextBody1 color={errors.ratingQuality ? 'error' : undefined} className={classes.ratingLabel}>
                {messages.ratingQuality.defaultMessage}*
              </TextBody1>
            </Grid>
            <Grid>
              <Controller
                disabled={isSent}
                defaultValue={(orderReaction && orderReaction.ratingQuality) || 0}
                rules={{
                  required: true,
                  min: 1
                }}
                onChange={(args: any) => {
                  setValue('ratingQuality', args[1]);
                  return args[1];
                }}
                // type={"number"}
                // min={1}
                max={5}
                as={FlrRating}
                name={'ratingQuality'}
                control={control}
              />
            </Grid>
          </Grid>

          <Grid className={classes.ratingsItem} container alignItems={'center'} justifyContent={'space-between'}>
            <Grid>
              <TextBody1 color={errors.ratingManager ? 'error' : undefined} className={classes.ratingLabel}>
                {messages.ratingManager.defaultMessage}*
              </TextBody1>
            </Grid>
            <Grid>
              <Controller
                disabled={isSent}
                defaultValue={(orderReaction && orderReaction.ratingManager) || 0}
                rules={{
                  required: true,
                  min: 1
                }}
                onChange={(args: any) => {
                  setValue('ratingManager', args[1]);
                  return args[1];
                }}
                // type={"number"}
                // min={1}
                max={5}
                as={FlrRating}
                name={'ratingManager'}
                control={control}
              />
            </Grid>
          </Grid>

          <Grid className={classes.ratingsItem} container alignItems={'center'} justifyContent={'space-between'}>
            <Grid>
              <TextBody1 color={Boolean(errors.ratingDelivery) ? 'error' : undefined} className={classes.ratingLabel}>
                {messages.ratingDelivery.defaultMessage}*
              </TextBody1>
            </Grid>
            <Grid>
              <Controller
                disabled={isSent}
                defaultValue={(orderReaction && orderReaction.ratingDelivery) || 0}
                rules={{
                  required: true,
                  min: 1
                }}
                onChange={(args: any) => {
                  setValue('ratingDelivery', args[1]);
                  return args[1];
                }}
                // type={"number"}
                // min={1}
                max={5}
                as={FlrRating}
                name={'ratingDelivery'}
                control={control}
              />
            </Grid>
          </Grid>
        </div>
        {!isSent ? (
          <form onSubmit={onSubmit}>
            <Controller
              disabled={isSent}
              error={Boolean(errors.comment)}
              helperText={errors.comment && errors.comment.message}
              rules={{
                required: messagesControls.requiredFiled.defaultMessage
              }}
              as={TextField}
              name="comment"
              control={control}
              defaultValue={''}
              className={classes.reviewCommentField}
              InputLabelProps={{ shrink: true }}
              placeholder={messages.reviewCommentPlaceholder.defaultMessage}
              fullWidth
              variant={'outlined'}
              multiline
              rows={3}
              // rowsMax={10}
              label={messages.reviewCommentLabel.defaultMessage}
            />

            <Collapse in={reclamationShow}>
              {reclamationShow && (
                <>
                  <TitleH1 align={'center'}>{messages.reviewReclamationTitle.defaultMessage}</TitleH1>
                  <Controller
                    disabled={isSent}
                    error={Boolean(errors.reclamation)}
                    helperText={errors.reclamation && errors.reclamation.message}
                    as={TextField}
                    rules={
                      reclamationShow
                        ? {
                            required: messagesControls.requiredFiled.defaultMessage
                          }
                        : {}
                    }
                    name="reclamation"
                    control={control}
                    defaultValue=""
                    className={classes.reviewReclamationField}
                    InputLabelProps={{ shrink: true }}
                    placeholder={messages.reviewReclamationPlaceholder.defaultMessage}
                    fullWidth
                    variant={'outlined'}
                    multiline
                    rows={3}
                    // rowsMax={10}
                    label={`${messages.reviewReclamationLabel.defaultMessage}*`}
                  />
                  <TextSubTitle className={classes.reviewPhotoLabel}>
                    {messages.reviewReclamationPhotoLabel.defaultMessage}
                  </TextSubTitle>
                  <TextHelper component={'div'} className={classes.reviewPhotoHelper}>
                    {messages.reviewReclamationPhotoHelper.defaultMessage}
                  </TextHelper>

                  {formState.isSubmitted && !files.length && (
                    <TextHelper color={'error'} component={'div'} className={classes.reviewPhotoHelper}>
                      {messages.reviewReclamationFilesRequired.defaultMessage}
                    </TextHelper>
                  )}

                  {Boolean(rejectedFiles && rejectedFiles.length) &&
                    rejectedFiles.map((file, index) => {
                      const isSize = file.size > MAX_FILE_SIZE;
                      const isFormat = ACCEPTED_FILE_TYPES.indexOf(file.type) < 0;
                      return (
                        <TextHelper
                          key={index}
                          color={'error'}
                          component={'div'}
                          className={classes.reviewPhotoHelperError}
                        >
                          <span className={classes.reviewPhotoHelperFileName}>{file.name}</span>
                          {isSize && ` ${messages.reviewReclamationFileWrongSize.defaultMessage}`}
                          {isFormat && ` ${messages.reviewReclamationFileWrongType.defaultMessage}`}
                        </TextHelper>
                      );
                    })}

                  <div className={classes.reviewPhotoDropZone}>
                    <aside className={classes.previewContainer}>
                      {!isSent && (
                        <div
                          className={clsx(classes.previewAddPhoto, {
                            [classes.previewAddPhotoActive]: isDragActive
                          })}
                          {...getRootProps({})}
                        >
                          <input {...getInputProps()} />

                          <Icon type={'camera'} size={32} />

                          <TextHelper className={classes.previewAddPhotoLabel}>
                            {messages.reviewReclamationAddPhoto.defaultMessage}
                          </TextHelper>
                        </div>
                      )}

                      {thumbs}
                    </aside>
                  </div>
                </>
              )}
            </Collapse>

            {!isSent && (
              <>
                <Button
                  disabled={isLoadingDev}
                  type={'submit'}
                  onClick={onSubmit}
                  className={classes.reviewSendButton}
                  fullWidth
                  variant={'contained'}
                  color={'primary'}
                >
                  {messages.reviewSend.defaultMessage}
                </Button>

                <Button
                  className={classes.reviewReclamationToggleButton}
                  fullWidth
                  color={'primary'}
                  onClick={handleToggleReclamation}
                >
                  {reclamationShow
                    ? messages.reviewReclamationCancel.defaultMessage
                    : messages.reviewAddReclamation.defaultMessage}
                </Button>
              </>
            )}
          </form>
        ) : (
          (orderReaction && (
            <>
              <TextBody2>{orderReaction.comment}</TextBody2>

              {orderReaction.reclamation && (
                <>
                  <TextBody1 className={classes.reclamationTextLabel}>
                    {messages.reviewReclamationLabel.defaultMessage}
                  </TextBody1>
                  <TextBody2 className={classes.reclamationText}>{orderReaction.reclamation}</TextBody2>

                  {orderReaction.images && (
                    <div className={classes.reviewPhotoDropZone}>
                      <aside className={classes.previewContainer}>
                        {orderReaction.images &&
                          orderReaction.images.map((file, index) => (
                            <div className={classes.previewPhoto} key={index}>
                              <img
                                className={classes.previewPhotoImage}
                                src={`${env.apiUrl}/static/feedback-images/${file}.jpg`}
                                alt={''}
                              />
                            </div>
                          ))}
                      </aside>
                    </div>
                  )}
                </>
              )}
            </>
          )) ||
          null
        )}
      </div>
    </>
  );
};

// export default withStyles<any>(styles)(AccountOrderReviewPanel);

const mapStateToProps: MapStateToProps<IStateProps, {}, IApplicationState> = (state: IApplicationState) => ({
  orderReaction: getOrderReaction(state),
  isLoading: getOrdersLoadingState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      accountCloseDetails: closeAccountDetails,
      getReaction: getOrderReactionAsync.request,
      setReaction: getOrderReactionAsync.success
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles<any>(styles)(AccountOrderReviewPanel as any));
