import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';

import BalanceCard, { DEFAULT_AMOUNT } from 'components/cards/shared/BalanceCard';
import PaymentModal from 'components/shared/modals/payment/PaymentModal';
import { TextSubTitle, TitleH1, TitleH2 } from 'components/shared/text';
import { PERSONAL_DISCOUNT } from 'models';
import { defaultLocale } from 'shared/constants';
import { getUserAccount } from 'store/account/selectors';
import { getDiscountConditions } from 'store/discount-conditions/selectors';
import messagesFinance from 'translations/account/finance';
import messages from 'translations/account/loyalty';

import { breadCrumbsContext } from '../breadCrumbsState';
import DashboardLoyalty from '../dashboard/DashboardLoyalty';
import { BalanceHistoryCard, PersonalDiscountCard } from './cards';
import PersonalDiscountProgressCard from './cards/PersonalDiscountProgressCard';
import { loyaltyBreadcrumb, loyaltyBreadcrumbMobile } from './index';
import DiscountRules from './rules/DiscountRules';

import classes from './BalanceDiscount.module.scss';

const BalanceDiscount: FC = () => {
  const theme = useTheme();
  const screenMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [modalPayOpen, setModalPayOpen] = useState(false);
  const account = useSelector(getUserAccount);
  const discountConditions = useSelector(getDiscountConditions);
  const breadcrumb = screenMobile ? loyaltyBreadcrumbMobile : loyaltyBreadcrumb;
  const { setCurrentUrl } = useContext(breadCrumbsContext);
  const updateBreadCrumbs = useCallback(
    (bc) => {
      setCurrentUrl([{ label: bc.label, path: bc.path }, { label: messages.titleBalance.defaultMessage }]);
    },
    [setCurrentUrl]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    updateBreadCrumbs(breadcrumb);

    return () => {
      setCurrentUrl([]);
    };
  }, [breadcrumb, setCurrentUrl, updateBreadCrumbs]);

  const handlePayCancel = () => {
    setModalPayOpen(false);
  };

  const handleOpenPayModal = () => {
    setModalPayOpen(true);
  };

  const creditUsed = account && account.profile.balance < 0 ? -1 * account.profile.balance : DEFAULT_AMOUNT;

  const nextUpdateDate = moment().locale(defaultLocale).startOf('isoWeek').add(1, 'week');

  const cards = (
    <>
      {discountConditions?.accountBalanceDiscount ? (
        <>
          <Grid item xs>
            <PersonalDiscountCard discount={discountConditions.accountBalanceDiscount} account={account} />
          </Grid>
          <Grid item xs>
            <PersonalDiscountProgressCard
              progressValue={discountConditions.accountBalanceDiscount.discountData.progressValue || 0}
            />
          </Grid>
        </>
      ) : null}
      <Grid item xs>
        <BalanceCard buttonVariant="outlined" />
      </Grid>
    </>
  );

  const lastDayOfMonth = moment().endOf('month').format('D MMMM YYYY');

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          {discountConditions?.permanentDiscounts && discountConditions?.permanentDiscounts.length ? (
            <>
              <TitleH1 className={classes.title}>{messages.titlePromotions.defaultMessage}.</TitleH1>
              <DashboardLoyalty
                discountConditions={discountConditions?.permanentDiscounts}
                hideTitle
                hideProfileDiscount
                smallCards
              />
            </>
          ) : null}
          <div>
            <TitleH1 className={classes.discountGroupsTitle}>
              <FormattedMessage {...messages.subTitleGroups} values={{ lastDayOfMonth }} />.
            </TitleH1>
            <TextSubTitle>{messages.groupsDescriptionP1.defaultMessage}.</TextSubTitle>
            <TextSubTitle>{messages.groupsDescriptionP2.defaultMessage}.</TextSubTitle>
            <TextSubTitle className={classes.discountGroupsDesc}>
              {messages.groupsDescriptionP3.defaultMessage}.
            </TextSubTitle>
          </div>
          <DashboardLoyalty
            discountConditions={discountConditions?.conditionDiscounts}
            hideTitle
            hideProfileDiscount
            smallCards
          />
        </div>
        <div className={classes.inner}>
          <div className={classes.wrapper}>
            <TitleH1 className={classes.header}>{messages.titleBalance.defaultMessage}</TitleH1>
            <TextSubTitle>
              <FormattedMessage
                {...messages.nextDiscountUpdate}
                values={{
                  date: nextUpdateDate.format('DD MMMM'),
                  time: '00:01'
                }}
              />
            </TextSubTitle>
          </div>
          <Button color="primary" variant="contained" onClick={handleOpenPayModal} className={classes.btn}>
            {messagesFinance.topUpBalance.defaultMessage}
          </Button>
        </div>
        <div className={classes.balanceHistoryWrapper}>
          <div className={classes.balanceHistoryGrid}>
            <div className={classes.balanceHistoryCards}>{cards}</div>
            <div className={classes.balanceHistoryCard}>
              <BalanceHistoryCard mobile={screenMobile} />
            </div>
          </div>
        </div>
        <div className={classes.discountRules}>
          <TitleH2>{messages.rules.defaultMessage}</TitleH2>
          <DiscountRules type={PERSONAL_DISCOUNT} classNameWrapper={classes.discountRule} />
          <div>{/* <DiscountLevels type={PERSONAL_DISCOUNT} /> */}</div>
        </div>
      </div>

      {modalPayOpen && <PaymentModal open={modalPayOpen} handleClose={handlePayCancel} amount={creditUsed} />}
    </div>
  );
};

export default BalanceDiscount;
