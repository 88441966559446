import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { getUserPreferences } from 'store/account/selectors';
import { catalogSetFilter, fetchCatalogAndFilters } from 'store/catalog/actions';
import { getFilterOptionsState } from 'store/filter-options/selectors';
import { filterStringToJson, IParamFilterObject } from 'utils/filters';

interface IUrlParams {
  filter: string;
  type: string;
}

interface IProps {
  children: JSX.Element;
}

function ParseUrlFilters({ children }: IProps): JSX.Element | null {
  const dispatch = useDispatch();
  const { filter: queryFilters, type: productType } = useParams<IUrlParams>();
  const filterOptionsState = useSelector(getFilterOptionsState);
  const userPreferences = useSelector(getUserPreferences);
  const catalogCategories = filterOptionsState.data.catalogCategory;

  const filterParams: IParamFilterObject | undefined = useMemo(
    () => filterStringToJson(queryFilters, catalogCategories),
    [queryFilters, catalogCategories]
  );

  useEffect(() => {
    if (filterParams && filterOptionsState.loaded) {
      dispatch(
        catalogSetFilter({
          productType,
          ...filterParams,
          // * update fields with account pref, TODO it is better to do this on success fetching the account data
          deliveryRegion: filterParams.deliveryRegion || userPreferences?.deliveryRegion,
          supplier: filterParams.supplier || userPreferences?.supplier
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams, productType, filterOptionsState.loaded, dispatch]);

  useEffect(() => {
    // * initial fetch of products and filter options
    dispatch(
      fetchCatalogAndFilters({
        productType,
        ...filterParams,
        // * update fields with account pref, TODO it is better to do this on success fetching the account data
        deliveryRegion: filterParams.deliveryRegion || userPreferences?.deliveryRegion,
        supplier: filterParams.supplier || userPreferences?.supplier
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
}

export { ParseUrlFilters };
