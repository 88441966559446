import { createReducer, PayloadAction, createAsyncAction, createAction } from "typesafe-actions";
import {
  loadedDataWrapper,
  REQUEST_ACTIONS,
  IAsyncDataWrapper,
  loadingDataWrapper,
  errorDataWrapper
} from "store/actions";

import { TransactionsRepository } from "./request";
import { call, put, takeEvery } from "redux-saga/effects";
import { logout } from "../auth/actions";
import { ITransaction, Transaction } from "models/transaction";
import { Currency } from "../../models";

const httpClient = new TransactionsRepository();

export const prefix = "@@transactions/";

export const TRANSACTIONS_RESET = `${prefix}RESET`;
export const TRANSACTIONS_REQUEST = `${prefix}${REQUEST_ACTIONS.REQUEST}`;
export const TRANSACTIONS_REQUEST_SUCCESS = `${prefix}${REQUEST_ACTIONS.SUCCESS}`;
export const TRANSACTIONS_REQUEST_FAILURE = `${prefix}${REQUEST_ACTIONS.FAILURE}`;

interface ITransactionsStateSync {
  transactions: Transaction[];
  currencies: Currency[];
}

export type ITransactionsState = IAsyncDataWrapper<ITransactionsStateSync>;

export const transactionsInitialState: ITransactionsState = {
  loading: false,
  loaded: false,
  data: {
    transactions: [],
    currencies: []
  },
  error: null
};

type TransactionsActionTypes =
  | typeof TRANSACTIONS_REQUEST
  | typeof TRANSACTIONS_REQUEST_SUCCESS
  | typeof TRANSACTIONS_REQUEST_FAILURE;

export const fetchTransactionsAsync = createAsyncAction(
  TRANSACTIONS_REQUEST,
  TRANSACTIONS_REQUEST_SUCCESS,
  TRANSACTIONS_REQUEST_FAILURE
)<void, ITransactionsStateSync, Error>();

export const resetTransactions = createAction(TRANSACTIONS_RESET)();

function* transactionsSaga(): Generator {
  try {
    const response: any = yield call(() => httpClient.fetch());
    const transactions = response.data.docs.map((item: ITransaction) => new Transaction(item));
    const currencies = response.data.currencies.map((item: Currency) => new Currency(item));

    yield put(fetchTransactionsAsync.success({ transactions, currencies }));
  } catch (err) {
    yield put(fetchTransactionsAsync.failure(err as Error));
  }
}

export function* transactionsRequestSaga() {
  yield takeEvery(fetchTransactionsAsync.request, transactionsSaga);
  yield takeEvery(logout, resetTransactions);
}

export default createReducer(transactionsInitialState)
  .handleAction(fetchTransactionsAsync.request, (state: ITransactionsState) => loadingDataWrapper(state.data))
  .handleAction(
    fetchTransactionsAsync.success,
    (state: ITransactionsState, action: PayloadAction<TransactionsActionTypes, ITransactionsStateSync>) =>
      loadedDataWrapper(
        {
          transactions: action.payload.transactions,
          currencies: action.payload.currencies
        },
        null,
        false
      )
  )
  .handleAction(fetchTransactionsAsync.failure, (state: ITransactionsState) =>
    errorDataWrapper({ ...state.data }, new Error("Failed to load transactions"))
  );
