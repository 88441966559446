import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import env from 'environment';
import moment from 'moment';

import { Icon } from 'components/shared';
import FlrCard from 'components/shared/card/FlrCard';
import { TextBody2, TextSubTitle, TitleH1 } from 'components/shared/text';
import Tooltip from 'components/shared/tooltip';
import { IClientDiscount, IDiscountCondition } from 'models';
import messages from 'translations/account/dashboard';

import classes from './DashboardLoyaltyCard.module.scss';

interface IPropsLoyaltyCard {
  discount: IDiscountCondition;
  link: string;
  isLoading?: boolean;
  clientDiscount?: IClientDiscount;
  smallCards?: boolean;
}

const DashboardLoyaltyCard: FC<IPropsLoyaltyCard> = ({ discount, link, clientDiscount, isLoading, smallCards }) => {
  const { image, name, discountType } = discount;
  const imageUrl = image ? `${env.apiUrl}/static/product-discount-images/${image}` : '/images/discount-card-2.webp';

  const guaranteedDiscountPercent = discount?.discountData?.nextDiscountProgress?.percent || 0;

  const nextDiscountPercent: any = (() => {
    if (discount?.discountData) {
      return Object.keys(discount.conditions).find(
        (k) => Number(discount.conditions[k]) >= (discount?.discountData?.nextDiscountProgress?.progressValue || 0)
      );
    }
    return Object.keys(discount.conditions)[0];
  })();

  const getPercent = () => {
    if (discount.discountType === 'static' || discount.discountType === 'website') {
      return Object.keys(discount.conditions)[0];
    }
    if (clientDiscount?.discountData?.percent) {
      return clientDiscount.discountData.percent || 0;
    }
    return 0;
  };

  const nextMonth = moment().startOf('month').add(1, 'month').format('D MMMM');
  const lastDayOfMonth = moment().endOf('month').format('D MMMM');

  const nextDiscountAmount = clientDiscount
    ? Number(discount.conditions[nextDiscountPercent]) -
      (discount?.discountData?.nextDiscountProgress?.progressValue || 0)
    : Number(discount.conditions[nextDiscountPercent]);

  const Conditions = () => {
    return (
      <>
        <TextSubTitle color={'inherit'} style={{ marginTop: 0 }}>
          <FormattedMessage {...messages.tooltipTitle} values={{ nextMonth, guaranteedDiscountPercent }} />
        </TextSubTitle>
        <br />
        <TextBody2 className={classes.tooltipText}>
          <FormattedMessage
            {...messages.tooltipText}
            values={{
              nextDiscountPercent,
              nextDiscountAmount,
              lastDayOfMonth
            }}
          />
        </TextBody2>
      </>
    );
  };

  const percentLevel = getPercent();

  return (
    <div className={clsx(classes.cardPaper, { [classes.smallCards]: smallCards })}>
      <NavLink className={classes.cardLink} to={link}>
        <FlrCard hoverShadow zeroPadding className={classes.cardLoyalty}>
          <img className={classes.cardLoyaltyImage} src={imageUrl} alt={''} />
          {discountType !== 'static' && discountType !== 'website' ? (
            <div className={classes.cardLoyaltyTooltip}>
              <Tooltip
                placement="top"
                icon={<Icon type={'info'} size={24} offset={8} opacity={1} />}
                title={<Conditions />}
                classes={{ tooltip: classes.tooltipClass }}
              >
                <span>
                  <Icon type={'info'} size={32} opacity={1} className={classes.cardLoyaltyTooltipIcon} />
                </span>
              </Tooltip>
            </div>
          ) : null}
          <div className={classes.cardLoyaltyContent}>
            <TextSubTitle className={classes.name} title={name}>
              {name}
            </TextSubTitle>

            {!isLoading && (
              <TitleH1 component="span" className={classes.percentLevel}>
                {percentLevel}%
              </TitleH1>
            )}
          </div>
        </FlrCard>
      </NavLink>
    </div>
  );
};

export default DashboardLoyaltyCard;
