import { HttpClient } from 'utils/http';

export class OrderRepository {
  private client = new HttpClient(`${process.env.REACT_APP_API_ENDPOINT}/account/orders`);
  private clientFeedback = new HttpClient(`${process.env.REACT_APP_API_ENDPOINT}/account/order-reviews`);

  public async fetch() {
    // we have to be sure token is fresh
    this.client.setAuthHeader();
    return await this.client.get('/', { params: { range: '[0,99999999]' } }).then(orders => orders.data);
  }

  public async get(orderId: string) {
    this.client.setAuthHeader();
    return await this.client.get(`/${orderId}`);
  }

  public async getReaction(orderId: string) {
    this.client.setAuthHeader();
    return (await this.clientFeedback.get(`/${orderId}`)).data;
  }
  // public async addReaction(orderId: string, ) {
  //   this.client.setAuthHeader();
  //   return (await this.clientFeedback.post(`/${orderId}`)).data;
  // }
}
