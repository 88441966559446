import React from 'react';
import { Column } from 'material-table';
import { Grid } from '@mui/material';
import moment from 'moment';
import { useLocation } from 'react-router';
import get from 'lodash/get';

import { Order, orderStatusOptions, ORDER_TYPE_PREORDER } from 'models';

import { Link1, TextBody1, TextBody2 } from 'components/shared/text';
import { defaultLocale, UAH } from 'shared/constants';
import { SplitedPrice } from 'components/prices';
import messages from 'translations/account/order';

// todo columns to actual model
const prettyDate = (date: Date, isShort?: boolean) => {
  const mdate = moment(date);
  return mdate.locale(defaultLocale).format(isShort ? 'dd DD.MM' : 'DD.MM.YYYY');
};

interface IProps {
  // component own props
  rowData: Order;
}

const LinkColumn: React.FC<IProps> = ({ rowData }) => {
  const { pathname } = useLocation();

  const orderId = rowData && rowData.orderId;

  return (
    <Grid container direction={'column'} spacing={1} justifyContent={'flex-start'}>
      <Grid item>
        <Link1 to={`${pathname}/${orderId}`}>№ {rowData && rowData.orderId}</Link1>
      </Grid>
      <Grid item>
        <TextBody2 color={'textSecondary'}>
          {messages.tableStatusFrom.defaultMessage} {prettyDate(rowData && rowData.createdAt)}
        </TextBody2>
      </Grid>
    </Grid>
  );
};

const columns: Array<Column<Order>> = [
  {
    title: messages.tableOrder.defaultMessage,
    field: 'orderId',
    render: rowData => <LinkColumn rowData={rowData} />
  },
  {
    title: messages.tableStatus.defaultMessage,
    field: 'createdAt',
    defaultSort: 'desc',
    render: rowData => <span>{orderStatusOptions[rowData.orderStatus] || rowData.orderStatus}</span>
  },
  {
    title: messages.tableDateShipment.defaultMessage,
    field: 'deliveryDate',
    render: rowData => (
      <Grid container direction={'column'} spacing={1} justifyContent={'flex-start'}>
        <Grid item>
          <TextBody1>{rowData.deliveryDate ? prettyDate(rowData.deliveryDate) : '-'}</TextBody1>
        </Grid>
        <Grid item>
          <TextBody2 color={'textSecondary'}>{rowData?.orderDetails?.deliveryTime?.name || ''}</TextBody2>
        </Grid>
      </Grid>
    )
  },
  {
    title: messages.tableDeliveryType.defaultMessage,
    field: 'deliveryMethod',
    render: rowData => (
      <Grid container direction={'column'} spacing={1} justifyContent={'flex-start'}>
        <Grid item>
          <TextBody1>{get(rowData, 'orderDetails.deliveryMethod.name', '-')}</TextBody1>
        </Grid>
        <Grid item>
          <TextBody2 color={'textSecondary'}>
            {rowData.outlet!.name}
            {rowData.outlet!.address ? `: ${rowData.outlet!.address}` : ''}
          </TextBody2>
        </Grid>
      </Grid>
    )
  },
  {
    title: messages.tableDeliveryCount.defaultMessage,
    field: 'totalQty',
    type: 'numeric',
    render: rowData => (
      <Grid container direction={'column'} spacing={1} justifyContent={'flex-start'}>
        <Grid item>
          <TextBody1>{rowData.totalQty} шт</TextBody1>
        </Grid>
        {+rowData.totalPackings > 0 && (
          <Grid item>
            <TextBody2 color={'textSecondary'}>{rowData.totalPackings} уп.</TextBody2>
          </Grid>
        )}
      </Grid>
    )
  },
  {
    title: messages.tableDeliveryAmount.defaultMessage,
    field: 'totalSum',
    type: 'numeric',
    render: rowData => (
      <Grid container direction={'column'} spacing={1} justifyContent={'flex-start'}>
        <Grid item>
          <SplitedPrice
            fontSize={'large'}
            value={rowData.totalSum}
            prefix={rowData.orderType === ORDER_TYPE_PREORDER ? '~' : undefined}
            postfix={UAH}
          />
        </Grid>
      </Grid>
    )
  }
];

export const columnsMobile: Array<Column<any>> = [
  {
    title: messages.tableDateShipment.defaultMessage,
    field: 'deliveryDate',
    render: (rowData: Order) => (
      <Grid container direction={'column'} spacing={1}>
        <Grid item>
          <TextBody2>{rowData?.orderDetails?.warehouse?.displayName || ''}</TextBody2>
        </Grid>
        <Grid item>
          <TextBody2 color={'textSecondary'}>
            <span style={{ whiteSpace: 'nowrap' }}>
              {rowData.deliveryDate ? prettyDate(rowData.deliveryDate, true) : '-'}
            </span>
            <span>{`  `}</span>
            <span style={{ whiteSpace: 'nowrap' }}>{rowData?.deliveryTime?.name || ''}</span>
          </TextBody2>
        </Grid>
      </Grid>
    )
  },
  {
    title: messages.tableDeliveryAmount.defaultMessage,
    field: 'totalSum',
    type: 'numeric',
    render: rowData => (
      <Grid container direction={'column'} spacing={1}>
        <Grid item>
          <SplitedPrice
            fontSize={'body2'}
            decimalSameSize
            value={rowData.totalSum}
            prefix={rowData.orderType === ORDER_TYPE_PREORDER ? '~' : undefined}
            postfix={UAH}
          />
        </Grid>
        <Grid item>
          <TextBody2 color={'textSecondary'}>
            <SplitedPrice
              fontColor={'inherit'}
              fontSize={'body2'}
              hideDecimal
              value={rowData.totalQty}
              postfix={'шт'}
            />
          </TextBody2>
        </Grid>
      </Grid>
    )
  }
];

export default columns;
