import React, { FC } from 'react';
import { Grid } from '@mui/material';

import { PERSONAL_DISCOUNT } from 'models';

import RuleText from './RuleText';

interface IProps {
  type: any;
  classNameWrapper?: string;
}

const DiscountRules: FC<IProps> = ({ type, classNameWrapper }) => {
  const keys: string[] =
    type === PERSONAL_DISCOUNT ? ['gettingDiscount', 'losingDiscount'] : ['increasingDiscount', 'decreasingDiscount'];
  return (
    <div className={classNameWrapper}>
      {keys.map((key, index) => (
        <Grid item sm key={`discount-rule-${index}`}>
          <RuleText messageKey={key} />
        </Grid>
      ))}
    </div>
  );
};

export default DiscountRules;
