import _cloneDeep from 'lodash/cloneDeep';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import moment from 'moment';

import { IProductAlphaLetter, Product, ProductAlphabet, ProductAttributesDictionary } from 'models/product';
import { defaultLocale } from 'shared/constants';
import { ATTRIBUTE_HEIGHT_PATH, ATTRIBUTE_PRICE_PATH } from 'shared/filters';
import { IFilterCombined, IFilterProps, IFiltersCombined } from 'store/catalog/actions';
import messages from 'translations/auth/registration';
import methodMessages from 'translations/checkout/delivery-methods';
import packageMessages from 'translations/checkout/delivery-package';
import timeMessages from 'translations/checkout/delivery-time';
import commonMessages from 'translations/common';

type IGetFilteredProducts = (
  filters: IFilterProps,
  docs: Product[]
) => { filteredProducts: Product[]; filteredProductsByCategories: Product[] };

export const buildCatalogAlphabet = (docs: Product[], locale: string): IProductAlphaLetter[] => {
  const newAlphabet: IProductAlphaLetter[] = [];
  ProductAlphabet[locale].map((letter: string, letterIndex: number) => {
    const filterFn = (product: Product) =>
      ProductAlphabet[locale].indexOf(product.name[0].toLowerCase()) === letterIndex;
    const productIndexFirst = docs.findIndex(filterFn);
    const productIndexLast = findLastIndex(docs, filterFn);
    const alphaLetter: IProductAlphaLetter = {
      startsAt: Math.max(productIndexFirst, 0),
      endsAt: Math.max(productIndexLast, 0),
      name: letter.toUpperCase(),
      enabled: productIndexFirst > -1,
      selected: false,
      price: 0,
      height: 0
    };
    return newAlphabet.push(alphaLetter);
  });
  return newAlphabet;
};

export const getFilteredProducts: IGetFilteredProducts = (filters, docs) => {
  const filteredByCategories: Product[] = [];
  const combinedFilters: IFiltersCombined = {};
  filters.forEach((filter) => {
    if (!combinedFilters[filter.path]) {
      combinedFilters[filter.path] = { rule: filter.rule, value: [] } as IFilterCombined;
    }
    combinedFilters[filter.path].value.push(filter.value);
  });

  // sort filters to make the filter by category primary (at 0 position in array)
  const listOfFilters = Object.entries(combinedFilters).sort((a, b) => (a[0] === 'category' ? -1 : 0));

  let filteredProducts: Product[] = _cloneDeep(docs);
  for (const [path, filter] of listOfFilters) {
    filteredProducts = _filter(filteredProducts, (product) => {
      let pathValue;
      if (path === 'category') {
        pathValue = { value: _get(product, 'catalogCategoryCode') || '' };
      } else if (path === ATTRIBUTE_PRICE_PATH) {
        pathValue = _get(product, 'preOrderPrice');
      } else if (path === ATTRIBUTE_HEIGHT_PATH) {
        pathValue = Number(_get(product, `${path}.value`, 0));
      } else {
        pathValue = _get(product, path, { value: '' });
      }

      if (pathValue && pathValue.hasOwnProperty('value') && !pathValue.value) {
        return false;
      } else if (pathValue) {
        let isMatchFilter;
        switch (filter.rule) {
          case 'include':
            isMatchFilter = _includes(filter.value, pathValue.value);
            if (path === 'category' && isMatchFilter) {
              filteredByCategories.push(product);
            }
            break;
          case 'range':
            const range = filter.value[0].split('-').map((val) => parseFloat(val));
            isMatchFilter = pathValue >= range[0] && pathValue <= range[1];
            break;
          default:
            isMatchFilter = filter.value === pathValue.value;
        }
        return isMatchFilter;
      }
      return false;
    });
  }

  const filteredProductsByCategories = filteredByCategories.length ? filteredByCategories : docs;

  return { filteredProducts, filteredProductsByCategories };
};

export const postFilterFilters = (products: Product[]) => {
  let filteredMap: Map<any, any> = new Map(Object.keys(ProductAttributesDictionary).map((key) => [key, new Map()]));
  filteredMap.set('prices', new Set());
  products.forEach((p) => {
    Object.keys(p.attributes).forEach((attrName) => {
      const attrMap = filteredMap.get(attrName);
      if (attrMap) {
        if (attrMap.has(p.attributes[attrName]?.value)) {
          attrMap.set(p.attributes[attrName].value, attrMap.get(p.attributes[attrName].value) + 1);
        } else {
          attrMap.set(p.attributes[attrName]?.value, 1);
        }
      }
    });
    // add filter options based on "preOrderPrice"
    filteredMap.get('prices').add(p.preOrderPrice);
  });
  return filteredMap;
};

export function chunk<T>(arr: T[], chunkSize: number): T[][] {
  const R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}

export function humanFileSize(bytes: number, si: boolean = true): string {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
}

export function formattedStringPrice(x: number) {
  const parts = x.toString().split('.');
  const glued = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + (parts[1] ? '.' + parts[1] : '');
  return `${glued}`;
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target: object, ...sources: object[]): object {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function findLastIndex(array: any[], compareFn: (value: any) => boolean): number {
  const index = array.slice().reverse().findIndex(compareFn);
  const count = array.length - 1;
  const finalIndex = index >= 0 ? count - index : index;
  return finalIndex;
}

export function getViewportHeight(): number {
  return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

export function attributeSuffix(attr: string) {
  if (attr === 'height') {
    return 'см';
  }
  if (attr === 'weight') {
    return 'г';
  }
  if (attr === 'packing') {
    return 'шт';
  }
  return '';
}

export const methodLabel = (method: string) =>
  (methodMessages[`${method}Label`] && methodMessages[`${method}Label`].defaultMessage) || '';
export const methodDescription = (method: string) =>
  (methodMessages[`${method}Description`] && methodMessages[`${method}Description`].defaultMessage) || '';

export const timeLabel = (value: string) =>
  (timeMessages[value] && timeMessages[value].defaultMessage) || timeMessages.other.defaultMessage;

export const packageIdLabel = (method: string) =>
  (packageMessages[`${method}Label`] && packageMessages[`${method}Label`].defaultMessage) || '';
export const packageIdDescription = (method: string) =>
  (packageMessages[`${method}Description`] && packageMessages[`${method}Description`].defaultMessage) || '';

export const prettyDate = (date: Date) => {
  const mdate = moment(date);
  const today = moment().endOf('day');
  const dow = moment().isoWeekday();
  const tomorrow = moment().add(1, 'day').endOf('day');

  let prefix = '';
  if (mdate < today) {
    prefix = 'Сьогодні';
  } else if (mdate < tomorrow) {
    prefix = 'Завтра';
  } else if (mdate.diff(today, 'days') < 7 - dow && mdate > tomorrow) {
    prefix = 'Цей тиждень';
  } else if (mdate.diff(today, 'days') < 14 - dow && mdate > tomorrow) {
    prefix = 'Наступний тиждень';
  }
  return `${prefix ? `${prefix}, ${mdate.locale(defaultLocale).format('ddd')}` : mdate.format('dddd')}`;
};

export function validateEmail(email: string) {
  const re =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase()) || commonMessages.emailNotValid.defaultMessage;
}

export const validatePassword = (value: string) => {
  if (!/((?=.*[a-z])(?=.*[A-Z]))+/.test(value)) {
    return commonMessages.passwordValidationAz.defaultMessage;
  }

  if (!/([^a-zA-Z]|[0-9])+/.test(value)) {
    return commonMessages.passwordValidationNumber.defaultMessage;
  }

  if (value.length < 8) {
    return commonMessages.passwordValidationLength.defaultMessage;
  }

  return true;
};

export const validatePhoneMask = (data: string) => {
  const digits = extractDigitsFromString(data);
  if (digits.length > 0 && digits.length !== 12) {
    return messages.formFieldErrorPhoneNotValid.defaultMessage;
  }
};

export const getPluralKey = (cow: number) => {
  let weeksText = 0;
  if (!(cow > 10 && cow < 15)) {
    weeksText = cow % 10;
  }
  return weeksText;

  /*
  *
  * defaultMessage: `Отримано за {weeks} {weeksKey, plural,
        one {тиждень}
        =2 {тижні}
        =3 {тижні}
        =4 {тижні}
        other {тижнів}
    }`

  * */
};

export const uaSort = (s1: string, s2: string): number => s1.localeCompare(s2);

export const getProductImage = (item: Product, asBackground?: boolean, fullImage?: boolean) => {
  if (asBackground) {
    if (item.images && item.images[0]) {
      return `url(${process.env.REACT_APP_API_ENDPOINT}/static/product/image/${fullImage ? 'full' : 'thumbnail'}/${
        item.images![0]
      })`;
    }
    return 'url(/images/flower-no-image.svg)';
  }
  return (
    (item.images &&
      item.images[0] &&
      `${process.env.REACT_APP_API_ENDPOINT}/static/product/image/${fullImage ? 'full' : 'thumbnail'}/${
        item.images![0]
      }.webp`) ||
    '/images/flower-no-image.svg'
  );
};

export const clearPopovers = (clearAfter?: boolean) => {
  if (clearAfter) {
    document.querySelectorAll('.MuiPopover-root').forEach((node) => {
      const nodeEl = node as HTMLElement;
      if (nodeEl.style.visibility !== 'hidden') {
        (nodeEl.childNodes[0] as HTMLElement).click();
      }
    });
  }
};

export const extractDigitsFromString = (str: string = ''): string => str.replace(/\D/g, '');
