import * as React from "react";
import { Text } from "components/layout/content/Text";
import { ITheme } from "utils/styled";

interface ITextProps {
  value: number;
  fontSize?: keyof ITheme["fontSizes"];
  fontSizeDecimal?: keyof ITheme["fontSizes"];
  fontColor?: keyof ITheme["colors"];
  negative?: boolean;
  positive?: boolean;
  bold?: boolean;
  hideDecimal?: boolean;
  decimalSameSize?: boolean;
  prefix?: React.ReactNode;
  postfix?: React.ReactNode;
  className?: string;
  prefixSize?: keyof ITheme["fontSizes"];
  priceDisplayStyle?: object;
  decimalStyles?: object;
}

const SplitedPrice: React.FC<ITextProps> = ({
  value,
  fontSize,
  fontSizeDecimal,
  fontColor,
  bold = true,
  negative,
  positive,
  hideDecimal,
  decimalSameSize,
  prefix,
  postfix,
  className,
  prefixSize,
  priceDisplayStyle = {},
  decimalStyles = {}
}) => {
  const val = value || 0;
  const data = String(Number(val).toFixed(2)).split(".");

  const decimalFontSize = decimalSameSize ? fontSize || "h4" : fontSizeDecimal;

  return (
    <span className={className} style={{ whiteSpace: "nowrap" }}>
      {prefix && (
        <Text
          fontSize={prefixSize || fontSize || "h4"}
          bold={bold}
          fontColor={fontColor}
          success={positive}
          error={negative}
          style={priceDisplayStyle}
        >
          {prefix}
        </Text>
      )}

      <Text
        fontSize={fontSize || "h4"}
        bold={bold}
        fontColor={fontColor}
        success={positive}
        error={negative}
        style={priceDisplayStyle}
      >
        {formatNumber(Number(data[0]))}
      </Text>

      {!hideDecimal && data[1] && (
        <Text
          fontSize={decimalFontSize}
          bold={bold}
          fontColor={fontColor}
          error={negative}
          success={positive}
          style={{...priceDisplayStyle, ...decimalStyles }}
        >
          ,{data[1]}
        </Text>
      )}

      {postfix && (
        <Text
          fontSize={hideDecimal ? fontSize || "h4" : decimalFontSize}
          bold={bold}
          fontColor={fontColor}
          success={positive}
          error={negative}
        >
          {` ${postfix}`}
        </Text>
      )}
    </span>
  );
};

function formatNumber(num: number): string {
  return new Intl.NumberFormat("ru-RU").format(num);
}

export default SplitedPrice;
