import React, { useRef, useState } from 'react';
// import { useTheme } from '@mui/styles';
// import { Theme, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
// @ts-ignore
import ImageGallery from 'react-image-gallery';

import { withStyles } from '@mui/styles';
import styles from './styles';

interface IProps {
  classes: any;
  images: string[];
  modalOpen: boolean;
  toggleModalGallery: () => void;
}

interface IZoomImageProps {
  item: any;
  classes: any;
  disableSwipe: boolean;
  setDisableSwipe: (state: boolean) => void;
  currentIndex: number;
  setStartIndex: (state: number) => void;
}

const ZoomImage = withStyles<any>(styles)(
  ({ item, classes, disableSwipe, setDisableSwipe, currentIndex, setStartIndex }: IZoomImageProps) => {
    const [scale, setScale] = useState(1);

    const onZoomChange = (e: any) => {
      if (e.state.scale > 1) {
        setScale(e.state.scale);
        if (!disableSwipe) {
          setDisableSwipe(true);
        }
        setStartIndex(currentIndex);
      } else {
        setScale(1);
        if (disableSwipe) {
          setDisableSwipe(false);
        }
        setStartIndex(currentIndex);
      }
    };

    return (
      <TransformWrapper
        onZoom={onZoomChange}
        minScale={1}
        centerOnInit
        panning={{ disabled: scale <= 1 }}
        doubleClick={{ disabled: scale <= 1 }}
      >
        <TransformComponent wrapperStyle={{ width: 'auto' }}>
          <img src={item.original} alt={''} className={classes.mainSliderImg} />
        </TransformComponent>
      </TransformWrapper>
    );
  }
);

const MiniDetailsModalGallery: React.FC<IProps> = ({ classes, images, modalOpen, toggleModalGallery }) => {
  // const theme: Theme = useTheme();
  const [disableSwipe, setDisableSwipe] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const getImageUrl = (img: string): string =>
    img ? `${process.env.REACT_APP_API_ENDPOINT}/static/product/image/${img}` : '/images/flower-no-image.svg';

  const currentIndex = useRef(0);

  function handleClickOnMobile(e: any) {
    if (
      e.target &&
      e.target.tagName !== 'IMG' &&
      e.target.tagName !== 'polyline' &&
      e.target.tagName !== 'svg' &&
      !e.target.classList.contains('react-transform-component')
    ) {
      toggleModalGallery();
      setStartIndex(0);
    }
  }

  const renderImage = (item: any) => {
    // if (isMobile) {
    return (
      <ZoomImage
        item={item}
        setDisableSwipe={setDisableSwipe}
        setStartIndex={setStartIndex}
        disableSwipe={disableSwipe}
        currentIndex={currentIndex.current}
      />
    );
    // }

    // return (
    //   <div>
    //     <img src={item.original} alt={''} className={classes.mainSliderImg} />
    //   </div>
    // );
  };

  const imagesArray: object[] = images.map(imgId => ({
    original: getImageUrl(imgId),
    thumbnail: getImageUrl(imgId),
    renderItem: renderImage,
    thumbnailWidth: 40
  }));

  const onBeforeSlide = (idx: number) => {
    currentIndex.current = idx;
  };

  return (
    <Dialog
      open={modalOpen}
      onClick={handleClickOnMobile}
      onClose={toggleModalGallery}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      classes={{
        root: classes.dialogRoot,
        container: classes.dialogContainer,
        scrollBody: classes.scrollBody,
        paperWidthSm: classes.paperWidthSm,
        paperScrollBody: classes.galleryScrollBody
      }}
    >
      <DialogContent className={classes.galleryContent}>
        <ImageGallery
          disableSwipe={disableSwipe}
          startIndex={startIndex}
          autoplay={false}
          showFullscreenButton={false}
          showPlayButton={false}
          items={imagesArray}
          onBeforeSlide={onBeforeSlide}
        />
      </DialogContent>
    </Dialog>
  );
};

export default withStyles<any>(styles)(MiniDetailsModalGallery);
