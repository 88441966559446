import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { IDiscountByGroup } from 'store/discount-by-group/actions';
import Tooltip from 'components/shared/tooltip';
import { TextBody2, TextBody1, TextSubTitle, TitleH1 } from 'components/shared/text';
import Icon from 'components/shared/Icon';
import FlrCardNarrow from 'components/shared/card/FlrCardNarrow';
import FlrCardTitle from 'components/shared/card/FlrCardTitle';
import messages from 'translations/account/loyalty';
import styles from '../styles';

interface IProps {
  discount: IDiscountByGroup | null;
  classes: any;
}

const ProductGroupDiscountCard: React.FC<IProps> = ({ discount, classes }) => {
  if (!discount) {
    return null;
  }

  const HistoryContent = () => {
    return (
      <div>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item>
            <TextSubTitle color="inherit" style={{ marginTop: 0 }}>
              {messages.tooltipTitle.defaultMessage}
            </TextSubTitle>
          </Grid>
          <Grid item>
            <TextSubTitle color="inherit" style={{ marginTop: 0, fontSize: 12 }}>
              {messages.tooltipTitlePeriod.defaultMessage}
            </TextSubTitle>
          </Grid>
        </Grid>
        {discount.history && discount.history.length
          ? discount.history.map(h => {
              return (
                <Grid key={h.id} container alignItems="center" justifyContent="space-between">
                  <Grid item style={{ flex: 1 }}>
                    <TextBody2 style={{ color: 'white' }}>{moment(h.onDate).format('MMMM YYYY')}</TextBody2>
                  </Grid>
                  <Grid item>
                    <TextBody2 style={{ color: 'white' }}>{h.discountData.progressValue} шт</TextBody2>
                  </Grid>
                  <Grid item style={{ width: 70, textAlign: 'right' }}>
                    <TextBody1 style={{ color: 'white' }}>{h.discountData.percent} %</TextBody1>
                  </Grid>
                </Grid>
              );
            })
          : null}
      </div>
    );
  };

  const lastDayOfMonth = moment()
    .endOf('month')
    .format('D MMMM YYYY');
  const date = moment(discount?.currentDiscount?.startDate || '');

  const startDate =
    date.month() === moment(discount?.currentDiscount?.endDate || '').month()
      ? date.format('D')
      : date.format('D MMMM');
  const endDate = moment(discount?.currentDiscount?.endDate || '').format('D MMMM YYYY');

  const getCurrentDiscountPercent = () => {
    if (discount?.discountRule?.discountType === 'website') {
      return discount?.nextDiscountProgress?.percent || 0;
    }
    return discount?.currentDiscount?.percent || 0;
  };

  return (
    <FlrCardNarrow className={classes.paperCard} hoverShadow>
      <FlrCardTitle isUnderline>
        <TextSubTitle align="left" className={classes.currentDiscountTitle}>
          {messages.currentDiscountLevel.defaultMessage}
          {discount?.discountRule?.discountType !== 'static' && discount?.discountRule?.discountType !== 'website' ? (
            <Tooltip
              classes={{ tooltip: classes.tooltipClass, rootTitle: classes.rootTitle }}
              icon={<Icon type="history" size={24} opacity={1} offset={8} />}
              title={<HistoryContent />}
            >
              <span>
                <Icon type="history" size={24} leftOffset={8} />
              </span>
            </Tooltip>
          ) : null}
        </TextSubTitle>
        <TextSubTitle align="right">
          <TextBody2 className={classes.descriptionText}>
            {discount?.discountRule?.discountType !== 'static' && discount?.discountRule?.discountType !== 'website' ? (
              <FormattedMessage {...messages.currentDiscountDate} values={{ lastDay: lastDayOfMonth }} />
            ) : (
              <FormattedMessage {...messages.currentDiscountDateRange} values={{ startDate, endDate }} />
            )}
          </TextBody2>
        </TextSubTitle>
      </FlrCardTitle>
      <Grid item container xs alignItems="flex-start">
        <TitleH1 className={classes.currentDiscount}>{getCurrentDiscountPercent()} %</TitleH1>
      </Grid>
    </FlrCardNarrow>
  );
};

export default withStyles<any>(styles)(ProductGroupDiscountCard);
