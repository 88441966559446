const styles = (theme: any) => ({
  header: {
    marginBottom: 0
  },
  discountRules: {
    marginTop: theme.spacing(6)
  },
  rulesSubtitle: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(1.5)
  },
  rulesSubSubtitle: {
    marginBottom: theme.spacing(1.5)
  },
  productsWithDiscount: {
    marginBottom: theme.spacing(2)
  },
  percentLevel: {
    marginTop: 0,
    marginBottom: 0,
    lineHeight: theme.typography.body1.lineHeight,
    color: theme.palette.textSecondary
  },
  progressRequireOr: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  mediumBold: {
    fontWeight: 500
  },
  historyChart: {
    '& .apexcharts-canvas >svg': {
      overflow: 'visible'
    },
    '& foreignObject': {
      overflow: 'visible'
    },
    '& .apexcharts-legend': {
      zIndex: 1,
      top: '-45px !important',
      left: `-${theme.spacing(2)}  !important`,
      right: `-${theme.spacing(2)}  !important`
    }
  },
  historyChartYTitle: {
    width: 60,
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'inline-block',
    textAlign: 'left',
    '&.subtitle': {
      top: 'auto',
      bottom: 0
    }
  },
  historyChartXTitle: {
    width: 60,
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'inline-block'
  },
  chartTooltip: {
    padding: theme.spacing(1)
  },
  cardLoyaltyImage: {
    borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
    width: '100%',
    height: 112,
    objectFit: 'cover'
  },
  cardDescription: {
    color: theme.palette.common.helperBlack
  },
  discountGroupsTitle: {
    marginBottom: 0
  },
  discountGroupsDesc: {
    marginBottom: theme.spacing(3)
  },
  descriptionText: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular
  },
  fadeBlock: {
    position: 'relative',

    '&:after': {
      content: "''",
      position: 'absolute',
      right: 0,
      left: 0,
      top: 0,
      bottom: 0,
      backgroundColor: 'rgba(255,255,255,0.6)'
    }
  },
  currentDiscountTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  currentDiscount: {
    marginBottom: 0
  },
  tooltipClass: {
    maxWidth: 500,
    minWidth: 380
  },
  rootTitle: {
    flex: 1
  },
  categoriesCardRoot: {
    marginBottom: theme.spacing(2),
    height: '100%',
  },
  tableContainer: {
    letterSpacing: '0.5pt',
    overflowX: 'initial',

    [theme.breakpoints.down('lg')]: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      maxWidth: `calc(100vw - ${theme.spacing(8)})`
    },

    // workaround for expand panel outline, prevent table horizontal scrolling
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      overflowX: 'initial',
      maxWidth: `calc(100vw - ${theme.spacing(4)})`,

      // only this nesting works
      '& > div > div': {
        overflowX: 'initial !important',

        '& > div > div': {
          overflowY: 'initial !important'
        }
      },

      '& .MuiTableCell-body > .MuiIconButton-root': {
        display: 'none'
      }
    },

    '& .MuiTableRow-root.MuiTableRow-hover:hover': {
      backgroundColor: 'transparent'
    }
  }
});

export default styles;
