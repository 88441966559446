import React, { MouseEvent } from 'react';
import clsx from 'clsx';
import { ProgressBar, Step } from 'react-step-progress-bar';
import { withStyles } from '@mui/styles';
import 'react-step-progress-bar/styles.css';
import { isMobileDevice } from 'environment';
import { TextHelper, TextSubTitle } from 'components/shared/text';
import styles from './styles';

const isMobile = isMobileDevice(window.navigator);

export interface IFlrProgressBarStep {
  label: React.ReactNode;
  value: React.ReactNode;
}

export interface IFlrProgressBarProps {
  steps: IFlrProgressBarStep[];
  activeIndex?: number;
  progress: number;
}

type IProps = IFlrProgressBarProps & {
  classes: any;
};

interface IProgressbarStep {
  accomplished: boolean;
  index: number;
}

const FlrProgressBar: React.FC<IProps> = ({ steps, progress, activeIndex, classes }) => {
  const onMouseMove = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // * horizontal scroll with left mouse button down ONLY on desktop
    if (e.nativeEvent.buttons === 1) {
      e.currentTarget.scrollLeft += -e.movementX;
    }
  };

  const disabled = activeIndex && activeIndex === -1;
  return (
    <div className={classes.root} {...(!isMobile ? { onMouseMove } : {})}>
      <ProgressBar percent={progress} width={steps.length > 4 ? 60 * steps.length : 'calc(100% - 16px)'}>
        {steps.map((step, stepIndex: number) => {
          return (
            <Step key={stepIndex}>
              {({ accomplished, index }: IProgressbarStep) => (
                <div
                  className={clsx(classes.step, {
                    [classes.accomplished]: accomplished && !disabled,
                    [classes.active]: (activeIndex || 0) === index
                  })}
                >
                  <div className={'point'} />
                  <TextSubTitle className={'value text'}>{step.value}</TextSubTitle>
                  <TextHelper className={'label text'}> {step.label}</TextHelper>
                </div>
              )}
            </Step>
          );
        })}
      </ProgressBar>
    </div>
  );
};

export default withStyles<any>(styles)(FlrProgressBar);
