import React from 'react';
import { Column } from 'material-table';

import {
  isReturnOrRefundTransaction,
  ITransaction,
  Transaction,
  TransactionOperationType
} from 'models/transaction';
import { SplitedPrice } from 'components/prices';
import { Link1 } from 'components/shared/text';
// import { EmptyCellDash } from 'components/shared/table/EmptyCellDash';
import messages from 'translations/account/finance';

// interface ICellProps {
//   record: ITransaction;
// }

// enum CurrencyTypes {
//   UAH = '₴ ',
//   USD = '$ ',
//   EUR = '€ '
// }

const forMobile = (func: any) => (...args: any) => func(...args, true);

// function checkCurrencyEquality(record: ITransaction) {
//   return record.currency && record.currencyBase && record.currency.id !== record.currencyBase.id;
// }

function getDebitText(record: ITransaction): string {
  const paymentId: string = (record.operation && record.operation.on && record.operation.on.paymentId) || '';
  let postFix = '';
  if (record.operation && record.operation.on && record.operation.on.type) {
    switch (record.operation.on.type) {
      case 'liqpay':
        postFix = 'через liqpay';
        break;
      case 'cash':
        postFix = 'готівкою';
        break;
      default:
        postFix = 'рахунку';
        break;
    }
  }
  return `Поповнення № ${paymentId} ${postFix}`;
}

function getDebitReturnText(record: ITransaction, link = true): React.ReactElement | string {
  const orderId: string = (record.operation && record.operation.on && record.operation.on.orderId) || '';
  if (link) {
    return <Link1 to={`orders/deliveries/${orderId}`}>Рекламація № {orderId}</Link1>;
  }
  return `Рекламація № ${orderId}`;
}

function getCreditText(record: ITransaction, link = true): React.ReactElement | string {
  const orderId = record.operation && record.operation.on ? record.operation.on.orderId : '';
  if (link) {
    return <Link1 to={`orders/deliveries/${orderId}`}>Замовлення № {orderId}</Link1>;
  }
  return `Замовлення № ${orderId}`;
}

function getCreditReturnText(record: ITransaction): React.ReactElement | string {
  const paymentId: string = (record.operation && record.operation.on && record.operation.on.paymentId) || '';
  return `Повернення коштів № ${paymentId}`;
}

function nameSort(data1: Transaction, data2: Transaction) {
  return NameCell(data2, 'row', false).localeCompare(NameCell(data1, 'row', false));
}

function NameCell (record: Transaction, _: any, link?: boolean): any {
  const {operationType} = record;
  if (isReturnOrRefundTransaction(record)) {
    return operationType === 'debit' ? getDebitReturnText(record, link) : getCreditReturnText(record);
  } else {
    return operationType === 'debit' ? getDebitText(record) : getCreditText(record, link);
  }
}

function MobileNameCell (record: Transaction): any {
  const {operationType} = record;
  if (isReturnOrRefundTransaction(record)) {
    return operationType === "debit" ? "Рекламація" : "Повернення коштів";
  } else {
    return operationType === "debit" ? "Поповнення" : "Замовлення";
  }
}

// const AmountCell = (rowData: Transaction): any => {
//   if (checkCurrencyEquality(rowData)) {
//     const currencyCode: string = rowData.operation && rowData.operation.on ? rowData.operation.on.currencyCode : '';
//     return (
//       <SplitedPrice
//         prefix={CurrencyTypes[currencyCode]}
//         value={rowData.amount}
//         fontSize="medium"
//         bold={false}
//         priceDisplayStyle={{ verticalAlign: 'text-bottom', lineHeight: '22px' }}
//         decimalStyles={{ lineHeight: '20px' }}
//         fontSizeDecimal="small"
//       />
//     );
//   }
//   return <EmptyCellDash />;
// };

// const CurrencyRateCell = (rowData: Transaction): any =>
//   checkCurrencyEquality(rowData) ? (
//     <SplitedPrice value={rowData.currencyRate} fontSize="medium" bold={false} fontSizeDecimal="small" />
//   ) : (
//     <EmptyCellDash />
//   );

const AmountBaseCell = (rowData: Transaction, _: any, isMobile?: boolean): any => {
  const isNegative = rowData.operationType === TransactionOperationType.credit;
  return (
    <SplitedPrice
      prefix={isNegative ? '- ' : '+ '}
      positive={!isNegative}
      value={rowData.amountBase}
      fontSize={isMobile ? 'small' : 'medium'}
      bold={false}
      fontSizeDecimal={isMobile ? 'tiny' : 'small'}
    />
  );
};

const BalanceCell = (rowData: Transaction, _: any, isMobile?: boolean): any => (
  <SplitedPrice
    negative={rowData.balance < 0}
    value={rowData.balance}
    fontSize={isMobile ? 'small' : 'medium'}
    bold={false}
    fontSizeDecimal={isMobile ? 'tiny' : 'small'}
  />
);

const columns: Array<Column<Transaction>> = [
  {
    title: messages.type.defaultMessage,
    field: 'details',
    customSort: nameSort,
    render: NameCell
  },
  {
    title: messages.date.defaultMessage,
    field: 'createdAt',
    defaultSort: 'desc',
    render: rowData => <span>{rowData.dateString}</span>
  },
  // {
  //   title: messages.amount.defaultMessage,
  //   field: 'amount',
  //   type: 'numeric',
  //   render: AmountCell
  // },
  // {
  //   title: messages.transactionCurrency.defaultMessage,
  //   field: 'currencyRate',
  //   type: 'numeric',
  //   render: CurrencyRateCell
  // },
  {
    title: messages.amountBase.defaultMessage,
    field: 'amountBase',
    type: 'numeric',
    render: AmountBaseCell
  },
  {
    title: messages.tableBalance.defaultMessage,
    field: 'balance',
    type: 'numeric',
    render: BalanceCell
  }
];

export const dashboardColumns: Array<Column<Transaction>> = [
  {
    title: messages.type.defaultMessage,
    field: 'details',
    customSort: nameSort,
    render: MobileNameCell
  },
  // {
  //   title: messages.amount.defaultMessage,
  //   field: 'amount',
  //   type: 'numeric',
  //   render: AmountCell
  // },
  // {
  //   title: messages.transactionCurrency.defaultMessage,
  //   field: 'currencyRate',
  //   type: 'numeric',
  //   render: CurrencyRateCell
  // },
  {
    title: messages.amountBase.defaultMessage,
    field: 'amountBase',
    type: 'numeric',
    render: AmountBaseCell
  },
  {
    title: messages.tableBalance.defaultMessage,
    field: 'balance',
    type: 'numeric',
    render: BalanceCell
  }
];

export const columnsMobile: Array<Column<Transaction>> = [
  {
    title: messages.type.defaultMessage,
    field: 'details',
    customSort: nameSort,
    render: MobileNameCell
  },
  {
    title: messages.amountBase.defaultMessage,
    field: 'amountBase',
    type: 'numeric',
    render: forMobile(AmountBaseCell)
  },
  {
    title: messages.tableBalance.defaultMessage,
    field: 'balance',
    type: 'numeric',
    render: forMobile(BalanceCell)
  }
];

export default columns;
