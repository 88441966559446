import { HttpClient } from 'utils/http';
import { AuthService } from 'store/auth/service';

export class TransactionsRepository {
  private client = new HttpClient(`${process.env.REACT_APP_API_ENDPOINT}/account/transactions?includeCurrency=true`);
  private service = new AuthService();

  public async fetch() {
    // we have to be sure token is fresh
    this.client.setAuthHeader();
    return await this.client.get('').catch(() => {
      this.service.authToken = null;
    });
  }
}
