import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { iOS } from 'environment';
import { TableContainer, TablePagination } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';

import FlrLoader from 'components/loading/LoadingSpinner';
import { ReactComponent as ArrowExpand } from 'components/shared/icons/arrow-expand.svg';
import messages from 'translations/layout/table';

import { pageSizeOptions } from './constants';
import { DetailPanelWrapper } from './DetailPanelWrapper';
import { EnhancedTableHead } from './EnhancedTableHead';
import styles from './styles';
import { TablePaginationActions } from './TablePaginationActions';

const isIOS = iOS(window.navigator.userAgent);
const useStyles = makeStyles(styles);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: 'asc' | 'desc',
  orderBy: Key,
  columns: any[]
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  const column = columns.find((c) => c.field === orderBy);
  if (column && column.customSort) {
    return order === 'desc' ? (a, b) => column.customSort(a, b) : (a, b) => -column.customSort(a, b);
  }
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface IProps {
  data: any[];
  columns: any[];
  isLoadingExternal?: boolean;
  detailPanel?: any;
  components?: any;
  hidePagination?: boolean;
  classNameWrapper?: string;
}

const FlrTable: React.FC<IProps> = ({
  data,
  columns,
  // components = {},
  isLoadingExternal,
  detailPanel,
  hidePagination,
  classNameWrapper
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleScroll = () => {
    setIsOpen(false);
  };

  // * close "select per row" dropdown while scrolling
  useEffect(() => {
    if (isOpen) {
      window.addEventListener('scroll', handleScroll);
    } else {
      window.removeEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isOpen]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setIsOpen(false);
  };

  const handleClick = (index: number) => {
    if (!detailPanel) {
      return;
    }

    if (index !== expandedRow) {
      setExpandedRow(index);
    } else if (typeof expandedRow === 'number') {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  const selectProps: any = {
    native: false,
    IconComponent: ArrowExpand,
    MenuProps: {
      classes: { paper: classes.selectListPaper },
      MenuListProps: {
        classes: { root: classes.selectList }
      },
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'left'
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left'
      },
      onClose: () => setIsOpen(false),
      open: isOpen
    },
    onOpen: () => setIsOpen(true),
    classes: {
      icon: clsx(classes.dropdownIndicator, { [classes.dropdownIndicatorActive]: isOpen }),
      root: classes.selectRoot
    }
  };

  return (
    <TableContainer className={clsx(classes.tableContainer, { classNameWrapper })}>
      <Table>
        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headCells={columns} />
        <TableBody>
          {!data || !data.length ? (
            <TableRow>
              <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
                {messages.emptyTable.defaultMessage}
              </TableCell>
            </TableRow>
          ) : null}
          {data && data.length
            ? stableSort(data, getComparator(order, orderBy, columns))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => (
                  <>
                    <TableRow
                      className={clsx({ [classes.expandedRow]: !isIOS && detailPanel && expandedRow === index })}
                      onClick={() => handleClick(index)}
                      role="checkbox"
                      tabIndex={-1}
                      key={row.name}
                    >
                      {columns.map((c: any) => (
                        <TableCell
                          align={c.type === 'numeric' ? 'right' : 'left'}
                          key={c.field}
                          className={classes.tableCell}
                        >
                          {c.render(row)}
                        </TableCell>
                      ))}
                      {isIOS && detailPanel && expandedRow === index ? (
                        <div className={classes.expandedRowIOS} />
                      ) : null}
                    </TableRow>
                    {detailPanel ? (
                      <TableRow>
                        <TableCell style={{ padding: 0 }} colSpan={columns.length}>
                          <Collapse in={expandedRow === index} timeout={0} unmountOnExit>
                            <DetailPanelWrapper data={row} children={detailPanel} />
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </>
                ))
            : null}
        </TableBody>
        {!hidePagination ? (
          <TableFooter>
            <TableRow>
              <TablePagination
                className={classes.select}
                classes={{
                  root: classes.paginationRoot,
                  selectIcon: classes.selectIcon,
                  toolbar: classes.toolbar,
                  selectLabel: classes.selectLabel
                }}
                labelRowsPerPage={messages.rows.defaultMessage}
                rowsPerPageOptions={pageSizeOptions}
                rowsPerPage={rowsPerPage}
                page={page}
                count={data.length}
                SelectProps={selectProps}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions as any}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        ) : null}
      </Table>

      {isLoadingExternal && <FlrLoader withOverlay={true} />}
    </TableContainer>
  );
};

export default FlrTable;
