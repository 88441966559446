import createStyles from '@mui/styles/createStyles';
import { defaultMaterialTheme } from 'utils/styled';

const styles = (theme: any) =>
  createStyles({
    tableContainer: {
      letterSpacing: '0.5pt',
      overflowX: 'initial',

      [theme.breakpoints.down('lg')]: {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        maxWidth: `calc(100vw - ${theme.spacing(8)})`
      },

      // workaround for expand panel outline, prevent table horizontal scrolling
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        overflowX: 'initial',
        maxWidth: `calc(100vw - ${theme.spacing(4)})`,

        // only this nesting works
        '& > div > div': {
          overflowX: 'initial !important',

          '& > div > div': {
            overflowY: 'initial !important'
          }
        },

        '& .MuiTableCell-body > .MuiIconButton-root': {
          display: 'none'
        }
      },

      '& .MuiTableRow-root.MuiTableRow-hover:hover': {
        backgroundColor: 'transparent'
      }
    },
    select: {
      '& div[class*="MuiTablePagination-select"]': {
        display: 'flex',
        margin: '0 8px',

        [theme.breakpoints.down('sm')]: {
          margin: 0
        }
      }
    },
    selectLabel: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    selectIcon: {
      top: 'auto'
    },
    paginationRoot: {
      width: '100%'
    },
    selectRoot: {
      padding: '12px',
      paddingRight: '22px !important',
      width: 30,
      border: '2px solid transparent'
    },
    selectListPaper: {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 8
    },
    selectList: {
      '& > .MuiMenuItem-root': {
        color: theme.palette.text.secondary,

        '&:hover': {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black
        },
        '&:active': {
          backgroundColor: theme.palette.common.primaryClickablePressBackground
        },
        '&.Mui-selected': {
          backgroundColor: theme.palette.common.cardBlackBackground,
          color: theme.palette.common.black
        }
      }
    },
    dropdownIndicator: {
      right: 8,
      height: 24,
      width: 14,
      transformOrigin: 'center',
      transition: defaultMaterialTheme.transitions.create(['transform', 'margin-bottom'], {
        easing: defaultMaterialTheme.transitions.easing.sharp,
        duration: defaultMaterialTheme.transitions.duration.leavingScreen
      })
    },
    dropdownIndicatorActive: {
      transform: 'rotate(180deg)'
    },
    toolbar: {
      padding: 0,

      '& > p:last-of-type': {
        display: 'none'
      }
    },
    detailPanelWrapper: {
      position: 'relative'
    },
    detailPanelContainer: {
      right: -19,
      left: -19,
      top: -1,
      height: 'calc(100% + 2px)',
      position: 'absolute',
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 8,
      boxShadow: '4px 4px 10px rgba(115, 52, 133, 0.16)',
      borderTop: 'none',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    },
    expandedRow: {
      position: 'relative',

      '&:after': {
        content: "''",
        position: 'absolute',
        top: -1,
        right: -19,
        left: -19,
        borderRadius: 8,
        height: 'calc(100% + 2px)',
        border: `2px solid ${theme.palette.primary.main}`,
        borderBottom: 'none',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }
    },
    expandedRowIOS: {
      position: 'absolute',
      right: 13,
      left: 13,
      height: 120,
      borderRadius: 8,
      border: `2px solid ${theme.palette.primary.main}`,
      borderBottom: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    },
    tableCell: {
      lineHeight: '16px',
      '&:nth-child(2) > span': {
        maxWidth: '300px',
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }
    },
    tableHeadCell: {
      padding: 0,
      borderTop: '1px solid #ebebeb',
    },
    tableSortLabel: {
      whiteSpace: 'nowrap',
      padding: '13px 0 10px',
      lineHeight: '14px',
    },
  });

export default styles;
