import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@mui/styles';
import Chart from 'react-apexcharts';
import {
  getBalanceHistory,
  getBalanceHistoryLoaded,
  getBalanceHistoryLoadingState
} from 'store/balance-history/selectors';
import { getDiscountConditions } from 'store/discount-conditions/selectors';
import { fetchBalanceHistoryAsync } from 'store/balance-history/actions';
import { TextHelper, TextSubTitle } from 'components/shared/text';
import FlrLoader from 'components/loading/LoadingSpinner';
import FlrCardNarrow from 'components/shared/card/FlrCardNarrow';
import FlrCardTitle from 'components/shared/card/FlrCardTitle';
import commonMessages from 'translations/account/finance';
import messages from 'translations/account/loyalty';
import { palette } from 'styles/theme/default';
import apexUaLocale from 'apexcharts/dist/locales/ua.json';
import styles from '../styles';

interface IDataSeries {
  name: string;
  data: IChardData[];
}
interface IChardData {
  x: string;
  y: number;
}

interface IProps {
  classes: any;
  intl: any;
  mobile?: boolean;
}

const BalanceHistoryCard: React.FC<IProps> = ({ classes, intl, mobile }) => {
  const dispatch = useDispatch();
  const discountConditions = useSelector(getDiscountConditions);
  const balanceHistory = useSelector(getBalanceHistory);
  const balanceHistoryLoaded = useSelector(getBalanceHistoryLoaded);
  const balanceHistoryLoadingState = useSelector(getBalanceHistoryLoadingState);

  useEffect(() => {
    dispatch(fetchBalanceHistoryAsync.request());
    // eslint-disable-next-line
  }, []);

  let maxBalancePoint = Math.max(...Object.values(balanceHistory || {}));
  let minBalancePoint = Math.min(...Object.values(balanceHistory || {}), 0);
  let ticksY = 1;
  let colorBreakpoints = [0];
  if (discountConditions?.discounts?.balance[0]) {
    const conditions = discountConditions?.discounts?.balance[0].conditions;
    const numberedConditions = Object.values(conditions).map(i => Number(i));
    colorBreakpoints = [0, Math.min(...numberedConditions), Math.max(...numberedConditions)];
    maxBalancePoint = Math.max(...numberedConditions);
    ticksY = numberedConditions.length;

    // * increase tick amount according to negative ballance
    if (minBalancePoint < 0) {
      ticksY += 1;
      const minConditionPoint = numberedConditions.find(i => i > Math.abs(minBalancePoint));
      if (minConditionPoint) {
        minBalancePoint = -(minConditionPoint || 0);
        ticksY += numberedConditions.indexOf(Math.abs(minBalancePoint));
      } else {
        // when minBalancePoint significantly lower than -max(numberedConditions)
        const additionalTicks = Math.abs(minBalancePoint / numberedConditions[0]);
        ticksY += Math.floor(additionalTicks);
        minBalancePoint = -Math.ceil(additionalTicks) * numberedConditions[0];
      }
    }
  }

  const colorGroups = [palette.error.main, palette.common.disabledBlack, palette.info.main, '#c1d134'];

  const chartData: IChardData[][] = [];
  if (balanceHistoryLoaded && balanceHistory) {
    Object.keys(balanceHistory).forEach((date: string, index: number) => {
      const balancePoint = balanceHistory[date];
      let groupIndex = colorBreakpoints.length;
      while (colorBreakpoints[groupIndex - 1] >= balancePoint && groupIndex > 0) {
        groupIndex--;
      }
      if (!chartData[groupIndex]) {
        chartData[groupIndex] = [];
      }
      chartData.forEach(data => data.push({ x: date, y: 0 }));
      chartData[groupIndex][chartData[groupIndex].length - 1].y = balancePoint;
    });
  }

  const getFormatParams = (index: number) => {
    let max = colorBreakpoints[index];
    let min = colorBreakpoints[index - 1];
    if (max) {
      max = Math.round(max / 1000);
    }
    if (min) {
      min = Math.round(min / 1000);
    }
    return { min, max };
  };
  const series: IDataSeries[] = [];
  const colors: string[] = [];
  chartData.forEach((data, index) => {
    const message = messages[`balanceHistory${index + 1}`];
    let name;
    if (message) {
      const params = getFormatParams(index);
      name = intl.formatMessage(message, params);
    }
    if (data.length > 0) {
      series.push({ name, data });
      colors.push(colorGroups[index]);
    }
  });

  const chartOptions = {
    chart: {
      id: 'balance-history-chart',
      type: 'bar',
      stacked: true,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      locales: [apexUaLocale],
      defaultLocale: apexUaLocale.name
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: false
      }
    },
    yaxis: {
      tickAmount: ticksY,
      max: maxBalancePoint,
      min: minBalancePoint,
      labels: {
        formatter: (value: number) => {
          if (Math.abs(value) < 1000) {
            return Math.floor(value);
          }
          if (Math.abs(value) < 5000) {
            return `${(value / 1000).toFixed(1)} тис`;
          }
          return `${(value / 1000).toFixed()} тис`;
        }
      },
      title: {
        show: true
      },
      axisBorder: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      floating: true,
      position: 'top',
      horizontalAlign: mobile ? 'left' : 'right',
      showForNullSeries: false,
      showForZeroSeries: false,
      onItemClick: {
        toggleDataSeries: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '90%'
      }
    },
    tooltip: {
      x: {
        show: true
      }
    },
    colors
  };

  return (
    <>
      <FlrCardNarrow
        className={clsx(classes.historyChart, classes.paperCard, classes.paperCardFullHeight)}
        style={{ minWidth: '100%' }}
      >
        <FlrCardTitle isUnderline>
          <TextSubTitle align={'left'} style={{ marginBottom: mobile ? 30 : 0 }}>
            {commonMessages.balance.defaultMessage}
          </TextSubTitle>
        </FlrCardTitle>
        {balanceHistoryLoadingState ? (
          <FlrLoader size={50} />
        ) : balanceHistory ? (
          <div style={{ position: 'relative' }}>
            <TextHelper className={classes.historyChartYTitle}>Гривні</TextHelper>
            {minBalancePoint < 0 && (
              <TextHelper className={clsx('subtitle', classes.historyChartYTitle)}>Кредитна лінія</TextHelper>
            )}
            <Chart options={chartOptions} series={series} width="100%" height={400} type={'bar'} />
            <TextHelper className={classes.historyChartXTitle}>Тижні</TextHelper>
          </div>
        ) : (
          <span>Немає даних</span>
        )}
      </FlrCardNarrow>
    </>
  );
};

export default withStyles<any>(styles)(injectIntl(BalanceHistoryCard));
