export interface IPersonalDiscountCondition {
  min: number;
  max?: number;
  weeks: number;
}

export interface IConditions {
  [key: string]: string;
}

export interface IDiscountCondition {
  id: string;
  allCatalog: boolean;
  categories: string[];
  conditions: IConditions;
  description: string;
  discountType: string;
  discountData: {
    nextDiscountProgress: IDiscountData;
    progressValue: number;
  };
  endDate: string;
  name: string;
  offersType: string[];
  productType: string[];
  products: string[];
  startDate: string;
  image?: string;
  // ! TODO remove
  // percent?: string;
  requireAmount?: number;
}

export interface IDiscountData {
  discountType: string;
  percent: number;
  progressValue: number;
  requiredValue: number;
  endDate?: string;
  startDate?: string;
}

export interface IClientDiscount {
  client: string;
  discountRule: string;
  id: string;
  onDate: string;
  updatedAt: string;
  discountData: IDiscountData;
  conditions?: IConditions;
}

export interface IDiscounts {
  balance: IDiscountCondition[];
  purchased_qty: IDiscountCondition[];
  static: IDiscountCondition[];
  website: IDiscountCondition[];
}

export interface IDiscountHistory {
  archived: boolean;
  client: string;
  createdAt: string;
  deleted: boolean;
  discountData: IDiscountData;
  discountRule: string;
  id: string;
  onDate: string;
  updatedAt: string;
}

export interface IDiscountConditionsResponse {
  clientDiscounts: IClientDiscount[];
  discounts: IDiscounts;
}

export class DiscountConditions {
  public clientDiscounts: IClientDiscount[];
  public discounts: IDiscounts;
  public allDiscounts: IDiscountCondition[];
  public conditionDiscounts: IDiscountCondition[];
  public permanentDiscounts: IDiscountCondition[];
  public accountBalanceDiscount: IClientDiscount | undefined;

  // ! TODO remove
  public personal: any;

  constructor(conditions: IDiscountConditionsResponse) {
    this.clientDiscounts = conditions.clientDiscounts;
    this.discounts = this.getDiscounts(conditions.discounts);
    // all discounts except balance
    this.allDiscounts = [...this.discounts.purchased_qty, ...this.discounts.static];
    this.conditionDiscounts = this.discounts.purchased_qty;
    this.permanentDiscounts = [...this.discounts.static, ...this.discounts.website];
    this.accountBalanceDiscount = (() => {
      let discount: any = null;
      if (this.clientDiscounts.length) {
        discount = this.clientDiscounts.find(i => i.discountData.discountType === 'balance');
      }
      if (!discount) {
        discount = {
          ...this.discounts.balance[0],
          discountData:
            this.discounts.balance[0] && this.discounts.balance[0].discountData
              ? this.discounts.balance[0].discountData.nextDiscountProgress
              : {}
        };
      }
      return discount;
    })();
  }

  private getDiscounts(discounts: any): IDiscounts {
    return {
      balance: discounts.balance || [],
      purchased_qty: discounts['purchased-qty'] || [],
      static: discounts.static || [],
      website: discounts.website || [],
    };
  }
}
